import http from "./httpService";
import auth from "./authService";
import utils from "./classService";

const endPoint = "/materials";

http.setJwt(auth.getJwt());

const Utils = class {
    getMaterial = async mID => {
        return await http.get(endPoint + "/" + mID);
    };

    getGradeMaterials = async grade => {
        return await http.get(
            endPoint + "?filter=" + JSON.stringify({ grade })
        );
    };

    getByClassID = async classID => {
        return await http.get(endPoint + "/getByClassID/" + classID);
    };

    createMaterial = async data => {
        return await http.post(endPoint, data);
    };
    updateMaterial = async data => {
        return await http.put(endPoint, data);
    };

    deleteMaterial = async mID => {
        return await http.delete(endPoint + "/" + mID);
    };
};

export default new Utils();
