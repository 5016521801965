import React, { Component } from "react";
import { ToastContainer } from "react-toastify";
import { Switch, Route } from "react-router-dom";
import Navigation from "./components/navigation";
import MainContent from "./components/mainContent";
import StoreRedirect from "./components/pages/storeRedirect";

import auth from "./services/authService";
import schoolService from "./services/schoolService";
import httpService from "./services/httpService";
import userService from "./services/userService";
import kidService from "./services/kidService";
import userUtils from "./utils/userUtils";

export const AppContext = React.createContext();

function getAppContext(comp) {
    return comp;
}

class App extends Component {
    constructor(props) {
        super(props);
        if (
            window.location.protocol === "http:" &&
            process.env.NODE_ENV === "production"
        ) {
            window.location.href = window.location.href.replace(
                "http://",
                "https://"
            );
        }

        const curUser = auth.getCurrentUser();
        this.state = {
            appName: "Dr. ABC Portal",
            pageTitle: "Dr. ABC Portal",
            curUser: curUser,
            schoolID: null,
            navSchoolYear: null,
            loaded: false,
            kids: []
        };
    }
    async componentDidMount() {
        const { curUser: loadedUser } = this.state;
        if (loadedUser) {
            try {
                const { data } = await userService.getUser(loadedUser._id);
                const curUser = userUtils.createUser(data);
                let schoolID =
                    curUser && curUser.isSuper && curUser.isSuper.school
                        ? curUser.isSuper.school
                        : "5c2a4bf75ce05719b8e36a90";
                if (curUser && curUser.isTeacher)
                    schoolID = curUser.isTeacher.school;
                if (curUser && curUser.isManager)
                    schoolID = curUser.isManager.school;
                httpService.setSchoolIDHeader(schoolID);
                let navSchoolYear = {};

                if (curUser.isSuper || curUser.isManager) {
                    const {
                        data: schoolYears
                    } = await schoolService.getYears();

                    try {
                        navSchoolYear = schoolYears[0];
                    } catch (ex) {}
                }

                const { data: kids } = await kidService.getKids();

                this.setState({
                    curUser,
                    schoolID,
                    navSchoolYear,
                    loaded: true,
                    kids
                });
            } catch (ex) {
                console.log(ex);
                auth.logout();
                window.location.reload();
            }
        } else {
            this.setState({ loaded: true });
        }
    }

    async componentDidUpdate(prevProps, prevState, snashot) {
        httpService.setSchoolIDHeader(this.state.schoolID);
    }

    render() {
        const { loaded } = this.state;
        if (!loaded) {
            return "";
        }

        return (
            <AppContext.Provider value={getAppContext(this)}>
                <ToastContainer />
                <Switch>
                    <Route path="/storeRedirect" component={StoreRedirect} />
                    <Route
                        path="/"
                        render={props => {
                            if (props.location.pathname === "/storeRedirect")
                                return "";
                            return (
                                <div className="container-fluid" id="wrapper">
                                    <div className="row">
                                        <Navigation />
                                        <MainContent />
                                    </div>
                                </div>
                            );
                        }}
                    />
                </Switch>
            </AppContext.Provider>
        );
    }
}

export default App;
