import React from "react";
import { Icon } from "antd";
import { Redirect, Link } from "react-router-dom";
import Joi from "joi-browser";
import queryString from "query-string";

import Form from "./commons/form";
import auth from "../services/authService";

import { AppContext } from "../App";

class PageLogin extends Form {
    state = {
        data: { email: "", password: "" },
        errors: {}
    };
    componentDidMount() {
        const { app, location } = this.props;
        const query = queryString.parse(location.search);
        // console.log("query", query);

        if (query.userToken) {
            auth.loginWithJwt(query.userToken);
            window.location.href = "/";
        } else {
            app.setState({
                pageTitle: "Login"
            });
        }
    }
    componentWillUnmount() {
        const { app } = this.props;
        app.setState({
            pageTitle: ""
        });
    }

    schema = {
        email: Joi.string()
            .email({ minDomainAtoms: 2 })
            .required()
            .label("Email"),
        password: Joi.string()
            .required()
            .label("Password")
    };

    doSubmit = async () => {
        try {
            const { data } = this.state;
            await auth.login(data.email, data.password);
            const { state } = this.props.location;

            window.location = state ? state.from.pathname : "/";
        } catch (ex) {}
    };

    render() {
        if (auth.getCurrentUser()) return <Redirect to="/" />;

        return (
            <div className="container">
                <div className="row ">
                    <div className="col-sm-12">
                        <a
                            href="http://localhost:3000/fbLogin"
                            // href="https://drabc-api-sandbox.herokuapp.com/fbLogin"
                            className="btn btn-primary rounded"
                            style={{
                                textAlign: "center",
                                width: 250,
                                margin: "auto",
                                display: "block",
                                marginBottom: 20
                            }}
                        >
                            <i
                                className="fa fa-facebook-official "
                                style={{ fontSize: "18pt" }}
                            />{" "}
                            <span style={{ position: "relative", top: "-3px" }}>
                                Log In With Facebook
                            </span>
                        </a>
                    </div>
                    <div className="d-none">
                        {" "}
                        <a
                            href="http://localhost:3000/googleLogin"
                            // href="https://drabc-api-sandbox.herokuapp.com/fbLogin"
                            className="btn btn-primary rounded"
                            style={{
                                textAlign: "center",
                                width: 250,
                                margin: "auto",
                                display: "block",
                                marginBottom: 20
                            }}
                        >
                            <i
                                className="fa fa-google "
                                style={{ fontSize: "18pt" }}
                            />{" "}
                            <span style={{ position: "relative", top: "-3px" }}>
                                Log In With Google
                            </span>
                        </a>
                    </div>
                    <div className="col-12 text-center">OR</div>
                </div>

                <form onSubmit={this.handleSubmit}>
                    {this.renderInput("email", "Email")}
                    {this.renderInput("password", "Password", "password")}
                    {this.renderButton("Login")}{" "}
                    <Link className="btn btn-link" to="/forgetPassword">
                        Forget Password?
                    </Link>
                </form>
                <hr />
                <div className="jumbotron">
                    <h3 className="mb-4">Don't have an account?</h3>
                    <p className="lead">
                        <Link
                            className="btn btn-primary btn-lg mt-2"
                            to="/register"
                        >
                            Register
                        </Link>
                    </p>
                </div>
            </div>
        );
    }
}

const _PageLogin = props => (
    <AppContext.Consumer>
        {app => <PageLogin {...props} app={app} />}
    </AppContext.Consumer>
);

export default _PageLogin;
