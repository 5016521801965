import React from "react";
import Form from "../commons/form";
import Joi from "joi-browser";
import CardBlock from "../themeComps/cardBlock";
import { Link } from "react-router-dom";
import validateUtils from "../../utils/validateUtils";
import kidService from "../../services/kidService";
import { toast } from "react-toastify";
class KidForm extends Form {
    state = {
        data: {
            name: "",
            code: ""
        },
        errors: {}
    };
    schema = {
        name: Joi.string()
            .min(3)
            .max(50)
            .label("Nick Name"),
        code: Joi.string()
            .regex(validateUtils.objIDRegex)
            .error(errors => {
                return validateUtils.JoiErrorMsg(
                    errors,
                    "code",
                    "Code is not valid!"
                );
            })
    };
    componentDidMount() {
        console.log(this.props.history);
    }
    doSubmit = async () => {
        const { history, handleKidChange, userID } = this.props;
        const formData = { ...this.state.data };
        try {
            if (!formData._id) {
                const { data } = await kidService.createKid(formData, userID);
                handleKidChange(data);
                if (data) history.goBack();
            }
            toast.success("Success!");
        } catch (ex) {
            console.log(ex);
        }
    };

    render() {
        return (
            <CardBlock
                title="Create A Kid"
                subTitle="Join Class with your activation code"
            >
                <form onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            {this.renderInput("name", "Kid Nickname")}
                        </div>
                        <div className="col-md-6">
                            {" "}
                            {this.renderInput("code", "Activation Code")}
                        </div>
                        <div className="col">
                            {this.renderButton("Create")}{" "}
                            <span
                                className="btn btn-default"
                                onClick={() => {
                                    this.props.history.goBack();
                                }}
                                to="/profile"
                                className="btn btn-default"
                            >
                                Close
                            </span>
                        </div>
                    </div>
                </form>
            </CardBlock>
        );
    }
}

export default KidForm;
