import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";

import { AppContext } from "../../App";
import Schools from "./schools";
import Users from "./users";
import Materials from "../teachingComps/materials";
import SuperRoute from "./../navComps/superRoute";
import PageNotFound from "../pageNotFound";

class PageSuper extends Component {
    state = {};

    componentDidMount() {}

    componentWillUnmount() {}

    render() {
        // const { app } = this.props;
        return (
            <React.Fragment>
                <Switch>
                    <SuperRoute path="/super/schools" component={Schools} />
                    <SuperRoute path="/super/users" component={Users} />
                    <Route component={PageNotFound} />
                </Switch>
            </React.Fragment>
        );
    }
}

const _PageSuper = props => (
    <AppContext.Consumer>
        {app => <PageSuper {...props} app={app} />}
    </AppContext.Consumer>
);

export default _PageSuper;
