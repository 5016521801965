import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import Select from "./select";
import TextArea from "./textarea";
// import SingleFileUpload from "./singleFileUpload";
import _ from "lodash";

class Form extends Component {
    state = {
        data: {},
        errors: {}
    };

    validate = data => {
        if (!data) data = this.state.data;
        const options = { abortEarly: false, allowUnknown: true };
        const { error } = Joi.validate(data, this.schema, options);
        try {
            console.log(error.details);
        } catch (error) {}

        if (!error) return { noErr: true };

        const errors = {};

        for (let item of error.details) {
            const pahtName = item.path.join(".");
            _.set(errors, pahtName, item.message);
            // errors[item.path[0]] = item.message;
        }

        return errors;
    };

    handleSubmit = e => {
        e.preventDefault();

        const errors = this.validate();
        this.setState({ errors });

        if (!errors.noErr) return;

        this.doSubmit();
    };
    handleChange = ({ currentTarget: input }) => {
        // console.log("handle change");
        const data = { ...this.state.data };
        _.set(data, input.name, input.value);
        const errors = this.validate(data);
        this.setState({ data, errors });
    };

    renderButton(confirmText = "Submit", cancelText = "Cancel") {
        return (
            <React.Fragment>
                <button
                    className="btn btn-primary"
                    disabled={!this.validate().noErr}
                >
                    {confirmText}
                </button>{" "}
                <span className="btn btn-default" onClick={this.doReset}>
                    {cancelText}
                </span>
            </React.Fragment>
        );
    }

    renderInput(name, label, type = "text", placeholder, props) {
        return (
            <Input
                name={name}
                value={_.get(this.state.data, name)}
                label={label}
                placeholder={placeholder}
                onChange={this.handleChange}
                onBlur={this.handleChange}
                error={_.get(this.state.errors, name)}
                type={type}
                {...props}
            />
        );
    }
    // renderSingleFileUpload(name, label, props) {
    //     return (
    //         <SingleFileUpload
    //             name={name}
    //             value={_.get(this.state.data, name)}
    //             label={label}
    //             onChange={this.handleChange}
    //             onBlur={this.handleChange}
    //             error={_.get(this.state.errors, name)}
    //             {...props}
    //         />
    //     );
    // }

    rednerTextArea(name, label, props) {
        return (
            <TextArea
                name={name}
                value={this.state.data[name]}
                label={label}
                onChange={this.handleChange}
                onBlur={this.handleChange}
                error={this.state.errors[name]}
                {...props}
            />
        );
    }

    renderSelect(name, label, options, props) {
        return (
            <Select
                name={name}
                label={label}
                options={options}
                onChange={this.handleChange}
                onBlur={this.handleChange}
                error={this.state.errors[name]}
                value={this.state.data[name]}
                {...props}
            />
        );
    }
}

export default Form;
