import React, { Component } from "react";
import Form from "../commons/form";
import Joi from "joi-browser";
import alphabetService from "../../services/alphabetService";
import { toast } from "react-toastify";
import CardBlock from "../themeComps/cardBlock";
import { join } from "path";

class AlphabetForm extends Form {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                _id: "",
                letter: "",
                word: "",
                image: "",
                audio: {
                    ulr: "",
                    start: 0,
                    end: 0
                },
                upperSVG: "",
                lowerSVG: "",
                audioStart: 0,
                audioEnd: 0,
                audioUrl: ""
            },
            errors: {}
        };
    }

    schema = {
        letter: Joi.string()
            .required()
            .min(1)
            .max(1),
        word: Joi.string().allow("", null),
        image: Joi.string().allow("", null),
        audioUrl: Joi.string().allow("", null),
        audioStart: Joi.number().min(0),
        audioEnd: Joi.number().min(0),
        upperSVG: Joi.string().allow("", null),
        lowerSVG: Joi.string().allow("", null)
    };
    async componentDidMount() {
        const { data } = this.props;
        if (data) this.loadEditData(data._id);
    }
    loadEditData = async dataID => {
        const { data } = await alphabetService.getAlphabet(dataID);
        const formData = { ...this.state.data };
        const keys = Object.keys(formData);

        keys.forEach(k => {
            formData[k] = data[k];
        });
        formData.audioUrl = formData.audio && formData.audio.url;
        formData.audioStart = formData.audio && formData.audio.start;
        formData.audioEnd = formData.audio && formData.audio.end;
        this.setState({ data: formData });
    };
    doSubmit = async () => {
        const formData = { ...this.state.data };
        if (
            typeof formData.audio === "string" ||
            formData.audio === null ||
            formData === undefined
        )
            formData.audio = {};
        formData.audio.url = formData.audioUrl;
        formData.audio.start = formData.audioStart;
        formData.audio.end = formData.audioEnd;
        try {
            if (formData._id === null || formData._id === "") {
                delete formData._id;
                const { data } = await alphabetService.createAlphabet(formData);
                this.props.handleClose(true);
                toast.success("Created Success!");
            } else {
                const { data } = await alphabetService.updateAlphabet(formData);
                this.props.handleClose(true);
                toast.success("Updated Success!");
            }
        } catch (ex) {
            console.log(ex);
        }
    };
    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        {this.renderInput("letter", "Letter")}
                    </div>
                    <div className="col-md-6">
                        {this.renderInput("word", "word")}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        {this.renderInput("image", "image")}
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6">
                                {this.renderInput("audioUrl", "Audio Url")}
                            </div>
                            <div className="col-md-3">
                                {this.renderInput("audioStart", "Audio Start")}
                            </div>
                            <div className="col-md-3">
                                {this.renderInput("audioEnd", "Audio End")}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        {this.rednerTextArea("upperSVG", "upperSVG")}
                    </div>
                    <div className="col-md-6">
                        {this.rednerTextArea("lowerSVG", "lowerSVG")}
                    </div>
                </div>
                <div className="row">
                    {this.renderButton("Submit")}{" "}
                    <span
                        className="btn btn-default"
                        onClick={() => {
                            this.props.handleClose();
                        }}
                    >
                        Cancel
                    </span>
                </div>
            </form>
        );
    }
}

export default AlphabetForm;
