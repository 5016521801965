import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import { AppContext } from "../App";
//pages
import PageHome from "./pageHome";
import PageNotFound from "./pageNotFound";
import ForgetPassword from "./forgetPassword";
import ResetPassword from "./resetPassword";
import PageAccept from "./pageAccept";
import PageAbout from "./pageAbout";
import PageLogin from "./pageLogin";
import PageLogout from "./pageLogout";
import PageRegister from "./pageRegister";
import PageProfile from "./pageProfile";
import PageSuper from "./superComps/super";
import School from "./schoolComps/school";
import Teacher from "./teacherComps/teacher";
import ClassComp from "./classComps/classComp";
import Materials from "./teachingComps/materials";
import ChallengeComp from "./challengeComps/challengeComp";
import ResultComp from "./challengeResultComps/resultComp";

//comps
import SuperRoute from "./navComps/superRoute";
import SchoolRoute from "./navComps/schoolRoute";
import TeacherRoute from "./navComps/teacherRoute";
import ProtectedRoute from "./navComps/protectedRoute";

import PageHeader from "./themeComps/pageHeader";

class MainContent extends Component {
    state = {};
    componentDidMount() {}

    render() {
        const appState = this.props.app.state;
        return (
            <div className="col-md-12 col-lg-9 offset-lg-3 col-xl-10 offset-xl-2 pt-3 pl-4">
                <PageHeader title={appState.pageTitle} />

                <section className="row">
                    <div className="col-12">
                        <Switch>
                            <SuperRoute path="/super" component={PageSuper} />
                            <SchoolRoute path="/school" component={School} />

                            <TeacherRoute
                                path="/teacher/:teacherID"
                                component={Teacher}
                            />
                            <TeacherRoute path="/teacher" component={Teacher} />
                            <TeacherRoute path="/class" component={ClassComp} />
                            <TeacherRoute
                                path="/materials/grade/:grade"
                                component={Materials}
                            />

                            <TeacherRoute
                                path="/challenge"
                                component={ChallengeComp}
                            />

                            <ProtectedRoute
                                path="/challengeResult"
                                component={ResultComp}
                            />
                            <ProtectedRoute
                                path="/dashboard/:childID"
                                component={PageHome}
                            />
                            <ProtectedRoute
                                path="/dashboard"
                                component={PageHome}
                            />

                            <ProtectedRoute
                                path="/profile/:userID"
                                component={PageProfile}
                            />
                            <ProtectedRoute
                                path="/profile"
                                component={PageProfile}
                            />

                            <Route path="/about" component={PageAbout} />
                            <Route path="/login" component={PageLogin} />
                            <Route path="/logout" component={PageLogout} />
                            <Route path="/register" component={PageRegister} />
                            <Route
                                path="/forgetPassword"
                                component={ForgetPassword}
                            />
                            <Route
                                path="/resetPassword/"
                                component={ResetPassword}
                            />
                            <Route path="/accept" component={PageAccept} />
                            <Redirect path="/" exact to="/dashboard" />
                            <Route component={PageNotFound} />
                        </Switch>
                    </div>
                </section>
            </div>
        );
    }
}

const _MainContent = props => (
    <AppContext.Consumer>
        {app => <MainContent {...props} app={app} />}
    </AppContext.Consumer>
);

export default _MainContent;
