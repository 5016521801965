import React, { Component } from "react";
import purchaseService from "../../services/purchaseService";
import CardBlock from "../themeComps/cardBlock";
import _ from "lodash";

class Materials extends Component {
    state = {
        grades: [],
        purchases: []
    };
    dropDownOpts = [
        {
            label: "Purchase History",
            iconCls: "fa-list",
            handler: () => {
                console.log("Purchase History");
            }
        }
    ];
    async componentDidMount() {
        console.log("componentDidMount");
        const { childID } = this.props;
        const { data: purchases } = await purchaseService.getPurchases(childID);
        // console.log(purchases);
        const grades = this.getGrades(purchases);
        console.log("grades", grades);
        this.setState({ grades, purchases });
    }
    render() {
        const { grades } = this.state;
        return (
            <CardBlock title="Materials" dropDownOpts={this.dropDownOpts}>
                <div id="gradeAccordion">
                    {grades.map((grade, index) => {
                        return this.renderGrade(grade, index);
                    })}
                </div>
            </CardBlock>
        );
    }

    renderGrade = (grade, index) => {
        return (
            <div className="card" key={"grade_" + grade}>
                <div className="card-header" id={"gradeHeading_" + grade}>
                    <h5 className="mb-0">
                        <button
                            className="btn btn-link"
                            data-toggle="collapse"
                            data-target={"#gradeCollapse_" + grade}
                            aria-expanded="true"
                            aria-controls={"#gradeCollapse_" + grade}
                        >
                            Units of Grade {grade}
                        </button>
                    </h5>
                </div>

                <div
                    id={"gradeCollapse_" + grade}
                    className={index === 0 ? "collapse show" : "collapse"}
                    aria-labelledby={"gradeHeading_" + grade}
                    data-parent="#gradeAccordion"
                >
                    <div className="card-body">
                        <div className="row">{this.renderUnit(grade)}</div>
                    </div>
                </div>
            </div>
        );
    };
    renderUnit = grade => {
        const { purchases } = this.state;
        const units = purchases.filter(purchase => {
            if (purchase.grade === grade && purchase.app === "classroom")
                return purchase;
        });

        units.sort((a, b) => {
            return a.unit - b.unit;
        });

        return units.map(unit => {
            return (
                <div className="col-2" key={unit.unit}>
                    <a
                        href="#"
                        style={{ padding: "5px 8px", display: "inline-block" }}
                    >
                        {unit.unit}
                    </a>
                </div>
            );
        });

        return "";
    };

    getGrades = purchases => {
        const grades = _.map(_.uniqBy(purchases, "grade"), item => {
            return item.grade;
        });

        return grades;
    };
}

export default Materials;
