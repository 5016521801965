import { Table } from "antd";
import React, { Component } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import { SchoolContext } from "./school";

import CardBlock from "../themeComps/cardBlock";
import TeacherForm from "./teacherForm";
import schoolService from "../../services/schoolService";
import userUtils from "../../utils/userUtils";
import TeacherFormInvite from "./teacherFormInvite";
import antTblUtils from "../../utils/antTblUtils";

class Teachers extends Component {
    state = {
        users: [],
        userToUpdate: null,
        showForm: false,
        searchText: ""
    };
    dropDownOpts = [
        {
            label: "Create new teacher",
            iconCls: "fa-plus",
            handler: () => {
                this.toggleForm(false);
                setTimeout(() => {
                    this.toggleForm("crateUpdate");
                }, 100);
            }
        },
        {
            label: "Invite new teacher",
            iconCls: "fa-envelope",
            handler: () => {
                this.toggleForm(false);
                setTimeout(() => {
                    this.toggleForm("inviteForm");
                }, 100);
            }
        }
    ];
    columns = [
        {
            title: "Name",
            dataIndex: "name",
            render: (val, user) => {
                const url = "/profile/" + user._id;
                return <Link to={url}>{val}</Link>;
            },
            sorter: (a, b) => {
                let nameA = "",
                    nameB = "";
                try {
                    nameA = a.name.toLowerCase();
                } catch (ex) {}
                try {
                    nameB = b.name.toLowerCase();
                } catch (ex) {}
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;

                return 0;
            },
            ...antTblUtils.getColumnSearchProps("name", this.state.searchText)
        },
        {
            title: "Email",
            dataIndex: "email",
            ...antTblUtils.getColumnSearchProps("email", this.state.searchText)
        },
        {
            title: "Roles",
            dataIndex: "roles",
            render: (val, user) => {
                if (!val) return "";
                return val.map((r, index) => {
                    let url = "";
                    if (r == "teacher" && user.isTeacher) {
                        url = "/teacher/" + user._id;
                    } else if (r == "manager" && user.isManager) {
                        url = "/school/info";
                    }
                    if (!url)
                        return (
                            <span key={index} className="m-1">
                                {r}
                            </span>
                        );
                    return (
                        <Link to={url} key={index} className="m-1">
                            {r}
                        </Link>
                    );
                });
            }
        },
        {
            title: "Class",
            dataIndex: "gClasses",
            render: (val, user) => {
                if (!val) return "";
                return val.map(c => {
                    const cName = `${c.schoolYear.name} Grade ${
                        c.grade.name
                    } Class ${c.name} `;

                    return (
                        <div key={c._id} className="m-2">
                            <Link to={"/class?classID=" + c._id}>{cName}</Link>
                        </div>
                    );
                });
            }
        },
        {
            title: "",
            dataIndex: "",
            render: (val, user) => {
                return (
                    <div>
                        <span
                            className="m-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                this.toggleForm(false);
                                setTimeout(() => {
                                    this.setState({ userToUpdate: user._id });
                                    this.toggleForm("crateUpdate");
                                }, 100);
                            }}
                        >
                            <em className="fa fa-pencil" />
                        </span>
                        <span
                            className="trash"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                this.deleteUser(user);
                            }}
                        >
                            <em className="fa fa-trash" />
                        </span>
                    </div>
                );
            }
        }
    ];
    async componentDidMount() {
        // console.log("teachers did mount");
        await this.setUpData();
    }

    setUpData = async () => {
        try {
            const { data: users } = await schoolService.getTeachers();
            // console.log(users);
            users.forEach(t => {
                return userUtils.createUser(t);
            });
            this.setState({ users });
        } catch (ex) {
            console.log(ex);
        }
    };

    handleUserChange = async user => {
        await this.setUpData();
    };
    deleteUser = async user => {
        try {
            const { data } = await schoolService.deleteTeacher(user._id);
            if (data) {
                await this.setUpData();
            }
        } catch (ex) {
            console.log(ex);
        }
    };

    toggleForm = val => {
        if (val === null || val === undefined) val = false;

        if (val === false) {
            this.setState({ userToUpdate: null });
        }

        this.setState({ showForm: val });
    };

    render() {
        const { schoolCmp } = this.props;
        const { showForm, userToUpdate, users } = this.state;
        return (
            <div className="">
                {showForm === "crateUpdate" && (
                    <TeacherForm
                        handleUserChange={this.handleUserChange}
                        userToUpdate={userToUpdate}
                        toggleForm={this.toggleForm}
                        schoolID={schoolCmp.state.curSchoolID}
                    />
                )}
                {showForm === "inviteForm" && (
                    <TeacherFormInvite
                        toggleForm={this.toggleForm}
                        handleUserChange={this.handleUserChange}
                    />
                )}
                <CardBlock title="Teachers" dropDownOpts={this.dropDownOpts}>
                    {users.length > 0 && (
                        <Table
                            columns={this.columns}
                            dataSource={users}
                            pagination={true}
                            bordered={true}
                            rowKey="_id"
                            // className="table table-responsive"
                        />
                    )}
                </CardBlock>
            </div>
        );
    }
}

const _Teachers = props => {
    return (
        <SchoolContext.Consumer>
            {schoolCmp => <Teachers {...props} schoolCmp={schoolCmp} />}
        </SchoolContext.Consumer>
    );
};

export default _Teachers;
