import _ from "lodash";
import React from "react";
import Joi from "joi-browser";

import Form from "../commons/form";
import classService from "../../services/classService";

import { ClassContext } from "./pageClass";
import schoolService from "../../services/schoolService";
import { toast } from "react-toastify";

class EditCodeForm extends Form {
    constructor(props) {
        super(props);
        const { codeInfo } = props;
        const formData = _.pick(codeInfo, ["_id", "__v", "classID", "name"]);
        if (!formData.name) formData.name = "";
        this.state = {
            data: formData,
            errors: {}
        };
    }

    schema = {
        name: Joi.string()
            .min(3)
            .max(50)
            .required()
    };
    doSubmit = async () => {
        try {
            const { data } = await schoolService.updateActivationCode(
                this.state.data
            );
            toast.success("Update Success!");
            this.props.closeForm(true);
        } catch (ex) {
            console.log(ex);
        }
    };
    render() {
        return (
            <form onSubmit={this.handleSubmit} className="row">
                <div className="col-md-12">
                    {this.renderInput("name", "Student Name")}
                </div>
                <div className="col-md-12">
                    {this.renderButton("Update")}{" "}
                    <span
                        className="btn btn-default"
                        onClick={() => {
                            this.props.closeForm();
                        }}
                    >
                        Cancel
                    </span>
                </div>
            </form>
        );
    }
}

export default EditCodeForm;
