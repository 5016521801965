import React, { Component } from "react";
import QueryString from "query-string";
import { toast } from "react-toastify";

import { AppContext } from "../../App";
import Form from "../commons/form";
import CardBlock from "../themeComps/cardBlock";

import PickedQuestionTbl from "./challengeForm_pickedQuestionTbl";
import QuestionPicker from "./challengeForm_questionPicker";
import MainForm from "./challengeForm_mainForm";

class ChallengeForm extends Form {
    state = {
        pickedQuestions: []
    };
    componentDidMount() {
        const { app, location, match } = this.props;
        const query = QueryString.parse(location.search);

        app.setState({
            pageTitle:
                query.challengeID === undefined
                    ? "Create a challenge"
                    : "Update challenge"
        });
    }

    componentWillUnmount() {}
    toggleQuestion = q => {
        const pickedQuestions = [...this.state.pickedQuestions];

        const index = pickedQuestions.findIndex(p => p._id === q._id);

        if (index > -1) {
            pickedQuestions.splice(index, 1);
        } else {
            pickedQuestions.push(q);
        }

        this.setState({
            pickedQuestions
        });
    };
    updatePickedQuestions = questions => {
        this.setState({
            pickedQuestions: questions
        });
    };
    render() {
        const { app, location, match, history } = this.props;
        const { curUser } = app.state;
        const { pickedQuestions } = this.state;
        const query = QueryString.parse(location.search);

        return (
            <div>
                <MainForm
                    curUser={curUser}
                    pickedQuestions={pickedQuestions}
                    history={history}
                    updatePickedQuestions={this.updatePickedQuestions}
                    challengeID={query.challengeID}
                />
                <PickedQuestionTbl
                    pickedQuestions={pickedQuestions}
                    toggleQuestion={this.toggleQuestion}
                    updatePickedQuestions={this.updatePickedQuestions}
                />
                <QuestionPicker
                    pickedQuestions={pickedQuestions}
                    toggleQuestion={this.toggleQuestion}
                />
            </div>
        );
    }
}

const _ChallengeForm = props => {
    return (
        <AppContext.Consumer>
            {app => <ChallengeForm {...props} app={app} />}
        </AppContext.Consumer>
    );
};

export default _ChallengeForm;
