import React from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";

import Form from "../commons/form";
import schoolService from "../../services/schoolService";
import CardBlock from "../themeComps/cardBlock";
import schoolUtils from "../../utils/school";
import SubscriptionForm from "./subscriptionForm";
class SchoolForm extends Form {
    state = {
        data: schoolUtils.School(),
        errors: {},
        loadedData: false
    };
    schema = schoolUtils.joiSchoolSchema;
    async componentDidMount() {
        const { schoolToUpdate } = this.props;
        try {
            if (schoolToUpdate) {
                const { data: school } = await schoolService.getSchool(
                    schoolToUpdate._id
                );
                // console.log(school);
                if (school) this.setState({ data: school, loadedData: true });
            } else {
                this.setState({ loadedData: true });
            }
        } catch (ex) {}
    }
    doSubmit = async () => {
        const formData = { ...this.state.data };
        try {
            let school = null;
            if (!formData._id) {
                //new
                const { data } = await schoolService.createSchool(formData);
                school = data;
            } else {
                //update
                const { data } = await schoolService.updateSchool(formData);
                school = data;
            }
            if (school !== null) {
                this.props.handleSchoolChange(school);
                toast.success("Success!!!");
                this.props.toggleForm(false);
            }
        } catch (ex) {
            console.log(ex);
        }
    };

    render() {
        const { data, loadedData, errors } = this.state;
        return (
            <div className="row">
                <div className="col">
                    <CardBlock
                        title="School Form"
                        subTitle={
                            data._id
                                ? "Update school info"
                                : "Create a new school"
                        }
                    >
                        <form onSubmit={this.handleSubmit} className="row">
                            <div className="col-md-12">
                                {this.renderInput("name", "Name")}
                            </div>
                            <div className="col-md-12">
                                {this.renderInput("schoolCode", "Short Name")}
                            </div>
                            {/* <div className="col-md-12">
                                {this.renderButton(
                                    data._id ? "Update" : "Create"
                                )}{" "}
                                <span
                                    className="btn btn-default"
                                    onClick={() => {
                                        this.props.toggleForm(false);
                                    }}
                                >
                                    Close
                                </span>
                            </div> */}
                        </form>
                        <div className="row">
                            <div className="col-md-12">
                                <CardBlock title="Subscriptions">
                                    {loadedData && (
                                        <SubscriptionForm
                                            subscriptions={data.subscriptions}
                                            errors={errors}
                                        />
                                    )}
                                </CardBlock>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <button
                                    className="btn btn-primary"
                                    disabled={this.validate()}
                                    onClick={this.handleSubmit}
                                >
                                    {data._id ? "Update" : "Create"}
                                </button>{" "}
                                <span
                                    className="btn btn-default"
                                    onClick={() => {
                                        this.props.toggleForm(false);
                                    }}
                                >
                                    Close
                                </span>
                            </div>
                        </div>
                    </CardBlock>
                </div>
            </div>
        );
    }
}

export default SchoolForm;
