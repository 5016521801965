import React, { Component } from "react";
import { Calendar, Badge } from "antd";
import CardBlock from "./../themeComps/cardBlock";
import userService from "../../services/userService";
import moment from "moment";
import kidService from "../../services/kidService";
import appUtils from "../../utils/appUtils";
import reportService from "../../services/reportService";

class DiaryCal extends Component {
    state = {
        childInfo: null,
        diaries: [],
        selectedDate: null,
        curDiary: null
    };
    async componentDidMount() {
        const { childID } = this.props;

        const selectedDate = appUtils.moment.getDateOnly(moment.utc());

        try {
            const { data: childInfo } = await userService.getUser(childID);
            let diaries = await this.getDiaires(selectedDate);
            const curDiary = this.getDiary(diaries, selectedDate);
            this.setState({
                childInfo,
                selectedDate,
                diaries,
                curDiary
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    getDiaires = async date => {
        try {
            const { childID } = this.props;
            const dateFrom = appUtils.moment.getStartOfCalendar(date);
            const dateTo = appUtils.moment.getEndOfCalendar(date);

            const reqBody = {
                userID: childID,
                dateFrom,
                dateTo
            };
            const { data } = await reportService.getDiaires(reqBody);
            return data;
        } catch (ex) {
            return [];
            console.log(ex);
        }
    };
    getDiary = (diaries, date) => {
        // console.log(date.toISOString());
        if (!Array.isArray(diaries)) return;

        const diary = diaries.find(d => {
            if (moment.utc(d.time).diff(date, "hour") === 0) return d;
        });
        return diary;
    };
    onChange = async date => {
        let { selectedDate, diaries } = this.state;
        if (date.month() != selectedDate.month()) {
            diaries = await this.getDiaires(date);
        }

        const curDiary = this.getDiary(diaries, date);

        this.setState({
            selectedDate: date,
            diaries,
            curDiary
        });
    };
    dateCellRender = date => {
        appUtils.moment.getDateOnly(date.utc());
        const diary = this.getDiary(this.state.diaries, date);
        if (diary) return <Badge status="success" />;
    };
    render() {
        const { childInfo, curDiary } = this.state;
        if (!childInfo) return "";

        return (
            <CardBlock title={`${childInfo.name}'s Diary`}>
                <p className="lead">
                    {curDiary ? curDiary.text : "No Activities"}
                </p>
                <Calendar
                    fullscreen={false}
                    onChange={this.onChange}
                    dateCellRender={this.dateCellRender}
                />
            </CardBlock>
        );
    }
}

export default DiaryCal;
