import moment from "moment";
import React, { Component } from "react";
import { Icon } from "antd";
import CardBlock from "../themeComps/cardBlock";
import deviceService from "../../services/deviceService";
import { toast } from "react-toastify";

class DeviceTbl extends Component {
    state = {
        devices: []
    };
    async componentDidMount() {
        await this.getDevices();
    }
    componentWillUnmount() {}

    getDevices = async () => {
        const { userID } = this.props;
        try {
            const { data } = await deviceService.getDevices(userID);
            this.setState({
                devices: data
            });
        } catch (ex) {
            console.log(ex);
        }
    };

    deleteDevice = async deviceID => {
        const { userID } = this.props;

        try {
            const { data } = await deviceService.deleteDevice(userID, deviceID);
            toast.success("Success to remove one device.");
            await this.getDevices();
        } catch (ex) {
            console.log(ex);
        }
    };

    render() {
        const { devices } = this.state;
        return (
            <CardBlock
                title="Devices"
                subTitle={`Available slots: ${5 - devices.length} out of 5`}
            >
                <div className="row">
                    {devices.map(d => {
                        return (
                            <div className="col-lg-6 col-md-12" key={d._id}>
                                {this.renderDevice(d)}
                            </div>
                        );
                    })}
                </div>
            </CardBlock>
        );
    }

    renderDevice = device => {
        return (
            <div className="text-center">
                <Icon
                    type={this.getDeviceIcon(device.deviceName)}
                    style={{ fontSize: "80pt" }}
                />
                {device.deviceName}
                <br />
                Last login:{" "}
                {moment(device.lastLogin).format("YYYY-MM-DD hh:mm")}
                <button
                    className="btn btn-link"
                    onClick={() => {
                        this.deleteDevice(device._id);
                    }}
                >
                    Delete
                </button>
            </div>
        );
    };

    getDeviceIcon = name => {
        name = name.toLowerCase();
        const list = ["chrome", "apple", "android", "windows"];

        if (name.includes("apple")) return "apple";

        if (name.includes("android")) return "android";

        if (name.includes("chrome")) return "chrome";

        return "windows";
    };
}

export default DeviceTbl;
