import React from "react";
import { NavLink } from "react-router-dom";

const TeacherNav = ({ curUser }) => {
    if (!curUser || !(curUser && (curUser.isTeacher || curUser.isSuper)))
        return "";
    return (
        <li className="parent nav-item">
            <a
                className="nav-link collapsed"
                data-toggle="collapse"
                href="#teacherMenu"
                aria-expanded="true"
            >
                <em className="fa fa-file-o">&nbsp;</em> Teacher{" "}
                <span
                    data-toggle="collapse"
                    href="#teacherMenu"
                    className="icon pull-right collapsed"
                    aria-expanded="true"
                >
                    <i className="fa fa-plus fa-minus" />
                </span>
            </a>
            <ul className="children collapse show" id="teacherMenu">
                <li className="nav-item">
                    <NavLink className="nav-link" to="/teacher/info">
                        teacher info
                    </NavLink>
                </li>
                {/* <li className="nav-item">
                    <NavLink className="nav-link" to="/teacher/classes">
                        Classes
                    </NavLink>
                </li> */}
            </ul>
        </li>
    );
};

export default TeacherNav;
