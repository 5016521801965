import React, { Component } from "react";
import Joi from "joi-browser";
import Form from "../commons/form";
import schoolService from "../../services/schoolService";

import { SchoolContext } from "./school";
import { toast } from "react-toastify";
import CardBlock from "../themeComps/cardBlock";

class TeacherFormInvite extends Form {
    state = {
        data: { email: "" },
        errors: {}
    };
    schema = {
        email: Joi.string()
            .email()
            .required()
    };
    doSubmit = async () => {
        const email = this.state.data.email;
        try {
            const { data } = await schoolService.inviteTeacher(email);
            if (data._id) {
                this.props.handleUserChange(data);
                toast.success("Teacher Added!");
            } else {
                toast.success("Invitation Sent!");
            }
            // this.props.toggleForm(false);

            return console.log(data);
        } catch (ex) {
            console.log(ex);
        }
    };
    render() {
        return (
            <CardBlock title="Invite teacher">
                <form onSubmit={this.handleSubmit}>
                    {this.renderInput("email", "Email", "email")}
                    {this.renderButton("Invite")}{" "}
                    <span
                        className="btn btn-default"
                        onClick={() => {
                            this.props.toggleForm(false);
                        }}
                    >
                        Close
                    </span>
                </form>
            </CardBlock>
        );
    }
}

export default TeacherFormInvite;
