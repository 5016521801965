import React, { Component } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../App";

import profilePic from "../../images/profile-pic.png";

class PageHeader extends Component {
    state = {};
    componentDidMount() {}
    componentWillUnmount() {}
    render() {
        const { app } = this.props;
        const { curUser, kids } = app.state;
        const kidName = kids[0] && kids[0].name;
        return (
            <header className="page-header row justify-center">
                <div className="col-md-6 col-lg-8">
                    <h1
                        className="float-left text-center text-md-left"
                        style={{ textTransform: "capitalize" }}
                    >
                        {typeof app.state.pageTitle === "string" &&
                            app.state.pageTitle}

                        {typeof app.state.pageTitle !== "string" &&
                            app.state.pageTitle()}
                    </h1>
                </div>

                <div className="dropdown user-dropdown col-md-6 col-lg-4 text-center text-md-right">
                    {curUser && (
                        <React.Fragment>
                            <a
                                className="btn btn-stripped dropdown-toggle"
                                href="void(0)"
                                id="dropdownMenuLink"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <img
                                    src={profilePic}
                                    alt=""
                                    className="circle float-left profile-photo"
                                    width="50"
                                    height="auto"
                                />
                                <div className="username mt-1">
                                    <h4 className="mb-1">{curUser.name}</h4>
                                    <h6 className="text-muted">{kidName}</h6>
                                </div>
                            </a>
                            <div
                                className="dropdown-menu dropdown-menu-right"
                                style={{ marginRight: "1.5rem" }}
                                aria-labelledby="dropdownMenuLink"
                            >
                                <Link className="dropdown-item" to="/profile">
                                    <em className="fa fa-user-circle mr-1" />{" "}
                                    View Profile
                                </Link>
                                {/* <a className="dropdown-item" href="void(0)">
                                    <em className="fa fa-sliders mr-1" />{" "}
                                    Preferences
                                </a> */}
                                <Link className="dropdown-item" to="/logout">
                                    <em className="fa fa-power-off mr-1" />{" "}
                                    Logout
                                </Link>
                            </div>
                        </React.Fragment>
                    )}
                </div>
                <div className="clear" />
            </header>
        );
    }
}

const _PageHeader = props => (
    <AppContext.Consumer>
        {app => <PageHeader {...props} app={app} />}
    </AppContext.Consumer>
);

export default _PageHeader;
