import _ from "lodash";
import React, { Component } from "react";
import Form from "../commons/form";
import Joi from "joi-browser";
import TextArea from "../commons/textarea";
import { toast } from "react-toastify";
import appUtils from "../../utils/appUtils";
import challengeService from "../../services/challengeService";

class QuestionForm extends Form {
    constructor(props) {
        super(props);
        const { question, curUser } = props;
        this.state = {
            data: {},
            options: [
                { opt: "", isAnswer: false },
                { opt: "", isAnswer: false },
                { opt: "", isAnswer: false },
                { opt: "", isAnswer: false }
            ],
            errors: {}
        };
        if (!question) {
            const data = { ...challengeService.getQuestionFilter() };
            if (!curUser.isSuper) data.owner = "school";
            data.question = "";
            data.key = 1;
            this.state.data = data;
        } else {
            this.state.data = _.pick(question, [
                "_id",
                "grade",
                "unit",
                "lesson",
                "key",
                "paragraph",
                "question",
                "options",
                "answers"
            ]);
            this.state.data.owner = question.owner.owner;
            this.state.options = question.options.map(opt => {
                return {
                    opt: opt,
                    isAnswer: question.answers.includes(opt)
                };
            });
        }
    }
    schema = {
        owner: Joi.string().required(),
        grade: Joi.string().required(),
        unit: Joi.number()
            .required()
            .min(1),
        lesson: Joi.when("owner", {
            is: Joi.string().valid("system"),
            then: Joi.valid(appUtils.lessonEnum).allow("", null),
            otherwise: Joi.string()
                .required()
                .valid(appUtils.lessonEnum)
        }),
        key: Joi.when("owner", {
            is: Joi.string().valid("system"),
            then: Joi.number()
                .min(1)
                .required(),
            otherwise: Joi.any()
        }),
        question: Joi.string().required(),
        paragraph: Joi.string(),
        options: Joi.array()
            .unique()
            .allow([]),
        answers: Joi.array()
            .unique()
            .allow([])
    };
    async componentDidMount() {
        const { question, curUser } = this.props;
    }
    formatData = (data, options) => {
        data.options = options.map(opt => opt.opt);
        data.answers = [];
        options.forEach(opt => {
            if (opt.isAnswer) {
                data.answers.push(opt.opt);
            }
        });

        if (data.owner === "system") {
        } else {
            data.key = null;
        }

        if (!data.lesson) delete data.lesson;

        return data;
    };
    doSubmit = async () => {
        const { question, handleClose } = this.props;
        let formData = { ...this.state.data };
        const options = [...this.state.options];

        formData = this.formatData(formData, options);
        // console.log(formData);
        try {
            if (!question) {
                //new
                const { data } = await challengeService.createQuestion(
                    formData
                );
                handleClose(true);
                toast.success("Added Successfully!");
            } else {
                //edit
                const { data } = await challengeService.updateQuestion(
                    formData
                );
                handleClose(true);
                toast.success("Edited Successfully!");
            }
        } catch (ex) {
            console.log(ex);
        }
    };
    addOpt = () => {
        const options = [...this.state.options];
        options.push({ opt: "", isAnswer: false });
        this.setState({ options });
    };
    render() {
        const { curUser } = this.props;
        const { data, options } = this.state;
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="row">
                    <div className="col-md-3 col-sm-6">
                        {this.renderSelect(
                            "owner",
                            "Owner",
                            [
                                { value: "system", label: "system" },
                                { value: "school", label: "My School" }
                            ],
                            curUser.isSuper ? null : { disabled: "disabled" }
                        )}
                    </div>
                    <div className="col-md-3 col-sm-6">
                        {this.renderSelect(
                            "grade",
                            "Grade",
                            appUtils.gradeEnum.map(g => ({
                                value: g,
                                label: g
                            }))
                        )}
                    </div>
                    <div className="col-md-3 col-sm-6">
                        {this.renderSelect(
                            "unit",
                            "Unit",
                            appUtils
                                .getUnitEnum()
                                .map(u => ({ value: u, label: u }))
                        )}
                    </div>
                    <div className="col-md-3 col-sm-6">
                        {this.renderSelect(
                            "lesson",
                            "Lesson",
                            appUtils.lessonEnum.map(L => ({
                                value: L,
                                label: L
                            }))
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {this.rednerTextArea("paragraph", "Reading")}
                    </div>
                </div>
                <div className="row">
                    {data.owner === "system" && (
                        <div className="col-2">
                            {this.renderInput("key", "Key", "number", "", {
                                min: 0
                            })}
                        </div>
                    )}

                    <div className="col">
                        {this.renderInput("question", "Question")}
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <label htmlFor="">
                                Options{" "}
                                <span
                                    style={{
                                        fontSize: "14pt",
                                        marginLeft: "10px",
                                        position: "relative",
                                        top: "3px",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => {
                                        this.addOpt();
                                    }}
                                >
                                    <i className="fa fa-plus" />
                                </span>
                            </label>
                            <div className="col">
                                {options.map((opt, index) =>
                                    this.renderOptInput(opt, index)
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col" style={{ textAlign: "right" }}>
                        <button
                            className="btn btn-primary"
                            disabled={this.validate()}
                        >
                            Submit
                        </button>{" "}
                        <span
                            className="btn btn-default"
                            onClick={() => {
                                this.props.handleClose();
                            }}
                        >
                            Cancel
                        </span>
                    </div>
                </div>
            </form>
        );
    }
    renderOptInput = (opt, index) => {
        return (
            <div className="row" key={index} style={{ marginBottom: "15px" }}>
                <div className="col-md-10">
                    <input
                        key={index}
                        className="form-control"
                        value={opt.opt}
                        onChange={e => {
                            const options = [...this.state.options];
                            options[index].opt = e.target.value;
                            this.setState({ options });
                        }}
                    />
                </div>
                <div className="col-md-2 " style={{ paddingTop: "14px" }}>
                    <input
                        type="checkbox"
                        checked={opt.isAnswer}
                        style={{ marginRight: "10px" }}
                        onChange={() => {
                            const options = [...this.state.options];
                            options[index].isAnswer = !options[index].isAnswer;
                            this.setState({ options });
                        }}
                    />
                    <span className="custom-control-description">Answer</span>

                    <span
                        className="fa fa-trash"
                        style={{
                            fontSize: "16pt",
                            cursor: "pointer",
                            marginLeft: "10px"
                        }}
                        onClick={() => {
                            const options = [...this.state.options];
                            options.splice(index, 1);
                            this.setState({ options });
                        }}
                    />
                </div>
            </div>
        );
    };
}

export default QuestionForm;
