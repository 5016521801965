import Joi from "joi-browser";
import { Table } from "antd";
import React, { Component } from "react";
import { toast } from "react-toastify";

import Form from "../commons/form";
import validateUtils from "../../utils/validateUtils";

import challengeService from "../../services/challengeService";
import assignmentService from "../../services/assignmentService";

import moment from "moment";

class Assignment_Form extends Form {
    constructor(props) {
        super(props);

        this.state = {
            data: {
                name: "",
                notes: "",
                challenge: "",
                dueDate: "",
                gClass: props.gClass._id,
                isActive: false,
                isTest: false
            },
            errors: {},
            challenges: []
        };

        if (props.dataToEdit && props.dataToEdit._id) {
            for (let p in this.state.data) {
                this.state.data[p] = props.dataToEdit[p];
            }
            if (this.state.data.dueDate) {
                this.state.data.dueDate = moment(
                    this.state.data.dueDate
                ).format("YYYY-MM-DD");
            }
        }
    }
    schema = {
        name: Joi.string().allow(""),
        notes: Joi.string().allow(""),
        challenge: Joi.string(),
        dueDate: Joi.date().allow("", null),
        gClass: Joi.string().required(),
        isActive: Joi.boolean(),
        isTest: Joi.boolean()
    };
    async componentDidMount() {
        const { gClass } = this.props;

        try {
            const { data } = await challengeService.getChallenges({
                grade: 1,
                $or: [
                    { "owner.owner": "system" },
                    {
                        $and: [
                            { "owner.owner": "school" },
                            { "owner.school": gClass.school._id }
                        ]
                    }
                ]
            });
            this.setState({
                challenges: data
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    doSubmit = async () => {
        const { dataToEdit } = this.props;
        const { data } = this.state;
        const formData = { ...data };

        if (!formData.dueDate) delete formData.dueDate;

        try {
            if (!dataToEdit) {
                const { data } = await assignmentService.createAssignment(
                    formData
                );
                toast.success("Create assignment successfully!");
                await this.props.closeModal(true);
            } else {
                formData._id = dataToEdit._id;
                const { data } = await assignmentService.updateAssignment(
                    formData
                );
                toast.success("Update assignment successfully!");
                await this.props.closeModal(true);
            }
        } catch (ex) {
            console.log(ex);
        }
    };
    render() {
        return (
            <form onSubmit={this.handleSubmit} className="row ">
                <div className="col-md-12">
                    {this.renderInput("name", "Assignment Name")}
                </div>
                <div className="col-md-12">
                    {this.rednerTextArea("notes", "Notes to studnet or parent")}
                </div>
                <div className="col-md-12">
                    {this.renderInput("dueDate", "Due Date", "date")}
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Picked Challenge</label>
                        {this.renderChallengePicker()}
                    </div>
                </div>
                <div className="col-md-6">
                    {this.renderSelect("isActive", "Is Active", [
                        { value: true, label: "Yes" },
                        { value: false, label: "No" }
                    ])}
                </div>
                <div className="col-md-6">
                    {this.renderSelect("isTest", "Is a test", [
                        { value: true, label: "Yes" },
                        { value: false, label: "No" }
                    ])}
                </div>

                <div className="col-md-12">
                    {this.renderButton("Submit")}{" "}
                    <span
                        className="btn btn-default"
                        onClick={() => {
                            this.props.closeModal();
                        }}
                    >
                        Cancel
                    </span>
                </div>
            </form>
        );
    }
    renderChallengePicker = () => {
        const { challenges, data } = this.state;

        const columns = [
            {
                title: "Owner",
                render: (v, r) => {
                    return r.owner.owner;
                }
            },
            {
                title: "Grade",
                dataIndex: "grade"
            },
            {
                title: "Unit",
                dataIndex: "unit"
            },
            {
                title: "Lesson",
                dataIndex: "lesson"
            },
            {
                title: "Description",
                dataIndex: "brief"
            },
            {
                title: "challengeID",
                dataIndex: "challengeID"
            },
            {
                title: "dateCreated",
                dataIndex: "dateCreated"
            }
        ];
        // rowSelection object indicates the need for row selection
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                const picked = selectedRows[0];
                const formData = { ...this.state.data };
                formData.challenge = picked._id;
                this.setState({
                    data: formData
                });
            },
            type: "radio",
            selectedRowKeys: [data.challenge]
        };
        return (
            <Table
                dataSource={challenges}
                rowSelection={rowSelection}
                columns={columns}
                pagination={true}
                bordered={true}
                rowKey="_id"
            />
        );
    };
}

export default Assignment_Form;
