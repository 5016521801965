import Joi from "joi-browser";
import React from "react";
import CardBlock from "./../themeComps/cardBlock";
import Form from "../commons/form";

import validateUtils from "../../utils/validateUtils";
import schoolService from "../../services/schoolService";
import GClassForm from "./gClassForm";

class GradeForm extends Form {
    state = {
        data: {
            schoolYearID: this.props.curSchoolYear._id,
            school: this.props.curSchoolYear.school,
            name: "",
            order: 0,
            gClasses: []
        },
        errors: {}
    };
    gradeVals = ["preSchool", "preK", "kindergarten", "1", "2", "3", "4", "5"];
    getGradeOpts = () => {
        const opts = this.gradeVals.map(v => {
            const obj = {
                label: v,
                value: v
            };
            return obj;
        });
        return opts;
    };
    joiSchoolYearSchema = {
        school: Joi.string()
            .required()
            .error(errors => {
                return validateUtils.JoiObjectIDErrorMsg(errors, "school");
            }),
        order: Joi.number(),
        name: Joi.string()
            .min(4)
            .max(32)
            .required()
    };
    joiGClassSchema = {
        school: Joi.string(),
        name: Joi.string()
            .max(32)
            .required(),
        order: Joi.number(),
        teachers: Joi.array().unique("_id")
    };
    schema = {
        schoolYearID: Joi.string(),
        name: Joi.string()
            .valid(this.gradeVals)
            .max(12)
            .required(),
        order: Joi.number(),
        gClasses: Joi.array()
            .unique("name")
            .items(Joi.object().keys(this.joiGClassSchema))
    };
    componentDidMount() {
        const { gradeToUpdate, curSchoolYear } = this.props;

        if (gradeToUpdate !== null) {
            gradeToUpdate.schoolYearID = curSchoolYear._id;
            gradeToUpdate.school = curSchoolYear.school;
            this.setState({ data: gradeToUpdate });
        }
    }
    componentDidUpdate(prevProps, prevState) {}
    doSubmit = async () => {
        const { curSchoolYear, toggleForm, updateCurSchoolYear } = this.props;
        const formData = JSON.parse(JSON.stringify(this.state.data));
        try {
            if (!formData._id) {
                //create new
                const { data } = await schoolService.createGrade(
                    formData,
                    curSchoolYear.school
                );
                updateCurSchoolYear(data);
            } else {
                //to update
                const { data } = await schoolService.updateGrade(
                    formData,
                    curSchoolYear.school
                );
                updateCurSchoolYear(data);
            }
            toggleForm(false);
        } catch (ex) {
            console.log(ex);
        }
    };
    handleGClassChange = gClasses => {
        const { curSchoolYear, updateCurSchoolYear } = this.props;
        const data = { ...this.state.data };
        gClasses.sort((a, b) => {
            return a.order - b.order;
        });
        data.gClasses = gClasses;
        this.setState({ data });

        if (data._id) {
            const gIndex = curSchoolYear.grades.findIndex(
                g => g._id === data._id
            );
            curSchoolYear.grades[gIndex] = data;
            updateCurSchoolYear(curSchoolYear);
        }
    };
    isUpdateGrade = () => {
        const gradeID = this.state.data._id;
        if (gradeID === null || gradeID === undefined || gradeID === "")
            return false;

        return true;
    };
    render() {
        const { curSchoolYear } = this.props;
        const { data } = this.state;
        return (
            <CardBlock
                title="Create a Grade"
                subTitle={"School Year: " + curSchoolYear.name}
            >
                <form onSubmit={this.handleSubmit} className="row">
                    <div className="col-md-6">
                        {this.renderSelect(
                            "name",
                            "Grade Name",
                            this.getGradeOpts()
                        )}
                    </div>
                    <div className="col-md-6">
                        {this.renderInput("order", "Order", "number")}
                    </div>
                </form>
                <div className="row">
                    <div className="col-md-12">
                        <CardBlock title="Classes">
                            <GClassForm
                                gClasses={data.gClasses}
                                handleGClassChange={this.handleGClassChange}
                                isUpdateGrade={this.isUpdateGrade}
                                school={data.school}
                                schoolYearID={data.schoolYearID}
                                gradeID={data._id}
                            />
                        </CardBlock>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <button
                            className="btn btn-primary"
                            disabled={this.validate()}
                            onClick={this.handleSubmit}
                        >
                            {this.isUpdateGrade() ? "Update" : "Create"}
                        </button>{" "}
                        <span
                            className="btn btn-default"
                            onClick={() => {
                                this.props.toggleForm(false);
                            }}
                        >
                            Close
                        </span>
                    </div>
                </div>
            </CardBlock>
        );
    }
}

export default GradeForm;
