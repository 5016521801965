import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import ProtectedRoute from "../navComps/protectedRoute";
import SystemChallengePage from "./systemChallenge";
import SchoolChallengePage from "./schoolChallenge";
import QuestionPage from "./questionPage";
import ChallengeForm from "./challengeForm";

import { AppContext } from "../../App";

class ChallengeComp extends Component {
    state = {};
    render() {
        return (
            <Switch>
                <ProtectedRoute
                    path="/challenge/systemChallenges"
                    component={SystemChallengePage}
                />
                <ProtectedRoute
                    path="/challenge/schoolChallenges"
                    component={SchoolChallengePage}
                />
                <ProtectedRoute
                    path="/challenge/questions/"
                    component={QuestionPage}
                />
                <ProtectedRoute
                    path="/challenge/form"
                    component={ChallengeForm}
                />
            </Switch>
        );
    }
}

const ChallengeComp_ = pros => {
    return (
        <AppContext.Consumer>
            {app => {
                return <ChallengeComp {...pros} app={app} />;
            }}
        </AppContext.Consumer>
    );
};
export default ChallengeComp_;
