import { Modal } from "antd";
import React, { Component } from "react";
import { AppContext } from "../../App";

import UnitBox from "./unitBox";
import materialService from "../../services/materialService";
import MaterialForm from "./materialForm";
import AlphabetList from "./alphabets";

class Materials extends Component {
    state = {
        modalVisible: false,
        modalTitle: "Create new material",
        unitCount: 40,
        materials: [],
        materialToEdit: null,
        modalWidth: 992
    };
    openModal = () => {
        const state = {
            modalVisible: true
        };
        if (window.innerWidth < 992) {
            state.modalWidth = 768;
        }
        this.setState(state);
    };
    closeModal = async formFlag => {
        this.setState({
            materialToEdit: null,
            modalVisible: false,
            modalTitle: "Create new material"
        });
        if (formFlag) await this.getData();
    };
    showEditForm = material => {
        const state = {
            materialToEdit: material,
            modalVisible: true,
            modalTitle: "Edit Unit " + material.unit + " " + material.lesson
        };
        if (window.innerWidth < 992) {
            state.modalWidth = 768;
        }
        this.setState(state);
    };
    async componentDidMount() {
        const { app, match, location } = this.props;

        await this.getData();

        app.setState({
            pageTitle: "Teaching Materials"
        });
    }
    getData = async () => {
        const { app, match, location } = this.props;

        const grade = match.params.grade;
        try {
            const { data } = await materialService.getGradeMaterials(grade);
            this.setState({
                materials: data
            });
        } catch (ex) {}
    };
    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.grade !== this.props.match.params.grade)
            await this.getData();
    }
    componentWillUnmount() {
        const { app } = this.props;
        app.setState({
            pageTitle: ""
        });
    }

    render() {
        const { app, match, location } = this.props;
        const grade = match.params.grade;
        const {
            unitCount,
            materials,
            modalVisible,
            materialToEdit,
            modalTitle,
            modalWidth
        } = this.state;
        const units = [];
        for (let u = 1; u <= unitCount; u++) {
            units.push(u);
        }

        return (
            <div>
                {grade === "preK" && <AlphabetList match={match} />}

                <div className="row">
                    <div className="col-md-12">
                        {app.state.curUser.isSuper && (
                            <span
                                className="btn btn-primary float-right m-4"
                                onClick={() => {
                                    this.openModal();
                                }}
                            >
                                Create Material
                            </span>
                        )}
                    </div>
                </div>

                <div className="row">
                    {units.map((u, index) => {
                        const unitMaterials = materials.filter(
                            m => m.unit === u
                        );
                        return (
                            <div
                                className="col-md-6 col-lg-4 col-xxl-3"
                                key={index}
                            >
                                <UnitBox
                                    unit={u}
                                    materials={unitMaterials}
                                    showEditForm={this.showEditForm}
                                />
                            </div>
                        );
                    })}
                </div>

                <Modal
                    title={modalTitle}
                    footer={null}
                    onCancel={() => {
                        this.closeModal();
                    }}
                    visible={modalVisible}
                    width={modalWidth}
                    maskClosable={false}
                    destroyOnClose={true}
                >
                    <MaterialForm
                        material={materialToEdit}
                        handleClose={this.closeModal}
                        grade={grade}
                    />
                </Modal>
            </div>
        );
    }
}

const _Materials = props => (
    <AppContext.Consumer>
        {app => <Materials {...props} app={app} />}
    </AppContext.Consumer>
);

export default _Materials;
