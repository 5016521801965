import React, { Component } from "react";
import CardBlock from "../themeComps/cardBlock";

import { AppContext } from "./../../App";

class SchoolInfo extends Component {
    state = {};
    async componentDidMount() {
        // console.log("schoolInfo did mount", app.state.curSchool.name);
    }
    render() {
        // console.log("schoolInfo render", app.state.curSchool.name);
        return <CardBlock title="Info" />;
    }
}

const _SchoolInfo = props => (
    <AppContext.Consumer>
        {app => <SchoolInfo {...props} app={app} />}
    </AppContext.Consumer>
);

export default _SchoolInfo;
