import React, { Component } from "react";
import { Link } from "react-router-dom";

import CardBlock from "../themeComps/cardBlock";
import Table from "../commons/table";
class Grade extends Component {
    state = {};
    dropDownOpts = [
        {
            label: "Edit",
            iconCls: "fa-pencil",
            handler: () => {
                const { openUpdateForm, grade } = this.props;
                openUpdateForm(grade);
            }
        },
        {
            label: "Delete",
            iconCls: "fa-trash",
            handler: () => {
                const { deleteGrade, grade } = this.props;
                deleteGrade(grade);
            }
        }
    ];
    columns = [
        {
            label: "Class",
            path: "name",
            content: item => (
                <Link to={"/class?classID=" + item._id}>{item.name}</Link>
            )
        },
        {
            label: "Order",
            path: "order"
        },
        {
            label: "Teachers",
            path: "teachers",
            content: item => {
                return item.teachers.map(t => t.name).join(",");
            }
        }
    ];
    render() {
        const { grade, subTitle } = this.props;
        const { gClasses } = grade;
        return (
            <CardBlock
                title={"Grade: " + grade.name}
                subTitle={"School Year: " + subTitle}
                dropDownOpts={this.dropDownOpts}
            >
                {gClasses.length === 0 && (
                    <div className="col-md-12">
                        <div className="jumbotron">
                            <h5 className="mb-2">Opps, No Class here!</h5>
                        </div>
                    </div>
                )}
                {gClasses.length > 0 && (
                    <Table data={gClasses} columns={this.columns} />
                )}
            </CardBlock>
        );
    }
}

export default Grade;
