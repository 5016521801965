import React from "react";
import { NavLink } from "react-router-dom";

const SuperNav = ({ curUser }) => {
    if (!curUser || (curUser && !curUser.isSuper)) return "";

    return (
        <li className="parent nav-item">
            <a
                className="nav-link collapsed"
                data-toggle="collapse"
                href="#superMenu"
                aria-expanded="true"
            >
                <em className="fa fa-file-o">&nbsp;</em> Super{" "}
                <span
                    data-toggle="collapse"
                    href="#superMenu"
                    className="icon pull-right collapsed"
                    aria-expanded="true"
                >
                    <i className="fa fa-plus fa-minus" />
                </span>
            </a>
            <ul className="children collapse show" id="superMenu">
                <li className="nav-item">
                    <NavLink className="nav-link" to="/super/schools">
                        Schools
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/super/users">
                        Users
                    </NavLink>
                </li>
                {/* <li className="nav-item">
                    <NavLink className="nav-link" to="/super/materials">
                        Materials
                    </NavLink>
                </li> */}
            </ul>
        </li>
    );
};

export default SuperNav;
