const Utils = class {
    constructor() {}
    moment = {
        getDateOnly: date => {
            return date
                .hour(0)
                .minute(0)
                .second(0)
                .millisecond(0);
        },
        getStartOfCalendar: date => {
            const d = date.clone();
            this.moment.getDateOnly(d.startOf("month").startOf("week"));
            return d;
        },
        getEndOfCalendar: date => {
            const d = date.clone();
            this.moment.getDateOnly(d.endOf("month").endOf("week"));
            return d;
        }
    };

    getQuestionFilter = challengeID => {
        try {
            const units = [];
            challengeID = challengeID.toLowerCase();
            if (!challengeID.includes("drabc")) return;
            const parts = challengeID.split("_");
            const unitParts = parts[2].replace("u", "").split("-");
            const unitFrom = parseInt(unitParts[0]);
            const unitTo = parseInt(unitParts[1]);
            for (let u = unitFrom; u <= unitTo; u++) {
                units.push(u);
            }
            const filter = {
                grade: parts[1].replace("g", ""),
                unit: { $in: units }
            };
            return filter;
        } catch (ex) {}
    };

    gradeOpts = [
        { label: "preSchool", value: "preSchool" },
        { label: "preK", value: "preK" },
        { label: "kindergarten", value: "" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" }
    ];
    gradeEnum = ["preSchool", "preK", "kindergarten", "1", "2", "3", "4", "5"];
    lessonEnum = ["phonic", "spelling", "grammar", "reading", "writing"];
    getUnitEnum = () => {
        const units = [];
        for (let i = 1; i <= 40; i++) {
            units.push(i);
        }
        return units;
    };
};
export default new Utils();
