import React, { Component } from "react";
import { Link } from "react-router-dom";
import CardBlock from "../themeComps/cardBlock";

import { AppContext } from "../../App";

class UnitBox extends Component {
    state = {};
    hasContent = materials => {
        if (materials && Array.isArray(materials) && materials.length > 0)
            return true;
        return false;
    };
    dropDownOpts = [
        {
            val: "phonic",
            label: "Edit phonic",
            iconCls: "fa-pencil",
            handler: () => {
                const { showEditForm, materials } = this.props;
                const lesson = materials.find(m => m.lesson === "phonic");
                showEditForm(lesson);
            }
        },
        {
            val: "spelling",
            label: "Edit spelling",
            iconCls: "fa-pencil",
            handler: () => {
                const { showEditForm, materials } = this.props;
                const lesson = materials.find(m => m.lesson === "spelling");
                showEditForm(lesson);
            }
        },
        {
            val: "grammar",
            label: "Edit grammar",
            iconCls: "fa-pencil",
            handler: () => {
                const { showEditForm, materials } = this.props;
                const lesson = materials.find(m => m.lesson === "grammar");
                showEditForm(lesson);
            }
        },
        {
            val: "reading",
            label: "Edit reading",
            iconCls: "fa-pencil",
            handler: () => {
                const { showEditForm, materials } = this.props;
                const reading = materials.find(m => m.lesson === "reading");
                showEditForm(reading);
            }
        },
        {
            val: "writing",
            label: "Edit writing",
            iconCls: "fa-pencil",
            handler: () => {
                const { showEditForm, materials } = this.props;
                const lesson = materials.find(m => m.lesson === "writing");
                showEditForm(lesson);
            }
        }
    ];
    render() {
        const { unit, materials, app } = this.props;
        if (!unit) return "";
        const phonic = materials.find(m => m.lesson === "phonic");
        const spelling = materials.find(m => m.lesson === "spelling");
        const grammar = materials.find(m => m.lesson === "grammar");
        const reading = materials.find(m => m.lesson === "reading");
        const writing = materials.find(m => m.lesson === "writing");
        const lessons = [phonic, spelling, grammar, reading, writing];
        if (!this.hasContent(materials)) return "";

        let dropDownOpts = [...this.dropDownOpts];
        if (!phonic)
            dropDownOpts = dropDownOpts.filter((d, i) => d.val != "phonic");
        if (!spelling)
            dropDownOpts = dropDownOpts.filter((d, i) => d.val != "spelling");
        if (!grammar)
            dropDownOpts = dropDownOpts.filter((d, i) => d.val != "grammar");
        if (!reading)
            dropDownOpts = dropDownOpts.filter((d, i) => d.val != "reading");
        if (!writing)
            dropDownOpts = dropDownOpts.filter((d, i) => d.val != "writing");

        if (!app.state.curUser.isSuper) dropDownOpts = null;

        return (
            <CardBlock title={"Unit " + unit} dropDownOpts={dropDownOpts}>
                <hr />
                <div className="body row unitLessons">
                    {!this.hasContent(materials) && (
                        <div className="col">
                            <span className="lead">Coming Soon!</span>
                        </div>
                    )}
                    {this.hasContent(materials) && (
                        <React.Fragment>
                            {lessons.map((lesson, index) => {
                                if (!lesson) return "";

                                let iconCls = "fa fa-microphone";
                                let pageName = "lesson";
                                if (lesson.lesson === "spelling") {
                                    iconCls = "fa fa-graduation-cap";
                                } else if (lesson.lesson === "grammar") {
                                    iconCls = "fa fa-file-text-o";
                                } else if (lesson.lesson === "reading") {
                                    iconCls = "fa fa-book";
                                    pageName = "reading";
                                } else if (lesson.lesson === "writing") {
                                    iconCls = "fa fa-pencil";
                                }

                                let materialUrl = `${
                                    process.env.REACT_APP_CLASS_ROOM_URL
                                }/${pageName}?materialID=${lesson._id}`;

                                if (
                                    Array.isArray(lesson.intro) &&
                                    lesson.intro.length > 0
                                ) {
                                    materialUrl = `${
                                        process.env.REACT_APP_CLASS_ROOM_URL
                                    }/reading/intro?materialID=${lesson._id}`;
                                }

                                return (
                                    <div className="col-md-6" key={index}>
                                        <a
                                            href={materialUrl}
                                            target="_blank"
                                            style={{
                                                textTransform: "capitalize"
                                            }}
                                        >
                                            <i
                                                className={iconCls}
                                                aria-hidden="true"
                                            />{" "}
                                            {lesson.lesson}
                                        </a>
                                        {this.renderEditBtn(lesson)}
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    )}
                </div>
            </CardBlock>
        );
    }
    renderEditBtn = lesson => {
        const { showEditForm } = this.props;
        return (
            <i
                className="fa fa-pencil text-primary"
                style={{ marginLeft: "10px", cursor: "pointer" }}
                onClick={() => {
                    showEditForm(lesson);
                }}
            />
        );
    };
}

const _UnitBox = props => (
    <AppContext.Consumer>
        {app => <UnitBox {...props} app={app} />}
    </AppContext.Consumer>
);

export default _UnitBox;
