import React, { Component } from "react";
import CardBlock from "../themeComps/cardBlock";
import { Link } from "react-router-dom";
import userService from "../../services/userService";
import { Table, Input, Button, Icon } from "antd";

class Children extends Component {
    async componentDidMount() {}
    dropDownOpts = [
        {
            label: "Create a kid",
            iconCls: "fa-plus",
            url: `/profile/${this.props.userID}/kidForm`
        }
    ];

    columns = [
        {
            title: "Name",
            dataIndex: "name",
            render: (val, user) => {
                const url = "/dashboard/" + user._id;
                return <Link to={url}>{val}</Link>;
            }
        }
    ];

    render() {
        const { kids, userID } = this.props;
        if (!userID) return "";
        return (
            <CardBlock title="Kids" dropDownOpts={this.dropDownOpts}>
                {kids.length === 0 && (
                    <p className="lead">
                        Opps, nothing here!
                        <br />
                        <Link
                            className="btn btn-primary mt-2"
                            to={`/profile/${userID}/kidForm`}
                            role="button"
                        >
                            Create
                        </Link>
                    </p>
                )}
                {kids.length > 0 && (
                    <Table
                        dataSource={kids}
                        columns={this.columns}
                        bordered={true}
                        pagination={false}
                        rowKey="_id"
                    />
                )}
            </CardBlock>
        );
    }
}

export default Children;
