import React, { Component } from "react";
import { toast } from "react-toastify";
import Grade from "./grade";
import GradeForm from "./gradeForm";
import schoolService from "../../services/schoolService";

class SchoolYear extends Component {
    state = {
        showForm: false,
        gradeToUpdate: null,
        curSchoolYear: null
    };

    async componentWillMount() {}

    async componentDidMount() {
        const { match } = this.props;
        try {
            const { data: curSchoolYear } = await schoolService.getYear(
                match.params.id
            );
            // const { data: teachers } = await schoolService.getTeachers();
            this.setState({ curSchoolYear });

            if (curSchoolYear && curSchoolYear.grades.length === 0)
                this.toggleForm(true);
        } catch (ex) {}
    }

    async componentDidUpdate(prevProps, prevState) {}

    toggleForm = value => {
        this.setState({ gradeToUpdate: null });
        if (value === null) value = !this.state.showForm;
        this.setState({ showForm: value });
    };
    openUpdateForm = grade => {
        this.toggleForm(false);
        setTimeout(() => {
            this.setState({ gradeToUpdate: grade, showForm: true });
        }, 100);
    };
    deleteGrade = async grade => {
        const curSchoolYear = { ...this.state.curSchoolYear };
        // console.log(curSchoolYear);
        // console.log(grade);

        try {
            const { data } = await schoolService.deleteGrade(
                grade._id,
                curSchoolYear
            );
            this.setState({ curSchoolYear: data });
            toast.success("Delete Successfully!");
            this.toggleForm(false);
        } catch (ex) {
            console.log(ex);
        }
    };
    updateCurSchoolYear = curSchoolYear => {
        this.setState({ curSchoolYear });
    };
    render() {
        const { showForm, gradeToUpdate, curSchoolYear } = this.state;
        const grades = curSchoolYear && curSchoolYear.grades;

        if (!curSchoolYear) {
            return "";
            return (
                <div className="jumbotron">
                    <h3 className="mb-4">Opps, Invalid School Year ID!</h3>
                </div>
            );
        }
        return (
            <React.Fragment>
                <section className="row">
                    {grades.length === 0 && (
                        <div className="col-md-12">
                            <div className="jumbotron">
                                <h1 className="mb-4">Opps, Nothing here!</h1>
                                <p className="lead">Please create one.</p>
                            </div>
                        </div>
                    )}
                    <div className="col-md-12">
                        {!showForm && (
                            <span
                                className="btn btn-primary float-right m-4"
                                onClick={() => {
                                    this.toggleForm(true);
                                }}
                            >
                                Create a new Grade
                            </span>
                        )}
                        {showForm && (
                            <GradeForm
                                curSchoolYear={curSchoolYear}
                                updateCurSchoolYear={this.updateCurSchoolYear}
                                toggleForm={this.toggleForm}
                                gradeToUpdate={gradeToUpdate}
                            />
                        )}
                    </div>
                </section>
                <section className="row">
                    {grades.map((g, index) => (
                        <div className="col-md-6" key={index}>
                            <Grade
                                grade={g}
                                subTitle={curSchoolYear.name}
                                deleteGrade={this.deleteGrade}
                                curSchoolYear={curSchoolYear}
                                openUpdateForm={this.openUpdateForm}
                            />
                        </div>
                    ))}
                </section>
            </React.Fragment>
        );
    }
}

export default SchoolYear;
