import React, { Component } from "react";
import { Table } from "antd";
import CardBlock from "../themeComps/cardBlock";
import schoolService from "../../services/schoolService";
import { SchoolContext } from "./school";
import { Link } from "react-router-dom";

class Students extends Component {
    state = {
        students: []
    };
    columns = [
        {
            title: "Name",
            dataIndex: "name",
            render: (val, user) => {
                return <Link to={"/dashboard/" + user._id}>{val}</Link>;
            },
            sorter: (a, b) => {
                let nameA = "",
                    nameB = "";
                try {
                    nameA = a.name.toLowerCase();
                } catch (ex) {}
                try {
                    nameB = b.name.toLowerCase();
                } catch (ex) {}
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;

                return 0;
            }
        },
        {
            title: "Class",
            dataIndex: "memberships",
            render: (val, record) => {
                const sID = this.props.schoolCmp.state.curSchoolID;
                const roles = val.filter(
                    v => v.roleName === "student" && v.school === sID
                );
                const role = roles[0];
                if (!role) return "";
                const { grade, name, schoolYear, _id: classID } = role.gClass;
                const classLink = "/class?classID=" + classID;
                const className = `${schoolYear.name} Grade ${
                    grade.name
                } Class ${name}`;

                return <Link to={classLink}>{className}</Link>;
            }
        }
    ];
    async componentDidMount() {
        try {
            const { data } = await schoolService.getStudents();
            console.log(data);
            this.setState({ students: data });
        } catch (ex) {
            console.log(ex);
        }
    }
    render() {
        const { curSchool } = this.props;
        const { students } = this.state;
        return (
            <div className="row">
                <div className="col">
                    <CardBlock title="Students">
                        {students.length > 0 && (
                            <Table
                                columns={this.columns}
                                dataSource={students}
                                pagination={true}
                                bordered={true}
                                rowKey="_id"
                                // className="table table-responsive"
                            />
                        )}
                    </CardBlock>
                </div>
            </div>
        );
    }
}

const _Students = props => (
    <SchoolContext.Consumer>
        {schoolCmp => <Students {...props} schoolCmp={schoolCmp} />}
    </SchoolContext.Consumer>
);

export default _Students;
