import _ from "lodash";
import Joi from "joi-browser";
import React from "react";
import { toast } from "react-toastify";

import Form from "../commons/form";
import CardBlock from "../themeComps/cardBlock";
import schoolService from "../../services/schoolService";
import userUtils from "../../utils/userUtils";

class TeacherForm extends Form {
    constructor(props) {
        super(props);
        this.schema = { ...userUtils.userSchema };
        this.schema.isManager = Joi.any();
        const userData = userUtils.createUserData();
        userData.isManager = 0;
        userData.memberships = [
            {
                roleName: "teacher",
                school: props.schoolID
            }
        ];
        this.state = {
            data: userData,
            errors: {}
        };
    }

    async componentDidMount() {
        const { userToUpdate } = this.props;
        if (userToUpdate !== null) {
            try {
                const { data } = await schoolService.getTeacher(userToUpdate);
                const userObj = userUtils.createUser(data);
                const user = _.pick(data, [
                    "__v",
                    "_id",
                    "name",
                    "email",
                    "memberships"
                ]);
                user.password = "";
                if (userObj.isManager) user.isManager = 1;
                this.setState({ data: user });
            } catch (ex) {
                console.log(ex);
            }
        }
    }
    componentWillUnmount() {}

    doSubmit = async () => {
        const { handleUserChange, toggleForm, schoolID } = this.props;

        const managerRole = { roleName: "manager", school: schoolID };
        const formData = JSON.parse(JSON.stringify(this.state.data));

        try {
            if (!formData._id) {
                //add
                if (formData.isManager.toString() === "1")
                    formData.memberships.push(managerRole);

                delete formData._id;
                const { data } = await schoolService.createTeacher(formData);
                if (data) {
                    toast.success("Success to add!!!");
                    handleUserChange(data);
                    toggleForm(false);
                }
            } else {
                //update
                if (
                    !formData.isManager ||
                    formData.isManager.toString() === "0"
                ) {
                    formData.memberships = formData.memberships.filter(
                        m =>
                            !(m.roleName === "manager" && m.school === schoolID)
                    );
                } else {
                    const hasMeangerRole = formData.memberships.find(
                        m => m.roleName === "manager" && m.school === schoolID
                    );
                    if (!hasMeangerRole) {
                        formData.memberships.push(managerRole);
                    }
                }
                // console.log(formData);
                const { data } = await schoolService.updateTeacher(formData);
                if (data) {
                    toast.success("Success to update!!!");
                    handleUserChange(data);
                    toggleForm(false);
                }
            }
        } catch (ex) {
            console.log(ex);
        }
    };

    render() {
        const { data } = this.state;
        return (
            <div className="row">
                <div className="col">
                    <CardBlock
                        title="Teacher Form"
                        subTitle={
                            data._id
                                ? "Update Teacher Info"
                                : "Create a teacher"
                        }
                    >
                        <form onSubmit={this.handleSubmit}>
                            <div className="row">
                                <div className="col-md-6">
                                    {this.renderInput("name", "Name")}
                                </div>
                                <div className="col-md-6">
                                    {this.renderSelect(
                                        "isManager",
                                        "Assign Manager Role",
                                        [
                                            { value: "1", label: "Yes" },
                                            { value: "0", label: "No" }
                                        ]
                                    )}
                                </div>
                                <div className="col-md-6">
                                    {this.renderInput(
                                        "email",
                                        "Email",
                                        "email"
                                    )}
                                </div>
                                <div className="col-md-6">
                                    {this.renderInput(
                                        "password",
                                        "Password",
                                        "password",
                                        null,
                                        {
                                            autoComplete: "new-password"
                                        }
                                    )}
                                </div>
                            </div>
                            {this.renderButton(data._id ? "Update" : "Create")}{" "}
                            <span
                                className="btn btn-default"
                                onClick={() => {
                                    this.props.toggleForm();
                                }}
                            >
                                Close
                            </span>
                        </form>
                    </CardBlock>
                </div>
            </div>
        );
    }
}

export default TeacherForm;
