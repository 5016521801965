import _ from "lodash";
import Joi from "joi-browser";
import React from "react";
import { toast } from "react-toastify";

import Form from "../commons/form";
import CardBlock from "../themeComps/cardBlock";
import userService from "../../services/userService";
import userUtils from "../../utils/userUtils";

class UserForm extends Form {
    constructor(props) {
        super(props);
        this.schema = { ...userUtils.userSchema };
        this.schema.isSuper = Joi.any();
        const userData = userUtils.createUserData();
        userData.isSuper = 0;
        this.state = {
            data: userData,
            errors: {}
        };
    }

    async componentDidMount() {
        const { userToUpdate } = this.props;
        if (userToUpdate !== null) {
            try {
                const { data } = await userService.getUser(userToUpdate);
                const userObj = userUtils.createUser(data);
                const user = _.pick(data, [
                    "__v",
                    "_id",
                    "name",
                    "email",
                    "memberships"
                ]);
                user.password = "";
                if (userObj.isSuper) user.isSuper = 1;
                // console.log(user);
                this.setState({ data: user });
            } catch (ex) {
                console.log(ex);
            }
        }
    }
    componentWillUnmount() {}

    doSubmit = async () => {
        const { handleUserChange, toggleForm } = this.props;
        const superRole = { roleName: "super" };
        const formData = JSON.parse(JSON.stringify(this.state.data));
        try {
            if (!formData._id) {
                //add
                if (formData.isSuper.toString() === "1") {
                    formData.memberships.push(superRole);
                } else {
                    formData.memberships = formData.memberships.filter(
                        m => m.roleName !== "super"
                    );
                }

                delete formData._id;
                const { data } = await userService.register(formData);
                if (data) {
                    toast.success("Success to add!!!");
                    handleUserChange(data);
                    toggleForm(false);
                }
            } else {
                //update
                if (!formData.isSuper || formData.isSuper.toString() === "0") {
                    formData.memberships = formData.memberships.filter(
                        m => m.roleName !== "super"
                    );
                } else {
                    const hasSuper = formData.memberships.find(
                        m => m.roleName === "super"
                    );
                    if (!hasSuper) {
                        formData.memberships.push(superRole);
                    }
                }
                // console.log(formData);
                const { data } = await userService.updateUser(formData);

                if (data) {
                    toast.success("Success to update!!!");
                    handleUserChange(data);
                    toggleForm(false);
                }
            }
        } catch (ex) {
            console.log(ex);
        }

        console.log("do submit");
    };

    render() {
        const { data } = this.state;
        return (
            <div className="row">
                <div className="col">
                    <CardBlock title="User Form" subTitle="Create a new user">
                        <form onSubmit={this.handleSubmit}>
                            <div className="row">
                                <div className="col-md-6">
                                    {this.renderInput("name", "Name")}
                                </div>
                                <div className="col-md-6">
                                    {this.renderSelect(
                                        "isSuper",
                                        "Assign Super Role",
                                        [
                                            { value: "1", label: "Yes" },
                                            { value: "0", label: "No" }
                                        ]
                                    )}
                                </div>
                                <div className="col-md-6">
                                    {this.renderInput(
                                        "email",
                                        "Email",
                                        "email"
                                    )}
                                </div>
                                <div className="col-md-6">
                                    {this.renderInput(
                                        "password",
                                        "Password",
                                        "password",
                                        null,
                                        {
                                            autoComplete: "new-password"
                                        }
                                    )}
                                </div>
                            </div>
                            {this.renderButton(data._id ? "Update" : "Create")}{" "}
                            <span
                                className="btn btn-default"
                                onClick={() => {
                                    this.props.toggleForm();
                                }}
                            >
                                Close
                            </span>
                        </form>
                    </CardBlock>
                </div>
            </div>
        );
    }
}

export default UserForm;
