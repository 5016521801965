import moment from "moment";
import Joi from "joi-browser";

const utils = class {
    constructor() {}

    Subscription = () => {
        const obj = {};
        obj.on = false;
        obj.type = "headCount";
        obj.app = "mobile";
        obj.grade = "1";
        obj.price = 500;
        obj.quote = 0;
        obj.status = "active";
        obj.dateCreated = null;
        obj.dateActived = null;
        obj.dateExpired = null;
        obj.dateSuspended = null;
        return obj;
    };
    School = () => {
        const obj = {};
        obj.name = "";
        obj.schoolCode = "";
        obj.addresses = [];
        obj.subscriptions = [];
        return obj;
    };
    joiSubscriptionSchema = {
        type: Joi.string()
            .valid("headCount", "quote")
            .required(),
        app: Joi.string()
            .valid("mobile", "classroom")
            .required(),
        grade: Joi.string()
            .valid("preSchool", "preK", "kindergarten", "1", "2", "3", "4", "5")
            .max(12)
            .required(),
        price: Joi.number()
            .min(0)
            .required(),
        quote: Joi.number()
            .when("type", {
                is: Joi.valid("quote"),
                then: Joi.valid(30, 60, 200, 500, 99999999)
            })
            .when("type", {
                is: Joi.valid("headCount"),
                then: Joi.valid(0)
            }),
        status: Joi.string().valid("trial", "active", "suspended"),
        dateExpired: Joi.date().allow(null),
        dateActived: Joi.date().allow(null),
        dateCreated: Joi.date().allow(null)
    };
    joiSchoolSchema = {
        name: Joi.string()
            .min(3)
            .max(256)
            .required(),
        schoolCode: Joi.string().allow(""),
        addresses: Joi.array().unique()
        // subscriptions: Joi.array()
        //     .unique((a, b) => a.grade == b.grade && a.app == b.app)
        //     .items(Joi.object().keys(this.joiSubscriptionSchema))
    };
    validateSchool = school => {
        return Joi.validate(school, this.joiSchoolSchema, {
            allowUnknown: true
        });
    };
    gradeEnum = ["preSchool", "preK", "kindergarten", "1", "2", "3", "4", "5"];
    gradeEnum_available = ["preK", "1"];
    appEnum = ["mobile", "classroom"];
};

export default new utils();
