import QueryString from "query-string";
import jwtDecode from "jwt-decode";
import React from "react";
import Joi from "joi-browser";

import Form from "./commons/form";
import { AppContext } from "../App";
import auth from "../services/authService";
import schoolService from "../services/schoolService";

class PageAccept extends Form {
    constructor(props) {
        super(props);
        const query = QueryString.parse(props.location.search);
        const tokenObj = jwtDecode(query.token);
        this.state = {
            data: {
                email: tokenObj.email,
                password: "",
                name: "",
                token: query.token
            },
            errors: {}
        };
    }

    schema = {
        name: Joi.string()
            .min(3)
            .max(50)
            .label("Name"),
        email: Joi.string()
            .min(5)
            .max(255)
            .email()
            .required()
            .label("Email"),
        password: Joi.string()
            .required()
            .min(8)
            .max(26)
            .label("Password")
    };

    componentDidMount() {
        const { app } = this.props;
        app.setState({
            pageTitle: "Accept Your Invitation"
        });
    }
    componentWillUnmount() {
        const { app } = this.props;
        app.setState({
            pageTitle: ""
        });
    }

    doSubmit = async () => {
        const formData = { ...this.state.data };
        try {
            const resp = await schoolService.acceptInvite(formData);
            auth.loginWithJwt(resp.data);
            const { state } = this.props.location;
            window.location = state ? state.from.pathname : "/";
        } catch (ex) {}
    };
    render() {
        return (
            <React.Fragment>
                <form onSubmit={this.handleSubmit}>
                    {this.renderInput("name", "Name")}
                    {this.renderInput("email", "Email", "email", null, {
                        disabled: true
                    })}
                    {this.renderInput("password", "Password", "password")}
                    {this.renderButton("Register")}
                </form>
            </React.Fragment>
        );
    }
}

const _PageAccept = props => (
    <AppContext.Consumer>
        {app => <PageAccept {...props} app={app} />}
    </AppContext.Consumer>
);

export default _PageAccept;
