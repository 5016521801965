import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class MaterialNav extends Component {
    state = {};
    render() {
        const { curUser } = this.props;
        if (!curUser) return "";
        if (!(curUser.isSuper || curUser.isManager || curUser.isTeacher))
            return "";
        return (
            <li className="parent nav-item">
                <a
                    className="nav-link collapsed"
                    data-toggle="collapse"
                    href="#materialMenu"
                    aria-expanded="false"
                >
                    <em className="fa fa-book" /> Teaching Materials{" "}
                    <span
                        data-toggle="collapse"
                        href="#materialMenu"
                        className="icon pull-right collapsed"
                        aria-expanded="false"
                    >
                        <i className="fa fa-plus" />
                    </span>
                </a>
                <ul className="children collapse" id="materialMenu">
                    <li className="nav-item">
                        <NavLink
                            className="nav-link"
                            to="/materials/grade/preK"
                        >
                            PreK
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/materials/grade/1">
                            Grade 1
                        </NavLink>
                    </li>
                </ul>
            </li>
        );
    }
}

export default MaterialNav;
