import React, { Component } from "react";
import { AppContext } from "../App";

class PageAbout extends Component {
    state = {};
    componentDidMount() {
        const { app } = this.props;

        app.setState({
            pageTitle: `About Dr.ABC!`
        });
    }
    componentWillUnmount() {
        const { app } = this.props;
        app.setState({
            pageTitle: ""
        });
    }
    render() {
        return (
            <div className="container">
                <div className="row justify-content-md-center">
                    <div className="col">
                        <div className="jumbotron">
                            <h1 className="mb-4">MISSION</h1>
                            <p className="lead">
                                Through funny stories, interactive games,
                                augmented reality & virtual reality
                            </p>
                            <ul>
                                <li>
                                    turning traditional educational methods into
                                    modern; and
                                </li>
                                <li>
                                    developing kids education platform with the
                                    times. In the meanwhile,
                                </li>
                            </ul>
                            <p className="lead">
                                Through playing interactive games at home, to
                            </p>
                            <ul>
                                <li>build up the family intimacy.</li>
                            </ul>
                        </div>
                        {/* */}
                        <div className="jumbotron">
                            <h1 className="mb-4">VISION</h1>
                            <p className="lead">
                                To be an international educational platform and
                                provide high standard curriculums with the
                                times.
                            </p>
                            <p className="lead">
                                <b>Teaching Media</b>
                            </p>
                            <p>
                                To be an educational platform with the times
                                through constantly review and delve into the
                                most appropriate teaching method and media for
                                each subject.
                            </p>
                            <p className="lead">
                                <b>Variety of Subject</b>
                            </p>
                            <p>
                                To be an educational platform, not only
                                providing traditional subjects but also
                                providing timely subjects such as emotion
                                self-control, financial management and more.
                            </p>
                            <p className="lead">
                                <b>Data Analysis</b>
                            </p>
                            <p>
                                To be an educational platform with collecting
                                and analyzing children’s learning result to find
                                out the real-time learning situation of each
                                child.
                            </p>
                            <p className="lead">
                                <b>Group Learning</b>
                            </p>
                            <p>
                                To be an interactive educational platform with
                                the “campus version” to improve interest and
                                interactivity of the group learning and assist
                                teacher in understanding the real-time situation
                                of each student so that teacher can make an
                                appropriate follow-up.
                            </p>
                            <p className="lead">
                                <b>Global</b>
                            </p>
                            <p>
                                To be a world-class educational platform with
                                offering multiple languages and fine-tune
                                curriculums according to different countries’
                                life cultures.
                            </p>
                        </div>
                        {/*  */}
                        <div className="jumbotron">
                            <h1 className="mb-4">RESEARCH FOCUS</h1>
                            <ul>
                                <li>Teaching Media</li>
                                <li>Timely Subjects</li>
                                <li>World Education Trends</li>
                                <li>Children Growth</li>
                                <li>Family Intimacy</li>
                                <li>Data Protection</li>
                                <li>Personal Privacy</li>
                            </ul>
                        </div>
                        {/*  */}
                        <div className="jumbotron">
                            <h1 className="mb-4">RESEARCH FOCUS</h1>
                            <p className="lead">
                                <b>Children</b>
                            </p>
                            <p>
                                To inspire children’s talent and learning
                                interest, establish good living habit in the
                                early age under interesting stories, games and
                                interactive environment.
                            </p>

                            <p className="lead">
                                <b>Family</b>
                            </p>
                            <p>
                                Providing a platform to let parents interact
                                with the children to build up family intimacy.
                            </p>

                            <p className="lead">
                                <b>Educational Research</b>
                            </p>
                            <p>
                                The growth of modern children is radically
                                different from the children in the past such as
                                thing they need to learn, life model, social
                                culture and even things they are facing every
                                day. Thus, learning subject, curriculum and
                                teaching method need to improve constantly as
                                well.
                            </p>

                            <p className="lead">
                                <b>School</b>
                            </p>
                            <p>
                                Through cooperation, interactivity and
                                competition in the “campus version” to learn
                                teamwork in the classroom. Teacher can also
                                easily grasp the real-time learning situation
                                and need of each classmate.
                            </p>

                            <p className="lead">
                                <b>Commitment To The World</b>
                            </p>
                            <p>
                                To inspire talent of each; Constantly innovate
                                educational method and curriculum.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const _PageAbout = props => (
    <AppContext.Consumer>
        {app => <PageAbout {...props} app={app} />}
    </AppContext.Consumer>
);

export default _PageAbout;
