import Joi from "joi-browser";
import React from "react";
import Form from "../commons/form";
import Table from "../commons/table";
import { toast } from "react-toastify";
import ObjectID from "bson-objectid";
import schoolService from "../../services/schoolService";

import MultiSelect from "@khanacademy/react-multi-select";

class GClassForm extends Form {
    state = {
        data: {
            _id: ObjectID().toString(),
            name: "",
            order: 0,
            teachers: []
        },
        errors: {},
        teacherOpts: []
    };
    schema = {
        name: Joi.string()
            .max(32)
            .required(),
        order: Joi.number(),
        teachers: Joi.array().unique()
    };
    columns = [
        {
            label: "Name",
            path: "name"
        },
        {
            label: "Order",
            path: "order"
        },
        {
            label: "teachers",
            path: "teachers",
            content: item => {
                return item.teachers.map(t => t.name).join(",");
            }
        },
        {
            label: "",
            path: "",
            content: item => {
                return (
                    <div>
                        <span
                            className="btn btn-default btn-sm"
                            onClick={() => {
                                this.editBtnHandler(item);
                            }}
                        >
                            Edit
                        </span>{" "}
                        <span
                            className="btn btn-danger btn-sm"
                            onClick={() => {
                                this.deleteBtnHandler(item);
                            }}
                        >
                            Delete
                        </span>
                    </div>
                );
            }
        }
    ];

    async componentDidMount() {
        try {
            const { data: teachers } = await schoolService.getTeachers();
            const teacherOpts = this.createTeahcerOpts(teachers);
            this.setState({ teacherOpts });
        } catch (ex) {
            console.log(ex);
        }
    }

    createTeahcerOpts = teachers => {
        const opts = teachers.map(t => {
            t.value = t._id;
            t.label = t.name;
            return t;
        });
        return opts;
    };

    doSubmit = async () => {
        const {
            handleGClassChange,
            gClasses,
            isUpdateGrade,
            school,
            schoolYearID,
            gradeID
        } = this.props;
        const { teacherOpts } = this.state;
        const formData = JSON.parse(JSON.stringify(this.state.data));
        formData.name = formData.name.toLowerCase();

        if (isUpdateGrade() && this.isUpdate()) {
            // update db
            formData.grade = gradeID;
            formData.school = school;
            formData.schoolYear = schoolYearID;
            console.log(formData);
            try {
                const { data } = await schoolService.updateClass(formData);
                console.log(data);
                const index = gClasses.findIndex(c => c._id === data._id);
                gClasses[index] = data;
                handleGClassChange(gClasses);
                toast.success("Success to update class!");
                this.resetData();
            } catch (ex) {
                console.log(ex);
            }
        } else if (isUpdateGrade() && !this.isUpdate()) {
            formData.grade = gradeID;
            formData.school = school;
            formData.schoolYear = schoolYearID;
            try {
                const { data } = await schoolService.createClass(formData);
                console.log(data);
                gClasses.push(data);
                handleGClassChange(gClasses);
                toast.success("Success to add class!");
                this.resetData();
            } catch (ex) {
                console.log(ex);
            }
        } else if (this.isUpdate()) {
            //update class on create new grade, not in db
            let match = gClasses.find(
                c => c.name === formData.name && c._id !== formData._id
            );

            if (match) return toast.error("Duplicated Class name!");
            formData.teachers = formData.teachers.map(t => {
                const tObj = teacherOpts.find(o => o._id === t);
                return tObj;
            });
            const index = gClasses.findIndex(c => c._id === formData._id);
            gClasses[index] = formData;
            handleGClassChange(gClasses);
            this.resetData();
        } else {
            //when crate new grade, not in db yet
            let match = gClasses.find(c => c.name === formData.name);
            if (match) return toast.error("Duplicated Class name!");
            formData.teachers = formData.teachers.map(t => {
                const tObj = teacherOpts.find(o => o._id === t);
                return tObj;
            });
            gClasses.push(formData);
            handleGClassChange(gClasses);
            this.resetData();
        }
    };
    editBtnHandler = item => {
        const data = { ...item };
        console.log(data);
        data.teachers = data.teachers.map(t => t._id);
        this.setState({ data });
    };
    deleteBtnHandler = async item => {
        const { gClasses, handleGClassChange, isUpdateGrade } = this.props;
        if (isUpdateGrade()) {
            try {
                const { data } = await schoolService.deleteClass(item._id);

                const items = gClasses.filter(c => c._id !== data._id);
                handleGClassChange(items);
            } catch (ex) {}
        } else {
            //when create new grade, no in db yet
            const items = gClasses.filter(c => c._id !== item._id);
            handleGClassChange(items);
        }
    };

    isUpdate = () => {
        const { gClasses } = this.props;
        return gClasses.find(c => c._id === this.state.data._id);
    };
    resetData = () => {
        this.setState({
            data: {
                _id: ObjectID().toString(),
                name: "",
                order: 0,
                teachers: []
            }
        });
    };
    onTeacherSelChange = selected => {
        const formData = { ...this.state.data };
        formData.teachers = selected;
        this.setState({ data: formData });
    };
    render() {
        const { gClasses } = this.props;
        const { teacherOpts, data } = this.state;
        return (
            <form className="row" onSubmit={this.handleSubmit}>
                {gClasses.length === 0 && (
                    <div className="col-md-12">
                        <div className="jumbotron">
                            <h5 className="mb-2">Opps, Nothing here!</h5>
                        </div>
                    </div>
                )}
                {gClasses.length > 0 && (
                    <Table data={gClasses} columns={this.columns} />
                )}

                <div className="col-md-3">
                    {this.renderInput("name", "Name")}
                </div>
                <div className="col-md-3">
                    {this.renderInput("order", "Order", "number")}
                </div>
                <div className="col-md-4">
                    <label htmlFor="">Teachers</label>
                    <div>
                        <MultiSelect
                            options={teacherOpts}
                            onSelectedChanged={this.onTeacherSelChange}
                            selected={data.teachers}
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="col-md-2">
                    <div className="form-group">
                        <label htmlFor="">&nbsp;</label>
                        <div style={{ textAlign: "center" }}>
                            {/* <button
                                className="btn btn-primary btn-circle margin"
                                onClick={e => {
                                    e.preventDefault();
                                }}
                            >
                                <span class="fa fa-plus"> </span>
                            </button> */}
                            {this.renderButton(
                                this.isUpdate() ? "Update" : "Add"
                            )}
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

export default GClassForm;
