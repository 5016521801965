import React, { Component } from "react";
import { toast } from "react-toastify";
import CardBlock from "../themeComps/cardBlock";
import Table from "../commons/table";
import ManagerForm from "./managerForm";
import schoolService from "../../services/schoolService";
import { SchoolContext } from "./school";

class Managers extends Component {
    state = {
        showForm: false,
        managers: []
    };
    columns = [
        { label: "Name", path: "name" },
        { label: "email", path: "email" },
        {
            label: "",
            path: "",
            content: item => {
                return (
                    <span
                        className="trash"
                        style={{ cursor: "pointer", textDecoration: "none" }}
                        onClick={() => {
                            this.deleteHandler(item);
                        }}
                    >
                        <em className="fa fa-trash" />
                    </span>
                );
            }
        }
    ];
    dropDownOpts = [
        {
            label: "Add new manager",
            iconCls: "fa-plus",
            handler: () => {
                this.toggleForm(true);
            }
        }
    ];
    async componentDidMount() {
        try {
            const { data } = await schoolService.getManagers();
            this.setState({ managers: data });
        } catch (ex) {
            console.log(ex);
        }
    }
    toggleForm = val => {
        if (val === null) val = false;
        this.setState({ showForm: val });
    };
    deleteHandler = async manager => {
        let managers = [...this.state.managers];
        try {
            const { data } = await schoolService.deleteManager(manager);

            managers = managers.filter(m => m._id !== data._id);
            this.setState({ managers });
            toast.success("Success!!!");
        } catch (ex) {
            console.log(ex);
        }
    };
    render() {
        const { curSchool } = this.props;
        const { showForm, managers } = this.state;
        return (
            <CardBlock title="Managers" dropDownOpts={this.dropDownOpts}>
                {showForm && (
                    <div className="row">
                        <div className="col-md-12 m-2">
                            <ManagerForm
                                toggleForm={this.toggleForm}
                                curSchool={curSchool}
                            />
                        </div>
                    </div>
                )}

                <div className="row">
                    <div className="col-md-12">
                        <Table data={managers} columns={this.columns} />
                    </div>
                </div>
            </CardBlock>
        );
    }
}

const _Managers = props => (
    <SchoolContext.Consumer>
        {schoolCmp => <Managers {...props} schoolCmp={schoolCmp} />}
    </SchoolContext.Consumer>
);

export default _Managers;
