import React, { Component } from "react";
import { Table, Modal, Button } from "antd";
import CardBlock from "../themeComps/cardBlock";
import Assignment_Form from "./assignment_form";
import assignmentService from "../../services/assignmentService";
import moment from "moment";
import { toast } from "react-toastify";

class Assignment extends Component {
    state = {
        showForm: false,
        dataToEdit: null,
        modalWidth: 992,
        assignments: []
    };
    async componentDidMount() {
        await this.setUpData();
    }
    setUpData = async () => {
        const { gClass } = this.props;
        if (!gClass) return;

        try {
            const { data } = await assignmentService.getAssignments({
                gClass: gClass._id
            });
            this.setState({
                assignments: data
            });
        } catch (ex) {
            console.log(ex);
        }
    };
    dropdownOpts = [
        {
            label: "Create Assignment",
            iconCls: "fa-plus",
            url: "",
            handler: () => {
                this.toggleForm(true);
            }
        }
    ];
    toggleForm = val => {
        if (val === null) val = !this.state.showForm;

        const state = { showForm: val };
        if (window.innerWidth < 992) {
            state.modalWidth = 768;
        }

        this.setState(state);
    };
    closeModal = async flag => {
        this.setState({ showForm: false, dataToEdit: null });
        if (flag) await this.setUpData();
    };
    createModal = () => {
        const { gClass } = this.props;
        const { dataToEdit } = this.state;
        return (
            <Modal
                title={<h3>Create an assignment</h3>}
                visible={this.state.showForm}
                onCancel={this.closeModal}
                width={this.state.modalWidth}
                maskClosable={false}
                destroyOnClose={true}
                footer={null}
            >
                <Assignment_Form
                    gClass={gClass}
                    closeModal={this.closeModal}
                    dataToEdit={dataToEdit}
                />
            </Modal>
        );
    };
    render() {
        const { classID } = this.props;
        return (
            <React.Fragment>
                <CardBlock title="Assignments" dropDownOpts={this.dropdownOpts}>
                    {this.renderAssignmentTbl()}
                </CardBlock>
                {this.createModal()}
            </React.Fragment>
        );
    }
    editAssignment = async r => {
        try {
            const { data } = await assignmentService.getAssignment(r._id);

            this.setState({
                dataToEdit: data,
                showForm: true
            });
        } catch (ex) {
            console.log(ex);
        }
    };
    deleteAssignment = async r => {
        const toDel = window.confirm("Are u sure want to delete this?");

        if (!toDel) return;

        try {
            await assignmentService.deleteAssignment(r._id);
            toast.success("Delete assignment successfully!");
            await this.setUpData();
        } catch (ex) {
            console.log(ex);
        }
    };
    renderAssignmentTbl = () => {
        const { assignments } = this.state;
        const { gClass } = this.props;

        const columns = [
            { title: "Name", dataIndex: "name" },
            {
                title: "Due Date",
                dataIndex: "dueDate",
                render: (v, r) => {
                    if (v) return moment(v).format("YYYY-MM-DD");
                }
            },
            {
                title: "Is Active",
                dataIndex: "isActive",
                render: (v, r) => {
                    if (v) return "Yes";
                }
            },
            {
                title: "Is a Test",
                dataIndex: "isTest",
                render: (v, r) => {
                    if (v) return "Yes";
                }
            },
            {
                title: "",
                render: (v, r) => {
                    const { history } = this.props;
                    return (
                        <div className="btn-margins">
                            <button
                                title="Delete Assignment"
                                className="btn btn-sm btn-danger btn-circle "
                                onClick={async () => {
                                    await this.deleteAssignment(r);
                                }}
                            >
                                <span className="fa fa-trash" />
                            </button>
                            <button
                                title="Edit Assignment"
                                className="btn btn-sm btn-primary btn-circle "
                                onClick={async () => {
                                    await this.editAssignment(r);
                                }}
                            >
                                <span className="fa fa-edit" />
                            </button>
                            <button
                                title="View Challenge"
                                className="btn btn-sm btn-default btn-circle"
                            >
                                <span className="fa fa-list" />
                            </button>
                            <button
                                title="View Result"
                                className="btn btn-sm btn-default btn-circle"
                                onClick={() => {
                                    history.push(
                                        "/class/assignmentResult?assignmentID=" +
                                            r._id +
                                            "&classID=" +
                                            gClass._id
                                    );
                                }}
                            >
                                <span className="fa fa-bar-chart" />
                            </button>
                        </div>
                    );
                }
            }
        ];

        return (
            <div>
                <Table
                    dataSource={assignments}
                    columns={columns}
                    pagination={true}
                    bordered={true}
                    rowKey="_id"
                />
            </div>
        );
    };
}

export default Assignment;
