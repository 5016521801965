import React, { Component } from "react";
import Form from "../commons/form";
import Joi from "joi-browser";
import validateUtils from "../../utils/validateUtils";
import kidService from "../../services/kidService";

class JoinClassForm extends Form {
    state = {
        data: {
            childID: this.props.childID,
            code: ""
        },
        errors: {}
    };
    schema = {
        code: Joi.string()
            .regex(validateUtils.objIDRegex)
            .error(errors => {
                return validateUtils.JoiErrorMsg(
                    errors,
                    "code",
                    "Code is not valid!"
                );
            })
    };
    doSubmit = async () => {
        // console.log(this.state.data);
        try {
            const { data } = await kidService.joinClass(this.state.data);
            this.props.closeModal();
            this.setState({
                code: ""
            });
            this.props.handleSubmit();
        } catch (ex) {
            console.log(ex);
        }
    };
    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="row">
                    <div className="col">
                        {this.renderInput("code", "Activation Code")}
                    </div>
                </div>
                <div className="row">
                    <div className="col">{this.renderButton("Join")}</div>
                </div>
            </form>
        );
    }
}

export default JoinClassForm;
