import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../../services/authService";

import { AppContext } from "../../App";

const ProtectedRoute = ({ component: Component, render, app, ...rest }) => {
    const curUser = app.state.curUser;
    return (
        <Route
            {...rest}
            render={props => {
                if (!curUser) {
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: {
                                    from: props.location
                                }
                            }}
                        />
                    );
                } else {
                    return Component ? <Component {...props} /> : render(props);
                }
            }}
        />
    );
};

const _ProtectedRoute = props => (
    <AppContext.Consumer>
        {app => <ProtectedRoute {...props} app={app} />}
    </AppContext.Consumer>
);

export default _ProtectedRoute;
