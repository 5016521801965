import http from "./httpService";

const endPoint = "/purchases";

const Utils = class {
    constructor() {}

    getPurchases = async childID => {
        return await http.get(endPoint + "/" + childID);
    };
};

export default new Utils();
