import moment from "moment";
import React, { Component } from "react";
import _ from "lodash";
import Joi from "joi-browser";
import {
    Input,
    Tooltip,
    Icon,
    Cascader,
    Select,
    Row,
    Col,
    Checkbox,
    Button
} from "antd";
import { toast } from "react-toastify";

import Form from "../commons/form";
import CardBlock from "../themeComps/cardBlock";
import userService from "../../services/userService";
import userUtils from "../../utils/userUtils";

class ProfileForm extends Form {
    state = {
        data: {
            _id: "",
            name: "",
            email: "",
            password: "",
            memberships: [],
            picture: null,
            created: null
        },
        errors: {}
    };
    schema = userUtils.userSchema;

    doSubmit = async () => {
        try {
            const { data } = await userService.updateUser(this.state.data);
            data.password = "";
            data.confirmPassword = "";
            this.setState({ data });
            toast.success("Success to update!!!");
        } catch (ex) {}
    };
    resetForm = async () => {
        const { userID } = this.props;
        const { data } = await userService.getUser(userID);
        data.password = "";
        data.confirmPassword = "";
        this.setState({ data });
    };
    async componentDidMount() {
        const { userID } = this.props;
        const { data } = await userService.getUser(userID);
        data.password = "";
        data.confirmPassword = "";
        this.setState({ data });
    }

    render() {
        const { userID } = this.props;
        const { data } = this.state;
        if (!data._id) return "";
        return (
            <div className="row">
                <div className="col">
                    <form onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label>Created</label>
                                    <span className="form-control">
                                        {moment(data.created).format(
                                            "YYYY-MM-DD"
                                        )}
                                    </span>
                                </div>
                            </div>
                            <div className="col-md-12">
                                {this.renderInput("name", "Name")}
                            </div>
                            <div className="col-md-12" />
                            <div className="col-md-12">
                                {this.renderInput(
                                    "email",
                                    "Email",
                                    "email",
                                    null
                                )}
                            </div>
                            <div className="col-md-12">
                                {this.renderInput(
                                    "password",
                                    "Password",
                                    "password",
                                    null,
                                    {
                                        autoComplete: "new-password"
                                    }
                                )}
                            </div>
                        </div>
                        {this.renderButton("Update")}{" "}
                        <span
                            className="btn btn-default"
                            onClick={() => {
                                this.resetForm();
                            }}
                        >
                            Reset
                        </span>
                    </form>
                </div>
            </div>
        );
    }
}

export default ProfileForm;
