import React, { Component } from "react";
import Joi from "joi-browser";
import Input from "./input";
import Select from "./select";
import TextArea from "./textarea";

class Form extends Component {
    state = {
        data: {},
        errors: {}
    };

    validate = () => {
        const options = { abortEarly: false, allowUnknown: true };
        const { error } = Joi.validate(this.state.data, this.schema, options);
        try {
            console.log(error.details);
        } catch (error) {}

        if (!error) return null;

        const errors = {};

        for (let item of error.details) {
            errors[item.path[0]] = item.message;
        }

        return errors;
    };
    validateProperty = ({ name, value }) => {
        const options = { abortEarly: false, allowUnknown: true };
        const obj = { [name]: value };
        const schema = { [name]: this.schema[name] };
        if (schema[name]._refs) {
            schema[name]._refs.forEach(ref => {
                obj[ref] = this.state.data[ref];
            });
        }
        const { error } = Joi.validate(obj, schema, options);
        return error ? error.details[0].message : null;
    };
    handleSubmit = e => {
        e.preventDefault();

        const errors = this.validate();
        this.setState({ errors: errors || {} });

        if (errors) return;

        this.doSubmit();
    };
    handleChange = ({ currentTarget: input }) => {
        const data = { ...this.state.data };
        const errors = { ...this.state.errors };
        data[input.name] = input.value;

        errors[input.name] = this.validateProperty(input);
        this.setState({ data, errors });
    };

    renderButton(label) {
        return (
            <button className="btn btn-primary" disabled={this.validate()}>
                {label}
            </button>
        );
    }

    renderInput(name, label, type = "text", placeholder, props) {
        return (
            <Input
                name={name}
                value={this.state.data[name]}
                label={label}
                placeholder={placeholder}
                onChange={this.handleChange}
                error={this.state.errors[name]}
                type={type}
                {...props}
            />
        );
    }

    rednerTextArea(name, label, props) {
        return (
            <TextArea
                name={name}
                value={this.state.data[name]}
                label={label}
                onChange={this.handleChange}
                error={this.state.errors[name]}
                {...props}
            />
        );
    }

    renderSelect(name, label, options, props) {
        return (
            <Select
                name={name}
                label={label}
                options={options}
                onChange={this.handleChange}
                error={this.state.errors[name]}
                value={this.state.data[name]}
                {...props}
            />
        );
    }
}

export default Form;
