import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import ClientJS from "clientjs";

const client = new ClientJS();

class StoreRedirect extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <React.Fragment>
                <Switch>
                    <Route
                        path="/storeRedirect/k"
                        exact
                        component={() => {
                            return <MyDirect grade="k"></MyDirect>;
                        }}
                    />
                    <Route
                        path="/storeRedirect/preK"
                        exact
                        component={() => {
                            return <MyDirect grade="preK"></MyDirect>;
                        }}
                    />
                    <Route
                        path="/storeRedirect/grade1"
                        exact
                        component={() => {
                            return <MyDirect grade="grade1"></MyDirect>;
                        }}
                    />
                    <Route
                        component={() => {
                            return <MyDirect grade=""></MyDirect>;
                        }}
                    />
                </Switch>
            </React.Fragment>
        );
    }
}

class MyDirect extends Component {
    constructor(props) {
        super(props);
    }
    componentWillMount() {
        this.openStore(this.props.grade);
    }
    getUrl = grade => {
        if (!grade) return;
        grade = grade.toString().toLowerCase();
        const objs = {
            k: {
                ios:
                    "https://apps.apple.com/us/app/dr-abc-kindergarten-curriculum/id1480958829?ls=1",
                android: "https://play.google.com",
                windows: "https://www.microsoft.com/en-us/store/apps/windows"
            },
            prek: {
                ios:
                    "https://apps.apple.com/us/app/dr-abc-pre-kindergarten-eng/id1456335185?ls=1",
                android:
                    "https://play.google.com/store/apps/details?id=com.tulipcim.itunes.drabcelcprek",
                windows: "https://www.microsoft.com/en-us/store/apps/windows"
            },
            grade1: {
                ios:
                    "https://apps.apple.com/us/app/dr-abc-grade-1-english/id1438373192?ls=1",
                android:
                    "https://play.google.com/store/apps/details?id=com.tulipcim.itunes.drabcelc.gk2",
                windows: "https://www.microsoft.com/en-us/store/apps/windows"
            }
        };

        let gradeUrls = objs[grade];
        if (!gradeUrls) return;

        const os = client.getOS();
        console.log(os);
        if (os.name === "iOS") return gradeUrls.ios;
        if (os.name === "Android") return gradeUrls.android;
        return gradeUrls.windows;
    };
    openStore = grade => {
        console.log("grade", grade);
        let appStoreUrl = this.getUrl(grade);
        if (!appStoreUrl) appStoreUrl = "https://www.drabcedu.com";
        console.log(appStoreUrl);
        window.location.href = appStoreUrl;
    };
    render() {
        return "";
    }
}

export default StoreRedirect;
