import React from "react";
import { NavLink } from "react-router-dom";
import { AppContext } from "../../App";

const SchoolNav = ({ app }) => {
    const { curUser, navSchoolYear } = app.state;
    if (!curUser || !(curUser && (curUser.isManager || curUser.isSuper)))
        return "";
    return (
        <li className="parent nav-item">
            <a
                className="nav-link collapsed"
                data-toggle="collapse"
                href="#managerMenu"
                aria-expanded="true"
            >
                <em className="fa fa-file-o">&nbsp;</em> Manager{" "}
                <span
                    data-toggle="collapse"
                    href="#managerMenu"
                    className="icon pull-right collapsed"
                    aria-expanded="true"
                >
                    <i className="fa fa-plus fa-minus" />
                </span>
            </a>
            <ul className="children collapse show" id="managerMenu">
                <li className="nav-item">
                    <NavLink className="nav-link" to="/school/info">
                        School Info
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/school/teachers">
                        Teachers
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink className="nav-link" to="/school/students">
                        Students
                    </NavLink>
                </li>
                {navSchoolYear && navSchoolYear._id && (
                    <li className="nav-item">
                        <NavLink
                            className="nav-link"
                            to={
                                "/school/year/" +
                                navSchoolYear.name +
                                "/" +
                                navSchoolYear._id
                            }
                        >
                            School Year {navSchoolYear.name}
                        </NavLink>
                    </li>
                )}
                {/* {schoolYears.map((sy, index) => {
                    return "";
                    return (
                        <li className="nav-item" key={index}>
                            <NavLink
                                className="nav-link"
                                to={`/school/year/${sy.name}/${sy._id}`}
                            >
                                {sy.name}
                            </NavLink>
                        </li>
                    );
                })} */}
            </ul>
        </li>
    );
};

const _SchoolNav = props => (
    <AppContext.Consumer>
        {app => <SchoolNav {...props} app={app} />}
    </AppContext.Consumer>
);

export default _SchoolNav;
