import $ from "jquery";
import _ from "lodash";
import { Progress } from "antd";
import React, { Component } from "react";
import { Doughnut, Bar, HorizontalBar } from "react-chartjs-2";

import CardBlock from "../themeComps/cardBlock";

const Overall = ({ result, results }) => {
    if (results.length == 0) return "";

    const getTimeData = () => {
        return {
            labels: ["Student", "Avage"],
            datasets: [
                {
                    label: "Seconds",
                    backgroundColor: "rgba(255,99,132,0.2)",
                    borderColor: "rgba(255,99,132,1)",
                    hoverBackgroundColor: "rgba(255,99,132,0.4)",
                    hoverBorderColor: "rgba(255,99,132,1)",
                    borderWidth: 1,
                    data: [result && result.timeSpend, getAvageTime()]
                }
            ]
        };
    };
    const getScoreOverallData = () => {
        return {
            labels: ["<60", "60-69", "70-79", "80-89", "90-99", "100"],
            datasets: [
                {
                    label: "Score",
                    backgroundColor: "rgba(255,99,132,0.2)",
                    borderColor: "rgba(255,99,132,1)",
                    borderWidth: 1,
                    hoverBackgroundColor: "rgba(255,99,132,0.4)",
                    hoverBorderColor: "rgba(255,99,132,1)",
                    data: [
                        results.filter(r => r.score < 60).length,
                        results.filter(r => r.score > 60 && r.score < 70)
                            .length,
                        results.filter(r => r.score > 70 && r.score < 80)
                            .length,
                        results.filter(r => r.score > 80 && r.score < 90)
                            .length,
                        results.filter(r => r.score > 90 && r.score < 100)
                            .length,
                        results.filter(r => r.score === 100).length
                    ]
                }
            ]
        };
    };
    const getAvageScore = () => {
        return _.meanBy(results, "score");
    };
    const getAvageTime = () => {
        return _.meanBy(results, "timeSpend");
    };

    return (
        <React.Fragment>
            <CardBlock title="Time Spent">
                <Bar
                    data={getTimeData}
                    height={3}
                    width={5}
                    options={{
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        beginAtZero: true
                                    }
                                }
                            ]
                        }
                    }}
                />
            </CardBlock>
            <CardBlock title="Score">
                <div className="row">
                    <div className="col-xl-6 col-lg-12 text-center">
                        <div>Student</div>
                        <Progress
                            type="circle"
                            percent={(result && result.score) || 0}
                        />
                    </div>
                    <div
                        style={{ height: "10px", width: "100%" }}
                        className="d-xl-none"
                    />
                    <div className="col-xl-6 col-lg-12 text-center">
                        <div>Avage</div>
                        <Progress type="circle" percent={getAvageScore()} />
                    </div>
                </div>
            </CardBlock>
            <CardBlock title="Score Overall">
                <HorizontalBar
                    data={getScoreOverallData}
                    height={4}
                    width={5}
                />
            </CardBlock>
        </React.Fragment>
    );
};

export default Overall;
