import React from "react";
import Joi from "joi-browser";
import { Link } from "react-router-dom";

import userService from "../services/userService";
import auth from "../services/authService";

import Form from "./commons/form";
import { AppContext } from "../App";

class PageRegister extends Form {
    state = {
        data: {
            email: "",
            password: "",
            name: "",
            memberships: []
        },
        errors: {}
    };
    schema = {
        name: Joi.string()
            .min(3)
            .max(50)
            .label("Name"),
        email: Joi.string()
            .min(5)
            .max(255)
            .email()
            .required()
            .label("Email"),
        password: Joi.string()
            .required()
            .min(8)
            .max(26)
            .label("Password")
    };
    componentDidMount() {
        const { app } = this.props;
        app.setState({
            pageTitle: "Register"
        });
    }
    componentWillUnmount() {
        const { app } = this.props;
        app.setState({
            pageTitle: ""
        });
    }

    doSubmit = async () => {
        console.log("Register Form Submited");
        try {
            const resp = await userService.register(this.state.data);
            auth.loginWithJwt(resp.data);
            const { state } = this.props.location;
            window.location = state ? state.from.pathname : "/";
        } catch (ex) {}
    };
    render() {
        return (
            <div className="container">
                <form onSubmit={this.handleSubmit}>
                    {this.renderInput("name", "Name")}
                    {this.renderInput("email", "Email", "email")}
                    {this.renderInput("password", "Password", "password")}
                    {this.renderButton("Register")}
                </form>
                <hr />
                <div className="jumbotron">
                    <h3 className="mb-4">Already registered?</h3>
                    <p className="lead">
                        <Link
                            className="btn btn-primary btn-lg mt-2"
                            to="/login"
                        >
                            Login
                        </Link>
                    </p>
                </div>
            </div>
        );
    }
}

const _PageRegister = props => (
    <AppContext.Consumer>
        {app => <PageRegister {...props} app={app} />}
    </AppContext.Consumer>
);

export default _PageRegister;
