import moment from "moment";
import React, { Component } from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { Switch, InputNumber, DatePicker } from "antd";

import schoolService from "../../services/schoolService";
import CardBlock from "../themeComps/cardBlock";
import schoolUtils from "../../utils/school";

class SubscriptionForm extends Component {
    constructor(props) {
        super(props);
        let subribeType = "headCount";
        props.subscriptions.some((item, index) => {
            if (index == 0) {
                subribeType = item.type;
                return true;
            }
        });
        const availableGradeSubs = schoolUtils.gradeEnum_available.map(g => {
            const gradeSubObj = { grade: g, appSubs: [] };
            gradeSubObj.appSubs = schoolUtils.appEnum.map(app => {
                const sub = schoolUtils.Subscription();
                sub.grade = g;
                sub.app = app;
                sub.type = subribeType;
                const matchSub = props.subscriptions.find(
                    s => s.grade === sub.grade && s.app === sub.app
                );
                if (matchSub) {
                    matchSub.on = true;
                    return matchSub;
                } else {
                    return sub;
                }
            });
            return gradeSubObj;
        });

        this.state = {
            availableGradeSubs
        };
    }
    componentDidUpdate(prevProps, prevState) {}
    render() {
        const { availableGradeSubs, subribeType } = this.state;
        return (
            <React.Fragment>
                {/* <div className="row m-2">
                    <div className="col-md-3">Subscription Type </div>
                    <div className="col-md-3">
                        <select
                            className="form-control"
                            value={subribeType}
                            onChange={e => {
                                this.setState({
                                    subribeType: e.target.value
                                });
                            }}
                        >
                            <option value="headCount">Head Count</option>
                            <option value="quote">Quote</option>
                        </select>
                    </div>
                </div> */}
                <div className="row">
                    <div className="col">
                        {renderSubscriptionsTbl(availableGradeSubs, this)}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function renderSubscriptionsTbl(subs, comp) {
    return (
        <table className="table table-striped table-responsive">
            <thead>
                <tr>
                    <th>Grade</th>
                    <th>Subscribe</th>
                    <th>App</th>
                    <th>Price</th>
                    <th>Quote</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th>dateCreated</th>
                    <th>dateActived</th>
                    <th>dateExpired</th>
                </tr>
            </thead>
            <tbody>
                {subs.map((gradeSub, gIndex) => {
                    return gradeSub.appSubs.map((sub, sIndex) => {
                        return (
                            <tr key={`${gIndex}:${sIndex}`}>
                                <td>{sub.grade}</td>
                                <td>
                                    <Switch
                                        onChange={() => {
                                            utils.switchSub(sub, comp);
                                        }}
                                        checked={sub.on}
                                    />
                                </td>
                                <td>{sub.app}</td>
                                <td>
                                    <InputNumber
                                        defaultValue={sub.price}
                                        min={0}
                                        className="form-control"
                                        onChange={val => {
                                            sub.price = val;
                                            utils.updateAppSub(sub, comp);
                                        }}
                                    />
                                </td>
                                <td>
                                    <InputNumber
                                        defaultValue={sub.quote}
                                        min={0}
                                        className="form-control"
                                        onChange={val => {
                                            sub.quote = val;
                                            utils.updateAppSub(sub, comp);
                                        }}
                                    />
                                </td>
                                <td>
                                    <select
                                        className="form-control"
                                        style={{ minWidth: 150 }}
                                        value={sub.type}
                                        onChange={e => {
                                            sub.type = e.target.value;
                                            utils.updateAppSub(sub, comp);
                                        }}
                                    >
                                        <option value="headCount">
                                            Head Count
                                        </option>
                                        <option value="quote">Quote</option>
                                    </select>
                                </td>
                                <td>
                                    <select
                                        className="form-control"
                                        style={{ minWidth: 100 }}
                                        value={sub.status}
                                        onChange={e => {
                                            sub.status = e.target.value;
                                            utils.updateAppSub(sub, comp);
                                        }}
                                    >
                                        <option>- Select - </option>
                                        {!sub._id && (
                                            <option value="trial">trial</option>
                                        )}

                                        <option value="active">active</option>
                                        {sub._id && (
                                            <option value="suspended">
                                                suspended
                                            </option>
                                        )}
                                    </select>
                                </td>
                                <td>
                                    {sub.dateCreated &&
                                        moment(sub.dateCreated).format(
                                            "YYYY-MM-DD"
                                        )}
                                </td>
                                <td>
                                    {sub.dateActived &&
                                        moment(sub.dateCreated).format(
                                            "YYYY-MM-DD"
                                        )}
                                </td>
                                <td style={{ minWidth: 160 }}>
                                    {sub.type === "quote" && (
                                        <DatePicker
                                            onChange={val => {
                                                sub.dateExpired = val;
                                                utils.updateAppSub(sub, comp);
                                            }}
                                            defaultValue={
                                                sub.dateExpired
                                                    ? moment(sub.dateExpired)
                                                    : moment().add("days", 180)
                                            }
                                        />
                                    )}
                                </td>
                            </tr>
                        );
                    });
                })}
            </tbody>
        </table>
    );
}

const utils = {
    switchSub: (appSub, comp) => {
        console.log("switchSub");
        appSub.on = !appSub.on;
        utils.updateAppSub(appSub, comp);

        if (appSub.on) {
            comp.props.subscriptions.push(appSub);
        } else {
            const index = comp.props.subscriptions.findIndex(
                s => s.app == appSub.app && s.grade == appSub.grade
            );
            if (index > -1) comp.props.subscriptions.splice(index, 1);
        }
    },
    updateAppSub: (appSub, comp) => {
        const subs = [...comp.state.availableGradeSubs];
        const gradeSubObj = subs.find(item => item.grade === appSub.grade);
        const index = gradeSubObj.appSubs.findIndex(
            item => item.app === appSub.app
        );
        gradeSubObj.appSubs[index] = appSub;
        comp.setState({
            availableGradeSubs: subs
        });
    },
    findAppSubIndex: (appSub, subs) => {
        const gradeSubObj = subs.find(item => item.grade === appSub.grade);
        const index = gradeSubObj.appSubs.findIndex(
            item => item.app === appSub.app
        );
        return index;
    }
};

export default SubscriptionForm;
/*
when updating subscriptons

subscription type

grade price quote checkbox
grade price quote checkbox

*/
