import QueryString from "query-string";
import React from "react";
import Joi from "joi-browser";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";

import Form from "./commons/form";

import { AppContext } from "../App";
import httpService from "../services/httpService";
import auth from "../services/authService";

class ResetPassword extends Form {
    state = {
        data: { password: "" },
        errors: {},
        sent: false
    };
    componentDidMount() {
        const { app } = this.props;
        app.setState({
            pageTitle: "Reset Password"
        });
    }
    componentWillUnmount() {
        const { app } = this.props;
        app.setState({
            pageTitle: ""
        });
    }

    schema = {
        password: Joi.string()
            .required()
            .min(8)
            .max(26)
            .label("Password")
    };

    doSubmit = async () => {
        const { location } = this.props;
        const query = QueryString.parse(location.search);
        const { data: formData } = this.state;
        formData.token = query.token;
        try {
            const { data: jwt } = await httpService.post(
                "/forgetPassword/reset",
                formData
            );
            localStorage.setItem("token", jwt);
            window.location = "/";
        } catch (ex) {}
    };

    render() {
        return (
            <div className="container">
                <div className="jumbotron">
                    <h3 className="mb-4">Forgot your password?</h3>
                    <p className="lead">
                        Don't worry! Reset your password here!
                    </p>
                </div>
                <hr />
                <form onSubmit={this.handleSubmit}>
                    {this.renderInput("password", "Password", "password")}
                    {this.renderButton("Reset Password")}{" "}
                </form>
            </div>
        );
    }
}

const _ResetPassword = props => (
    <AppContext.Consumer>
        {app => <ResetPassword {...props} app={app} />}
    </AppContext.Consumer>
);

export default _ResetPassword;
