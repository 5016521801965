import React, { Component } from "react";
import { Table, Input, Button, Icon } from "antd";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import { AppContext } from "../../App";

import SchoolForm from "./schoolForm";
import CardBlock from "../themeComps/cardBlock";

import schoolService from "../../services/schoolService";
import superService from "../../services/superService";

class Schools extends Component {
    state = {
        schools: [],
        schoolToUpdate: null,
        showForm: false
    };
    columns = [
        {
            title: "Name",
            dataIndex: "name",
            render: (val, school) => (
                <Link
                    to={"/school?schoolID=" + school._id}
                    onClick={() => {
                        superService.setDefaultSchool(school._id);
                    }}
                >
                    {school.name}
                </Link>
            )
        },
        {
            title: "",
            dataIndex: "",
            render: (val, school) => (
                <div>
                    <span
                        className="m-2"
                        style={{ cursor: "pointer", textDecoration: "none" }}
                        onClick={() => {
                            this.handleEditBtnClick(school);
                        }}
                    >
                        <em className="fa fa-pencil" />
                    </span>{" "}
                    <span
                        onClick={() => {
                            this.toDelete(school);
                        }}
                        className="trash"
                        style={{ cursor: "pointer", textDecoration: "none" }}
                    >
                        <em className="fa fa-trash" />
                    </span>
                </div>
            )
        }
    ];

    async componentDidMount() {
        const { app } = this.props;
        app.setState({ pageTitle: "Schools Manager" });

        try {
            const { data: schools } = await schoolService.getSchools();
            // console.log(schools);
            this.setState({ schools });
        } catch (ex) {}
    }

    componentWillUnmount() {
        const { app } = this.props;
        app.setState({ pageTitle: "" });
    }

    handleSchoolsChange = schools => {
        this.setState({ schools });
    };

    handleSchoolChange = school => {
        const schools = [...this.state.schools];
        const index = schools.findIndex(s => s._id === school._id);
        if (index === -1) {
            schools.push(school);
        } else {
            schools[index] = school;
        }

        this.handleSchoolsChange(schools);
    };

    handleEditBtnClick = school => {
        this.toggleForm(false);

        setTimeout(() => {
            this.setState({
                schoolToUpdate: school,
                showForm: true
            });
        }, 100);
    };

    toggleForm = val => {
        if (val === null || val === undefined) val = false;
        this.setState({ showForm: val });
    };

    async toDelete(school) {
        try {
            const { data } = await schoolService.deleteSchool(school._id);
            let schools = [...this.state.schools];
            schools = schools.filter(s => s._id !== data._id);
            this.setState({ schools });
            toast.success("Success to delete a school!");
            this.toggleForm(false);
        } catch (ex) {
            console.log(ex);
        }
    }
    render() {
        const { schools, schoolToUpdate, showForm } = this.state;
        return (
            <div className="">
                {showForm && (
                    <SchoolForm
                        handleSchoolChange={this.handleSchoolChange}
                        schoolToUpdate={schoolToUpdate}
                        toggleForm={this.toggleForm}
                    />
                )}
                {schools.length === 0 && (
                    <div className="jumbotron">
                        <h3 className="mb-4">
                            Opps, No schools here, create one!
                        </h3>
                        <p className="lead">
                            <button
                                className="btn btn-primary m-4 btn-lg"
                                onClick={() => {
                                    this.toggleForm(true);
                                }}
                            >
                                Create a new School
                            </button>
                        </p>
                    </div>
                )}
                {schools.length > 0 && (
                    <div className="row">
                        {!showForm && (
                            <div className="col-md-12">
                                <button
                                    className="btn btn-primary float-right m-4"
                                    onClick={() => {
                                        this.setState({
                                            schoolToUpdate: null
                                        });
                                        this.toggleForm(true);
                                    }}
                                >
                                    Create a new School
                                </button>
                            </div>
                        )}

                        <div className="col">
                            <CardBlock title="School List">
                                <Table
                                    dataSource={schools}
                                    columns={this.columns}
                                    pagination={true}
                                    bordered={true}
                                    rowKey="_id"
                                />
                            </CardBlock>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const _Schools = props => (
    <AppContext.Consumer>
        {app => <Schools {...props} app={app} />}
    </AppContext.Consumer>
);

export default _Schools;
