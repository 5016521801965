import React, { Component } from "react";
import Form from "../commons/formash";
import Joi from "joi-browser";
import materialService from "../../services/materialService";
import { toast } from "react-toastify";
import CardBlock from "./../themeComps/cardBlock";

class MaterialForm extends Form {
    state = {
        data: {
            _id: "",
            grade: "",
            unit: 1,
            lesson: "",
            mTitle: { title: "", audioStart: 0, audioEnd: 0 },
            mPages: [],
            introTitle: { title: "", audioStart: 0, audioEnd: 0 },
            intro: []
        },
        errors: {},
        pages: [{ contents: [{ content: "", audioStart: 0, audioEnd: 0 }] }],
        intro: []
    };
    schema = {
        grade: Joi.string()
            .valid("preSchool", "preK", "kindergarten", "1", "2", "3", "4", "5")
            .required(),
        unit: Joi.number()
            .required()
            .min(1),
        lesson: Joi.string()
            .valid("phonic", "spelling", "grammar", "reading", "writing")
            .required(),
        mTitle: Joi.object()
            .keys({
                title: Joi.string().required(),
                audioStart: Joi.number().min(0),
                audioEnd: Joi.number().min(0)
            })
            .required(),
        mPages: Joi.array(),
        introTitle: Joi.object()
            .keys({
                title: Joi.string().allow(""),
                audioStart: Joi.number().min(0),
                audioEnd: Joi.number().min(0)
            })
            .required(),
        intro: Joi.array()
    };
    gradeOpts = [
        { label: "preSchool", value: "preSchool" },
        { label: "preK", value: "preK" },
        { label: "kindergarten", value: "kindergarten" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" }
    ];
    lessonOpts = [
        { label: "Phonic", value: "phonic" },
        { label: "Spelling", value: "spelling" },
        { label: "Grammar", value: "grammar" },
        { label: "Reading", value: "reading" },
        { label: "Writing", value: "writing" }
    ];
    async componentDidMount() {
        const { material, grade } = this.props;
        if (material) {
            const { data } = await materialService.getMaterial(material._id);
            console.log(data);
            this.setState({
                data,
                pages: data.mPages,
                intro: data.intro
            });
        } else if (grade) {
            const data = { ...this.state.data };
            data.grade = grade;
            this.setState({
                data
            });
        }
    }

    doSubmit = async () => {
        const { handleClose } = this.props;
        const formData = { ...this.state.data };
        formData.mPages = this.state.pages;
        formData.intro = this.state.intro;
        console.log(formData);

        try {
            if (!formData._id) {
                //create
                const { data } = await materialService.createMaterial(formData);
                if (data) toast.success("Success!");
            } else {
                //update
                const { data } = await materialService.updateMaterial(formData);
                if (data) toast.success("Success!");
            }
            handleClose(true);
        } catch (ex) {
            console.log(ex);
        }
    };
    doReset = async () => {
        this.props.handleClose();
    };
    render() {
        const { handleClose, material } = this.props;
        const { pages, intro } = this.state;
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="row">
                    <div className="col">
                        {this.renderSelect("grade", "Grade", this.gradeOpts)}
                    </div>
                    <div className="col">
                        {this.renderInput("unit", "Unit", "number", {
                            min: 1,
                            max: 40
                        })}
                    </div>

                    <div className="col">
                        {this.renderSelect("lesson", "Lesson", this.lessonOpts)}
                    </div>
                </div>
                <div className="row">
                    {/* <div className="col">
                        {this.renderInput("title", "Title")}
                    </div> */}
                    <div className="col-md-8">
                        {this.renderInput("mTitle.title", "Title")}
                    </div>
                    <div className="col-md-2">
                        {this.renderInput(
                            "mTitle.audioStart",
                            "Audio Start",
                            "number",
                            {
                                step: "0.01"
                            }
                        )}
                    </div>
                    <div className="col-md-2">
                        {this.renderInput(
                            "mTitle.audioEnd",
                            "Audio End",
                            "number",
                            {
                                step: "0.01"
                            }
                        )}
                    </div>
                </div>
                <CardBlock title="Intro">
                    <div className="row">
                        <div className="col-md-8">
                            {this.renderInput(
                                "introTitle.title",
                                "Intro Title"
                            )}
                        </div>
                        <div className="col-md-2">
                            {this.renderInput(
                                "introTitle.audioStart",
                                "Audio Start",
                                "number",
                                {
                                    step: "0.01"
                                }
                            )}
                        </div>
                        <div className="col-md-2">
                            {this.renderInput(
                                "introTitle.audioEnd",
                                "Audio End",
                                "number",
                                {
                                    step: "0.01"
                                }
                            )}
                        </div>
                    </div>
                    {this.reanderPageInputs(intro, "intro")}
                </CardBlock>
                <CardBlock title="Contents">
                    {this.reanderPageInputs(pages, "pages")}
                </CardBlock>
                <div className="row">
                    <div className="col ">
                        <div className="pull-right">
                            {this.renderButton()}
                            {material && (
                                <span
                                    className="btn btn-danger m-1"
                                    onClick={this.deleteMaterial}
                                >
                                    Delete
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </form>
        );
    }
    deleteMaterial = async () => {
        const { material, handleClose } = this.props;
        if (!material) return;

        try {
            const deleteMaterial = await materialService.deleteMaterial(
                material._id
            );
            handleClose(true);
        } catch (ex) {
            console.log(ex);
        }
    };
    deletePage = ({ pages, pageIndex, dataName }) => {
        const page = pages[pageIndex];
        if (page.contents.length > 0)
            return window.alert(
                "Delete all Paragraphs before to delete a page!"
            );

        pages.splice(pageIndex, 1);
        const obj = {};
        obj[dataName] = pages;
        this.setState(obj);
    };
    addPages = ({ pages, dataName }) => {
        pages.push({ contents: [{ content: "", audioStart: 0, audioEnd: 0 }] });
        const obj = {};
        obj[dataName] = pages;
        this.setState(obj);
    };
    deleteParagraph = ({ pages, pageIndex, contentIndex, dataName }) => {
        console.log("deleteParagraph");
        const page = pages[pageIndex];
        page.contents.splice(contentIndex, 1);
        const obj = {};
        obj[dataName] = pages;
        this.setState(obj);
    };
    addParagraph = ({ pages, pageIndex, dataName }) => {
        const page = pages[pageIndex];
        page.contents.push({
            content: "",
            audioStart: 0,
            audioEnd: 0
        });
        const obj = {};
        obj[dataName] = pages;
        this.setState(obj);
    };
    reanderPageInputs = (pages, dataName) => {
        return (
            <React.Fragment>
                {pages.map((page, pageIndex) => {
                    const pageNum = pageIndex + 1;

                    return (
                        <div
                            className="form-group"
                            key={pageIndex}
                            style={{ marginBottom: "15px" }}
                        >
                            <label htmlFor="">
                                Page {pageNum}{" "}
                                <em
                                    className="fa fa-trash-o"
                                    style={{
                                        cursor: "pointer",
                                        marginLeft: "10px",
                                        fontSize: "14pt"
                                    }}
                                    onClick={() => {
                                        this.deletePage({
                                            pages,
                                            pageIndex,
                                            dataName
                                        });
                                    }}
                                />
                            </label>
                            {page.contents.map((content, contentIndex) => {
                                const contentNum = contentIndex + 1;
                                return (
                                    <div
                                        className="row"
                                        key={contentIndex}
                                        style={{ marginBottom: "15px" }}
                                    >
                                        <div className="col-md-1" />
                                        <div className="col-md-7">
                                            <div className="form-group">
                                                <label
                                                    className=""
                                                    style={{
                                                        textAlign: "right"
                                                    }}
                                                >
                                                    Paragraph {contentNum}{" "}
                                                    <em
                                                        className="fa fa-trash-o"
                                                        style={{
                                                            cursor: "pointer",
                                                            fontSize: "14pt"
                                                        }}
                                                        onClick={() => {
                                                            this.deleteParagraph(
                                                                {
                                                                    pages,
                                                                    pageIndex,
                                                                    contentIndex,
                                                                    dataName
                                                                }
                                                            );
                                                        }}
                                                    />
                                                </label>
                                                <textarea
                                                    className="form-control"
                                                    value={content.content}
                                                    onChange={e => {
                                                        const pages = [
                                                            ...this.state[
                                                                dataName
                                                            ]
                                                        ];

                                                        pages[
                                                            pageIndex
                                                        ].contents[
                                                            contentIndex
                                                        ].content =
                                                            e.target.value;

                                                        const obj = {};
                                                        obj[dataName] = pages;
                                                        this.setState(obj);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label>Audio Start</label>
                                                <input
                                                    type="number"
                                                    step="0.01"
                                                    className="form-control"
                                                    value={content.audioStart}
                                                    min={0}
                                                    onChange={e => {
                                                        const pages = [
                                                            ...this.state[
                                                                dataName
                                                            ]
                                                        ];

                                                        pages[
                                                            pageIndex
                                                        ].contents[
                                                            contentIndex
                                                        ].audioStart =
                                                            e.target.value;

                                                        const obj = {};
                                                        obj[dataName] = pages;
                                                        this.setState(obj);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label>Audio End</label>
                                                <input
                                                    type="number"
                                                    step="0.01"
                                                    className="form-control"
                                                    value={content.audioEnd}
                                                    min={0}
                                                    onChange={e => {
                                                        const pages = [
                                                            ...this.state[
                                                                dataName
                                                            ]
                                                        ];

                                                        pages[
                                                            pageIndex
                                                        ].contents[
                                                            contentIndex
                                                        ].audioEnd =
                                                            e.target.value;

                                                        const obj = {};
                                                        obj[dataName] = pages;
                                                        this.setState(obj);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                            <div className="row">
                                <div className="col-md-11 offset-md-1">
                                    <span
                                        className="btn btn-primary btn-sm"
                                        style={{ marginTop: "15px" }}
                                        onClick={() => {
                                            this.addParagraph({
                                                pages,
                                                pageIndex,
                                                dataName
                                            });
                                        }}
                                    >
                                        New Paragraph
                                    </span>
                                </div>
                            </div>
                        </div>
                    );
                })}
                <span
                    className="btn btn-primary btn-sm"
                    style={{ marginTop: "15px" }}
                    onClick={() => {
                        this.addPages({ pages, dataName });
                    }}
                >
                    New Page
                </span>
            </React.Fragment>
        );
    };
}

export default MaterialForm;
