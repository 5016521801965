import http from "./httpService";
import auth from "./authService";
const apiEndpoint = "/superActions";
http.setJwt(auth.getJwt());

async function setDefaultSchool(schoolID) {
    return await http.post(apiEndpoint + "/setDefaultSchool", {
        school: schoolID
    });
}

export default {
    setDefaultSchool
};
