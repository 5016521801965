import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import queryStr from "query-string";

import { AppContext } from "../../App";
import schoolService from "../../services/schoolService";
import SchoolRoute from "../navComps/schoolRoute";
import SchoolYears from "./schoolYears";
import SchoolInfo from "./info";
import SchoolYearForm from "./schoolYearForm";
import SchoolYear from "./schoolYear";
import Teachers from "./teachers";
import CardBlock from "../themeComps/cardBlock";
import Managers from "./managers";
import Students from "./students";

export const SchoolContext = React.createContext();

function getSchoolContext(comp) {
    return comp;
}

class School extends Component {
    constructor(props) {
        super(props);
        const queryObj = queryStr.parse(props.location.search);
        // console.log("school constructor", queryObj.schoolID);
        if (queryObj.schoolID) {
            this.props.app.setState({
                schoolID: queryObj.schoolID
            });
        }
        // console.log(queryObj.schoolID);
        this.state = {
            curSchoolID: queryObj.schoolID || this.props.app.state.schoolID,
            curSchool: null,
            schoolYears: []
        };
    }

    async setUpState() {
        const curSchoolID = this.state.curSchoolID;
        if (!curSchoolID) return;
        const { app } = this.props;
        const { data: curSchool } = await schoolService.getSchool(curSchoolID);
        const { data: schoolYears } = await schoolService.getYears();
        let navSchoolYear = {};
        try {
            navSchoolYear = schoolYears[0];
        } catch (ex) {}
        this.setState({
            curSchool,
            schoolYears
        });
        app.setState({
            pageTitle: curSchool.name,
            navSchoolYear
        });
    }
    async componentDidMount() {
        await this.setUpState();
    }
    async componentDidUpdate(prevProps, prevState) {}

    componentWillUnmount() {
        const { app } = this.props;
        app.setState({
            pageTitle: ""
        });
    }

    handleYearsChange = schoolYears => {
        const { app } = this.props;
        schoolYears.sort((a, b) => a.order - b.order);
        this.setState({
            schoolYears
        });

        try {
            const navSchoolYear = schoolYears[0];
            app.setState(navSchoolYear);
        } catch (ex) {}
    };
    handleYearChange = sy => {
        sy.grades.sort((a, b) => a.order - b.order);
        const schoolYears = [...this.state.schoolYears];
        const index = schoolYears.findIndex(y => y._id === sy._id);

        if (index === -1) {
            schoolYears.push(sy);
        } else {
            schoolYears[index] = sy;
        }
        this.handleYearsChange(schoolYears);
    };

    render() {
        const { history } = this.props;
        const { curSchoolID, curSchool, schoolYears } = this.state;
        if (!curSchool) {
            return "";
            return (
                <div className="jumbotron">
                    <h3 className="mb-4">Opps, Please Select a school!</h3>
                </div>
            );
        }
        return (
            <SchoolContext.Provider value={getSchoolContext(this)}>
                <section className="row">
                    <div className="col">
                        <Switch>
                            <SchoolRoute
                                path="/school/createSchoolYear"
                                render={props => {
                                    return (
                                        <SchoolYearForm
                                            {...props}
                                            curSchoolID={curSchoolID}
                                            schoolYears={schoolYears}
                                            handleYearChange={
                                                this.handleYearChange
                                            }
                                        />
                                    );
                                }}
                            />
                            <SchoolRoute
                                path="/school/teachers"
                                component={Teachers}
                                curSchool={curSchool}
                            />
                            <SchoolRoute
                                path="/school/students"
                                component={Students}
                                curSchool={curSchool}
                            />
                            <SchoolRoute
                                path="/school/year/:name/:id"
                                render={props => {
                                    return <SchoolYear {...props} />;
                                }}
                            />
                        </Switch>
                    </div>
                </section>
                <Switch>
                    <Route path="/school/year/:name/:id" />
                    <Route path="/school/teachers" />
                    <Route path="/school/students" />
                    <Route>
                        <section className="row">
                            <div className="col-sm-12 col-md-6 ">
                                {/* <SchoolInfo /> */}
                                <Managers curSchool={curSchool} />
                            </div>
                            <div className="col-sm-12 col-md-6 ">
                                <SchoolYears
                                    schoolYears={schoolYears}
                                    history={history}
                                    handleYearsChange={this.handleYearsChange}
                                />
                            </div>
                        </section>
                    </Route>
                </Switch>
            </SchoolContext.Provider>
        );
    }
}

const _School = props => (
    <AppContext.Consumer>
        {app => <School {...props} app={app} />}
    </AppContext.Consumer>
);

export default _School;
