import Joi from "joi-browser";
import React, { Component } from "react";
import CardBlock from "../themeComps/cardBlock";
import Form from "../commons/form";

import QueryString from "query-string";

import appUtils from "../../utils/appUtils";
import challengeService from "../../services/challengeService";
import { toast } from "react-toastify";
import { async } from "q";

class MainForm extends Form {
    constructor(props) {
        super(props);

        this.state = {
            data: {
                __v: "",
                owner: "",
                grade: "",
                unit: "",
                lesson: "",
                brief: "",
                challengeID: ""
            },
            errors: {}
        };
    }

    schema = {
        owner: Joi.string().required(),
        grade: Joi.any(),
        unit: Joi.any(),
        lesson: Joi.any(),
        brief: Joi.string().allow("", null),
        challengeID: Joi.string().allow("", null)
    };

    async componentDidMount() {
        const { challengeID } = this.props;

        if (challengeID) await this.loadEditData(challengeID);
    }

    loadEditData = async challengeID => {
        const { history, curUser } = this.props;
        try {
            const { data } = await challengeService.getChallenge(challengeID);

            if (data.owner.owner === "system" && !curUser.isSuper) {
                history.goBack();
            }

            const formData = { ...this.state.data };

            const keys = Object.keys(formData);

            keys.forEach(k => {
                formData[k] = data[k];
            });

            formData.owner = data.owner.owner;

            this.setState({ data: formData });
            this.props.updatePickedQuestions(data.questions);
        } catch (ex) {
            console.log(ex);
        }
    };

    getOwnerOpts = curUser => {
        const opts = [{ value: "school", label: "My School" }];
        if (curUser.isSuper) opts.unshift({ value: "system", label: "system" });
        return opts;
    };
    doSubmit = async () => {
        const { history, challengeID } = this.props;
        const formData = { ...this.state.data };

        const keys = Object.keys(formData);

        formData.questions = this.props.pickedQuestions.map(q => q._id);

        try {
            if (!challengeID) {
                const { data } = await challengeService.createChallenge(
                    formData
                );
                toast.success("Success to create a challenge!");
            } else {
                formData._id = challengeID;
                const { data } = await challengeService.updateChallenge(
                    formData
                );
                toast.success("Update Successfully!");
            }
            history.goBack();
        } catch (ex) {
            console.log(ex);
        }
    };
    render() {
        const { curUser, toggleQuestion, pickedQuestions } = this.props;

        return (
            <CardBlock title="Challenge">
                <form onSubmit={this.handleSubmit}>
                    <div className="row">
                        <div className="col-md-3 col-sm-6">
                            {this.renderSelect(
                                "owner",
                                "Owner",
                                this.getOwnerOpts(curUser)
                            )}
                        </div>
                        <div className="col-md-3 col-sm-6">
                            {this.renderSelect(
                                "grade",
                                "Grade",
                                appUtils.gradeEnum.map(g => ({
                                    value: g,
                                    label: g
                                }))
                            )}
                        </div>
                        <div className="col-md-3 col-sm-6">
                            {this.renderSelect(
                                "unit",
                                "Unit",
                                appUtils
                                    .getUnitEnum()
                                    .map(u => ({ value: u, label: u }))
                            )}
                        </div>
                        <div className="col-md-3 col-sm-6">
                            {this.renderSelect(
                                "lesson",
                                "Lesson",
                                appUtils.lessonEnum.map(L => ({
                                    value: L,
                                    label: L
                                }))
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            {this.renderInput("brief", "Description")}
                        </div>
                        <div className="col-md-6">
                            {curUser.isSuper &&
                                this.renderInput("challengeID", "challengeID")}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {this.renderButton("Submit")}{" "}
                            <span
                                className="btn btn-default"
                                onClick={() => {
                                    this.props.history.goBack();
                                    // window.location.reload();
                                }}
                            >
                                Cancel
                            </span>
                        </div>
                    </div>
                </form>
            </CardBlock>
        );
    }
}
export default MainForm;
