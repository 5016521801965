import React from "react";
import { Link } from "react-router-dom";

const CardBlockDropDown = ({ opts }) => {
    if (!opts) return "";

    return (
        <div className="dropdown card-title-btn-container">
            <button
                className="btn btn-sm btn-subtle dropdown-toggle"
                type="button"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
                <em className="fa fa-cog" />
            </button>
            <div
                className="dropdown-menu dropdown-menu-right"
                aria-labelledby="dropdownMenuButton"
            >
                {opts.map((o, index) => {
                    if (!o.url) {
                        return (
                            <a
                                key={index}
                                className="dropdown-item"
                                href="/preventDefault"
                                onClick={e => {
                                    e.preventDefault();
                                    o.handler();
                                }}
                            >
                                <em className={"fa mr- " + o.iconCls} />{" "}
                                {o.label}
                            </a>
                        );
                    }

                    return (
                        <Link to={o.url} key={index} className="dropdown-item">
                            <em className={"fa mr- " + o.iconCls} /> {o.label}
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default CardBlockDropDown;

// opts example;
// opts = [
//     {
//         label: "Add a kid",
//         iconCls: "fa-plus",
//         handler: () => {
//             console.log("more info");
//         }
//     },
//     {
//         label: "go back home",
//         iconCls: "fa-plus",
//         url: "/"
//     }
// ];
