import React, { Component } from "react";
import { Table, Input, Button, Icon } from "antd";
import { AppContext } from "../../App";
import challengeService from "../../services/challengeService";
import { Link } from "react-router-dom";
import ChallengeForm from "./challengeForm";

class SystemChallengePage extends Component {
    state = {
        challenges: []
    };
    async componentDidMount() {
        const { app } = this.props;
        const { curUser } = app.state;

        await this.getSetChallenges();

        app.setState({
            pageTitle: () => {
                return (
                    <React.Fragment>
                        System Challenges{" "}
                        {curUser.isSuper && (
                            <Link
                                className="btn btn-primary"
                                to="/challenge/form"
                            >
                                Create New
                            </Link>
                        )}
                    </React.Fragment>
                );
            }
        });
    }

    async componentWillUnmount() {
        const { app } = this.props;
        app.setState({
            pageTitle: ""
        });
    }

    getSetChallenges = async () => {
        const filterObj = {
            "owner.owner": "system"
        };
        try {
            const { data } = await challengeService.getChallenges(filterObj);
            // console.log(data);
            this.setState({
                challenges: data
            });
        } catch (ex) {
            console.log(ex);
        }
    };

    columns = [
        {
            title: "Grade",
            dataIndex: "grade"
        },
        {
            title: "Unit",
            dataIndex: "unit"
        },
        {
            title: "Lesson",
            dataIndex: "lesson"
        },
        {
            title: "Description",
            dataIndex: "brief"
        },
        {
            title: "challengeID",
            dataIndex: "challengeID"
        },
        {
            title: "dateCreated",
            dataIndex: "dateCreated"
        },
        {
            title: "",
            render: (v, r) => {
                const { app } = this.props;
                const { curUser } = app.state;
                return (
                    <div>
                        <Link
                            to={"/challenge?challengeID=" + r._id}
                            title="View"
                        >
                            <em className="fa fa-television" />
                        </Link>
                        {curUser.isSuper && (
                            <React.Fragment>
                                <Link
                                    to={`/challenge/form?challengeID=${r._id}`}
                                >
                                    <em
                                        className="fa fa-pencil"
                                        style={{ margin: "0 10px" }}
                                    />
                                </Link>
                                <a
                                    href="javascript:void(0)"
                                    onClick={async () => {
                                        const toDel = window.confirm(
                                            "Are u sure want to delete this?"
                                        );
                                        if (!toDel) return;
                                        try {
                                            // console.log(v);
                                            const {
                                                data
                                            } = await challengeService.deleteChallenge(
                                                v._id
                                            );
                                            await this.getSetChallenges();
                                        } catch (ex) {}
                                    }}
                                >
                                    <em className="fa fa-trash" />
                                </a>
                            </React.Fragment>
                        )}
                    </div>
                );
            }
        }
    ];

    render() {
        const { challenges } = this.state;
        return (
            <div>
                <Table
                    dataSource={challenges}
                    columns={this.columns}
                    pagination={true}
                    bordered={true}
                    rowKey="_id"
                />
            </div>
        );
    }
}

const _SystemChallengePage = props => (
    <AppContext.Consumer>
        {app => <SystemChallengePage app={app} {...props} />}
    </AppContext.Consumer>
);

export default _SystemChallengePage;
