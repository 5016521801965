import React, { Component } from "react";
import DiaryCal from "./diaryCal";
import ClassList from "./classes";
import Reports from "./reports";
import Materials from "./materials";
class Child extends Component {
    state = {};
    render() {
        const { childID } = this.props;
        if (!childID) return "";

        return (
            <div className="row">
                <div className="col-md-6">
                    <DiaryCal childID={childID} />
                    <Reports childID={childID} />
                </div>
                <div className="col-md-6">
                    <ClassList childID={childID} />
                    <Materials childID={childID} />
                </div>
            </div>
        );
    }
}

export default Child;
