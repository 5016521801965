import React, { Component } from "react";
import Joi from "joi-browser";
import Form from "../commons/form";
import schoolService from "../../services/schoolService";

import { SchoolContext } from "./school";
import { toast } from "react-toastify";

class ManagerForm extends Form {
    state = {
        data: { email: "" },
        errors: {}
    };
    schema = {
        email: Joi.string()
            .email()
            .required()
    };
    doSubmit = async () => {
        const email = this.state.data.email;
        const curSchool = { ...this.props.curSchool };
        try {
            const { data } = await schoolService.assignManager(
                email,
                this.props.curSchool._id
            );
            if (!curSchool.managers) curSchool.managers = [];
            curSchool.managers.push(data);
            this.props.toggleForm(false);
            toast.success("Success!!!");
        } catch (ex) {
            console.log(ex);
        }
    };
    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                {this.renderInput("email", "Email", "email")}
                {this.renderButton("Add")}{" "}
                <span
                    className="btn btn-default"
                    onClick={() => {
                        this.props.toggleForm(false);
                    }}
                >
                    Close
                </span>
            </form>
        );
    }
}

const _ManagerForm = props => (
    <SchoolContext.Consumer>
        {schoolCmp => <ManagerForm {...props} schoolCmp={schoolCmp} />}
    </SchoolContext.Consumer>
);

export default _ManagerForm;
