import React from "react";
import CardBlockDropDown from "./cardBlockDropDown";

const CardBlock = ({ title, subTitle, children, dropDownOpts }) => {
    return (
        <div className="card mb-4">
            <div className="card-block">
                {title && (
                    <h3
                        className="card-title"
                        style={{ textTransform: "uppercase" }}
                    >
                        {title}
                    </h3>
                )}
                <CardBlockDropDown opts={dropDownOpts} />
                {subTitle && (
                    <h6
                        className="card-subtitle mb-2 text-muted"
                        style={{ textTransform: "capitalize" }}
                    >
                        {subTitle}
                    </h6>
                )}
                {children}
            </div>
        </div>
    );
};

export default CardBlock;

// opts example;
// opts = [
//     {
//         label: "Add a kid",
//         iconCls: "fa-plus",
//         handler: () => {
//             console.log("more info");
//         }
//     },
//     {
//         label: "go back home",
//         iconCls: "fa-plus",
//         url: "/"
//     }
// ];
