import React, { Component } from "react";
import CardBlock from "../themeComps/cardBlock";

const QuestionTbl = ({ challenge, result, results }) => {
    if (!challenge) return "";
    const { questions } = challenge;

    const renderQuestion = (q, index) => {
        const qResult = result && getQuestionResult(q);
        return (
            <CardBlock key={q._id}>
                <h2 className="lead border-0">
                    {index + 1}. {q.question}
                </h2>
                <div className="body">
                    <div>
                        <span className="mr-2">
                            Time Spent: {qResult && qResult.timeSpend}s
                        </span>

                        {result && (
                            <span className="mr-2">
                                Correct:{" "}
                                {qResult.correct ? (
                                    <span className="text-success">Yes</span>
                                ) : (
                                    <span className="text-danger">No</span>
                                )}
                            </span>
                        )}
                        {results.length > 0 && (
                            <span className="mr-2">
                                Overall Correct %: {getCorrectAvage(q)}
                            </span>
                        )}
                    </div>
                    <table className="table ">
                        <thead>
                            <tr>
                                <th />
                                <th>Correct</th>
                                {result && <th>Picked</th>}
                            </tr>
                        </thead>
                        <tbody>
                            {q.options.map((opt, index) => {
                                return renderOpt(opt, index, q, qResult);
                            })}
                        </tbody>
                    </table>
                </div>
            </CardBlock>
        );
    };

    const renderOpt = (opt, index, question, qResult) => {
        const isAnswer = question.answers.includes(opt);

        const isUserResp = result && qResult.answers.includes(opt);

        return (
            <tr key={index}>
                <td>{opt}</td>
                <td className="text-center " style={{ width: "30px" }}>
                    {isAnswer && (
                        <span className="fa fa-check-square text-success" />
                    )}
                </td>
                {result && (
                    <td className=" text-center" style={{ width: "30px" }}>
                        {isUserResp && isAnswer && (
                            <span className="fa fa-check-square text-success" />
                        )}
                        {isUserResp && !isAnswer && (
                            <span className="fa fa-times text-danger" />
                        )}
                    </td>
                )}
            </tr>
        );
    };

    const getQuestionResult = question => {
        return result.detail.find(d => {
            return d.question === question._id;
        });
    };

    const getCorrectAvage = question => {
        const qResps = [];
        results.forEach(r => {
            r.detail.forEach(d => {
                if (d.question === question._id) qResps.push(d);
            });
        });

        const correctCount = qResps.filter(r => r.correct).length;

        return ((correctCount / qResps.length) * 100).toFixed(2);
    };

    return (
        <CardBlock title="Questions">
            {questions.map((q, index) => renderQuestion(q, index))}
        </CardBlock>
    );
};

export default QuestionTbl;
