import React, { Component } from "react";
import { Route, Switch, Link } from "react-router-dom";
import queryString from "query-string";
import moment from "moment";

import PageClass from "./pageClass";
import AssignmentResult from "./assignmentResult";
import { AppContext } from "../../App";

class ClassComp extends Component {
    state = {};
    componentDidMount() {}
    render() {
        const { app } = this.props;
        return (
            <Switch>
                <Route path="/class" exact component={PageClass} />
                <Route
                    path="/class/assignmentResult"
                    component={AssignmentResult}
                />
            </Switch>
        );
    }
}

const ClassComp_ = props => {
    return (
        <AppContext.Consumer>
            {app => {
                return <ClassComp {...props} app={app} />;
            }}
        </AppContext.Consumer>
    );
};

export default ClassComp_;
