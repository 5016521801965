import React, { Component } from "react";
import kidService from "../../services/kidService";
import userService from "../../services/userService";
import CardBlock from "./../themeComps/cardBlock";
import { Table, Modal } from "antd";
import JoinClassForm from "./joinClassForm";

class ClassList extends Component {
    state = {
        childInfo: null,
        classList: [],
        formVisible: false
    };
    columns = [
        {
            title: "Year",
            dataIndex: "gClass",
            key: "Year",
            render: (v, r) => {
                return v.schoolYear.name;
            }
        },
        {
            title: "Grade",
            dataIndex: "gClass",
            key: "Grade",
            render: (v, r) => {
                return v.grade.name;
            }
        },
        {
            title: "Class",
            dataIndex: "gClass",
            key: "Class",
            render: (v, r) => {
                return v.name;
            }
        }
    ];
    dropDownOpts = [
        {
            label: "join a class",
            iconCls: "fa-plus",
            handler: () => {
                this.setState({
                    formVisible: true
                });
            }
        }
    ];
    closeModal = () => {
        this.setState({
            formVisible: false
        });
    };
    getChildInfo = async () => {
        const { childID } = this.props;
        try {
            const { data: childInfo } = await kidService.getKid(childID);
            console.log(childInfo);
            const classList = childInfo.memberships.filter(
                m => m.roleName === "student"
            );
            this.setState({
                childInfo,
                classList
            });
        } catch (ex) {
            console.log(ex);
        }
    };
    async componentDidMount() {
        await this.getChildInfo();
    }

    render() {
        const { childID } = this.props;
        const { classList, formVisible } = this.state;
        return (
            <div>
                <CardBlock
                    title="Classes Joined"
                    dropDownOpts={this.dropDownOpts}
                >
                    {classList.length > 0 && (
                        <Table
                            dataSource={classList}
                            columns={this.columns}
                            pagination={false}
                            bordered={true}
                            rowKey="_id"
                        />
                    )}
                </CardBlock>
                <Modal
                    title="Join a Class"
                    visible={formVisible}
                    footer={null}
                    onCancel={() => {
                        this.closeModal();
                    }}
                >
                    <JoinClassForm
                        childID={childID}
                        closeModal={this.closeModal}
                        handleSubmit={this.getChildInfo}
                    />
                </Modal>
            </div>
        );
    }
}

export default ClassList;
