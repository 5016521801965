import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "../../services/authService";

import { AppContext } from "../../App";

const SuperRoute = ({ component: Component, render, app, ...rest }) => {
    // const curUser = auth.getCurrentUser();
    const curUser = app.state.curUser;
    return (
        <Route
            {...rest}
            render={props => {
                if (!curUser) {
                    return (
                        <Redirect
                            to={{
                                pathname: "/login",
                                state: {
                                    from: props.location
                                }
                            }}
                        />
                    );
                } else if (curUser && curUser.isSuper) {
                    return Component ? <Component {...props} /> : render(props);
                } else {
                    return (
                        <Redirect
                            to={{
                                pathname: "/PageNotFound",
                                state: {
                                    from: props.location
                                }
                            }}
                        />
                    );
                }
            }}
        />
    );
};

const _SuperRoute = props => (
    <AppContext.Consumer>
        {app => <SuperRoute {...props} app={app} />}
    </AppContext.Consumer>
);

export default _SuperRoute;
