import React from "react";
import { Redirect } from "react-router-dom";
import Joi from "joi-browser";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import Form from "./commons/form";
import auth from "../services/authService";

import { AppContext } from "../App";
import httpService from "../services/httpService";

class ForgetPassword extends Form {
    state = {
        data: { email: "" },
        errors: {},
        sent: false
    };
    componentDidMount() {
        const { app } = this.props;
        app.setState({
            pageTitle: "Forget Password"
        });
    }
    componentWillUnmount() {
        const { app } = this.props;
        app.setState({
            pageTitle: ""
        });
    }

    schema = {
        email: Joi.string()
            .email({ minDomainAtoms: 2 })
            .required()
            .label("Email")
    };

    doSubmit = async () => {
        try {
            const { data: formData } = this.state;
            const { data } = await httpService.post(
                "/forgetPassword",
                formData
            );
            this.setState({ sent: true });
        } catch (ex) {}
    };

    render() {
        return (
            <div className="container">
                <div className="jumbotron">
                    <h3 className="mb-4">Forgot your password?</h3>
                    <p className="lead">Don't worry! We are here to help!</p>
                </div>
                <hr />
                <form onSubmit={this.handleSubmit}>
                    {this.renderInput("email", "Email")}
                    {this.renderButton("Sent Reset Link")}{" "}
                    {this.state.sent && (
                        <div className="m-4">Link Sent! Check your email.</div>
                    )}
                </form>
            </div>
        );
    }
}

const _ForgetPassword = props => (
    <AppContext.Consumer>
        {app => <ForgetPassword {...props} app={app} />}
    </AppContext.Consumer>
);

export default _ForgetPassword;
