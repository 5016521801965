import React, { Component } from "react";
import { Switch, Route, Link } from "react-router-dom";
import { Table } from "antd";

import { AppContext } from "../../App";

import schoolService from "../../services/schoolService";
import CardBlock from "../themeComps/cardBlock";

class Teacher extends Component {
    state = {
        teacherID: null,
        teacherInfo: null
    };
    columns = [
        { title: "SchoolYear", dataIndex: "schoolYear.name" },
        { title: "Grade", dataIndex: "grade.name" },
        { title: "Class", dataIndex: "name" },
        {
            title: "",
            dataIndex: "",
            render: (val, gClass) => {
                return <Link to={`/class?classID=${gClass._id}`}>View</Link>;
            }
        }
    ];
    async componentDidMount() {
        const { app, match, history } = this.props;
        const { curUser } = app.state;
        let teacherID = curUser.isTeacher && curUser._id;
        if (
            match.params.teacherID &&
            match.params.teacherID.toLowerCase() !== "info"
        )
            teacherID = match.params.teacherID;

        let teacherInfo = null;
        try {
            if (teacherID) {
                const { data } = await schoolService.getTeacher(teacherID);
                teacherInfo = data;
                // console.log(data);
            }
        } catch (ex) {
            console.log(ex);
        }

        this.setState({
            teacherID,
            teacherInfo
        });

        app.setState({
            pageTitle: "Teacher Info"
        });
    }

    render() {
        const { teacherInfo } = this.state;
        return (
            <Switch>
                <Route>
                    <section className="row">
                        <div className="col-md-12">
                            <CardBlock title="Classes">
                                <Table
                                    dataSource={
                                        teacherInfo && teacherInfo.gClasses
                                    }
                                    rowKey="_id"
                                    columns={this.columns}
                                    pagination={false}
                                    bordered={true}
                                />
                            </CardBlock>
                        </div>
                    </section>
                </Route>
            </Switch>
        );
    }
}

const _Teacher = props => (
    <AppContext.Consumer>
        {app => <Teacher {...props} app={app} />}
    </AppContext.Consumer>
);

export default _Teacher;
