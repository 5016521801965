import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import CardBlock from "./../themeComps/cardBlock";
import Table from "../commons/table";
import schoolService from "../../services/schoolService";

const SchoolYears = ({ schoolYears, history, handleYearsChange }) => {
    const dropDownOpts = [
        {
            label: "Create a year",
            iconCls: "fa-plus",
            url: "/school/createSchoolYear"
        }
    ];

    const columns = [
        {
            label: "Name",
            path: "name",
            content: sy => (
                <Link to={`/school/year/${sy.name}/${sy._id}`}>{sy.name}</Link>
            )
        },
        {
            label: "",
            path: "",
            content: sy => (
                <div>
                    <Link
                        to={`/school/createSchoolYear?syID=${sy._id}`}
                        className="m-2"
                    >
                        <em className="fa fa-pencil" />
                    </Link>
                    {"  "}
                    <span
                        onClick={() => {
                            toDelete(sy);
                        }}
                        className="trash"
                        style={{ cursor: "pointer", textDecoration: "none" }}
                    >
                        <em className="fa fa-trash" />
                    </span>
                </div>
            )
        }
    ];

    const toDelete = async sy => {
        try {
            const { data } = await schoolService.deleteYear(sy._id, sy.school);
            // console.log(data);
            if (data._id) {
                schoolYears = schoolYears.filter(sy => sy._id !== data._id);
                handleYearsChange(schoolYears);
            }
            history.push("/school");
            toast.success("Deleted Successfully!");
        } catch (ex) {
            console.log(ex);
        }
    };

    return (
        <CardBlock title="School Years" dropDownOpts={dropDownOpts}>
            {schoolYears.length === 0 && (
                <p className="lead">
                    Opps, nothing here!
                    <br />
                    <Link
                        className="btn btn-primary mt-2"
                        to="/school/createSchoolYear"
                        role="button"
                    >
                        Create
                    </Link>
                </p>
            )}
            {schoolYears.length > 0 && (
                <Table columns={columns} data={schoolYears} />
            )}
        </CardBlock>
    );
};

export default SchoolYears;
