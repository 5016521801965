import React, { Component } from "react";
import Form from "../commons/form";
import appUtils from "../../utils/appUtils";
import Joi from "joi-browser";
import CardBlock from "../themeComps/cardBlock";
import challengeService from "../../services/challengeService";

class QuestionPageFilter extends Form {
    constructor(props) {
        super(props);
        this.state = {
            data: { ...challengeService.getQuestionFilter() },
            errors: {}
        };
    }

    schema = {
        owner: Joi.string().required(),
        grade: Joi.string().required(),
        unit: Joi.number()
            .required()
            .min(1),
        lesson: Joi.any()
    };
    doSubmit = () => {
        const { getSetQuestions } = this.props;
        const formData = { ...this.state.data };
        challengeService.saveQuestionFilter(formData);
        getSetQuestions(formData);
    };
    render() {
        return (
            <CardBlock title="Question Filter">
                <form
                    className="row"
                    style={{ marginBottom: "20px" }}
                    onSubmit={this.handleSubmit}
                >
                    <div className="col-lg-6">
                        {this.renderSelect("owner", "Owner", [
                            { value: "system", label: "system" },
                            { value: "school", label: "My School" }
                        ])}
                    </div>
                    <div className="col-lg-6">
                        {this.renderSelect(
                            "grade",
                            "Grade",
                            appUtils.gradeEnum.map(g => ({
                                value: g,
                                label: g
                            }))
                        )}
                    </div>
                    <div className="col-lg-6">
                        {this.renderSelect(
                            "unit",
                            "Unit",
                            appUtils
                                .getUnitEnum()
                                .map(u => ({ value: u, label: u }))
                        )}
                    </div>
                    <div className="col-lg-6">
                        {this.renderSelect(
                            "lesson",
                            "Lesson",
                            appUtils.lessonEnum.map(L => ({
                                value: L,
                                label: L
                            }))
                        )}
                    </div>
                    <div className="col-lg-6">
                        <label className="form-label"> </label>
                        <br />
                        {this.renderButton("Filter")}
                    </div>
                </form>
            </CardBlock>
        );
    }
}

export default QuestionPageFilter;
