import React, { Component } from "react";
import { Table, Input, Button, Icon } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";

import CardBlock from "../themeComps/cardBlock";
import kidService from "../../services/kidService";
import reportService from "../../services/reportService";
import challengeResultservice from "../../services/challengeResultservice";

class Reports extends Component {
    state = {
        results: []
    };
    columns = [
        {
            title: "Grade",
            dataIndex: "challenge.grade"
        },
        {
            title: "Unit",
            dataIndex: "challenge.unit"
        },
        {
            title: "Lesson",
            dataIndex: "challenge.lesson"
        },
        {
            title: "Completed",
            dataIndex: "timestamp",
            render: (v, r) => {
                let link = `/challengeResult?challengeID=${
                    r.challenge._id
                }&studentID=${this.props.childID}&resultID=${r._id}`;
                if (r.assignment) link = link + "&assignmentID=" + r.assignment;

                return (
                    <Link to={link}>
                        {moment(v).format("YYYY-MM-DD HH:mm A")}
                    </Link>
                );
            }
        }
    ];
    async componentDidMount() {
        const { childID } = this.props;
        try {
            const { data } = await challengeResultservice.getByStudentID(
                childID
            );
            // console.log(data);
            this.setState({
                results: data
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    render() {
        const { results } = this.state;
        return (
            <CardBlock title="Challenge Records">
                <Table
                    dataSource={results}
                    columns={this.columns}
                    bordered={true}
                    rowKey="_id"
                    pagination={true}
                />
            </CardBlock>
        );
    }
}

export default Reports;
