import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class ChallengeNav extends Component {
    state = {};
    render() {
        const { curUser } = this.props;
        if (!curUser) return "";
        if (!(curUser.isSuper || curUser.isManager || curUser.isTeacher))
            return "";
        return (
            <li className="parent nav-item">
                <a
                    className="nav-link collapsed"
                    data-toggle="collapse"
                    href="#challengeMenu"
                    aria-expanded="false"
                >
                    <em className="fa fa-tasks  " /> Challenges{" "}
                    <span
                        data-toggle="collapse"
                        href="#challengeMenu"
                        className="icon pull-right collapsed"
                        aria-expanded="false"
                    >
                        <i className="fa fa-plus" />
                    </span>
                </a>
                <ul className="children collapse" id="challengeMenu">
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/challenge/questions">
                            Questions
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            className="nav-link"
                            to="/challenge/systemChallenges"
                        >
                            System Challenges
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink
                            className="nav-link"
                            to="/challenge/schoolChallenges"
                        >
                            School Challenges
                        </NavLink>
                    </li>
                </ul>
            </li>
        );
    }
}

export default ChallengeNav;
