import React from "react";
import Joi from "joi-browser";

import Form from "../commons/form";
import classService from "../../services/classService";

import { ClassContext } from "./pageClass";
import { toast } from "react-toastify";

class CodeForm extends Form {
    state = {
        data: {
            count: 1
        },
        errors: {}
    };
    schema = {
        count: Joi.number()
            .integer()
            .min(1)
            .required()
    };
    componentDidMount() {}
    doSubmit = async () => {
        const { toggleForm, gClassCmp } = this.props;
        const formData = { ...this.state.data };
        try {
            const { data } = await classService.createCodes(
                formData,
                gClassCmp.state.gClass._id
            );

            if (data) {
                toggleForm(false);
                await gClassCmp.setClassData();
                toast.success("Success to create code");
            }
        } catch (ex) {
            console.log(ex);
        }
    };
    render() {
        const { toggleForm } = this.props;
        return (
            <form onSubmit={this.handleSubmit} className="row">
                <div className="col-md-6">
                    {this.renderInput("count", "Count", "number")}
                </div>
                <div className="col-md-6">
                    <div className="form-group">
                        <label htmlFor="">&nbsp;</label>
                        <div>
                            {this.renderButton("Create")}{" "}
                            <span
                                className="btn btn-default"
                                onClick={() => {
                                    toggleForm(false);
                                }}
                            >
                                Close
                            </span>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

const _CodeForm = props => (
    <ClassContext.Consumer>
        {gClassCmp => <CodeForm {...props} gClassCmp={gClassCmp} />}
    </ClassContext.Consumer>
);

export default _CodeForm;
