import React from "react";

import { Link } from "react-router-dom";

const PageNotFound = () => {
    return (
        <div className="row justify-content-md-center">
            <div className="col">
                <div className="jumbotron">
                    <h1 className="mb-4">404!</h1>
                    <p className="lead">Sorry, page not found</p>

                    <p className="lead">
                        <Link
                            className="btn btn-primary btn-lg mt-2"
                            to="/"
                            role="button"
                        >
                            Back to Home
                        </Link>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;
