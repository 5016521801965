import queryString from "query-string";
import moment from "moment";
import React, { Component } from "react";
import { Route, Switch, Link } from "react-router-dom";

import { AppContext } from "../../App";

import StudentTbl from "./studentTbl";
import CardBlock from "../themeComps/cardBlock";
import Assignment from "./assignment";

import schoolService from "../../services/schoolService";
import classService from "../../services/classService";

export const ClassContext = React.createContext();

function getContext(comp) {
    return comp;
}

class PageClass extends Component {
    state = {
        gClass: null
    };

    async componentDidMount() {
        console.log("page class");
        const { app, match } = this.props;

        try {
            const data = await this.setClassData();
            app.setState({
                pageTitle: `Year:${data.schoolYear.name} Grade:${
                    data.grade.name
                } Class: ${data.name}`
            });
        } catch (ex) {
            console.log(ex);
        }
    }

    setClassData = async () => {
        const { location } = this.props;
        const query = queryString.parse(location.search);
        const classID = query.classID;
        if (!classID) return;

        const { data } = await schoolService.getClass(classID);
        data.grade = data.schoolYear.grades.find(g => g._id === data.grade);
        this.setState({ gClass: data });
        return data;
    };

    componentWillUnmount() {
        const { app } = this.props;
        app.setState({
            pageTitle: ""
        });
    }

    render() {
        const { app, location, history } = this.props;
        const { gClass } = this.state;

        const query = queryString.parse(location.search);
        const classID = query.classID;

        if (!gClass) return "";
        return (
            <ClassContext.Provider value={getContext(this)}>
                <section className="row">
                    <div className="col-md-12">
                        <StudentTbl
                            setClassData={this.setClassData}
                            gClass={gClass}
                        />
                    </div>
                    <div className="col-md-12">
                        <Assignment gClass={gClass} history={history} />
                    </div>
                </section>
            </ClassContext.Provider>
        );
    }
}

const _PageClass = props => (
    <AppContext.Consumer>
        {app => <PageClass {...props} app={app} />}
    </AppContext.Consumer>
);

export default _PageClass;
