import React, { Component } from "react";
import { Modal } from "antd";

import { AppContext } from "../../App";
import UnitBox from "./unitBox";
import alphabetService from "../../services/alphabetService";
import AlphabetForm from "./alphabetForm";
import CardBlock from "../themeComps/cardBlock";

class AlphabetList extends Component {
    state = {
        alphabets: [],
        modalVisible: false,
        modalTitle: "Create new Alphabet",
        alphabetToEdit: null,
        modalWidth: 992
    };
    openModal = () => {
        const state = {
            modalVisible: true
        };
        if (window.innerWidth < 992) {
            state.modalWidth = 768;
        }
        this.setState(state);
    };
    closeModal = async formFlag => {
        this.setState({
            alphabetToEdit: null,
            modalVisible: false,
            modalTitle: "Create new Alphabet"
        });
        if (formFlag) await this.getData();
    };
    showEditForm = alphabet => {
        const state = {
            alphabetToEdit: alphabet,
            modalVisible: true,
            modalTitle: "Edit  alphabet " + alphabet.letter
        };
        if (window.innerWidth < 992) {
            state.modalWidth = 768;
        }
        this.setState(state);
    };
    async componentDidMount() {
        await this.getData();
    }
    getData = async () => {
        try {
            const { data: alphabets } = await alphabetService.getAlphabets();
            this.setState({ alphabets });
        } catch (ex) {
            console.log(ex);
        }
    };

    render() {
        const { app, match, location } = this.props;
        const grade = match.params.grade;
        const {
            alphabets,
            modalVisible,
            alphabetToEdit,
            modalTitle,
            modalWidth
        } = this.state;
        return (
            <CardBlock title="Alphabet">
                <div className="row">
                    <div className="col-md-12" style={{ textAlign: "right" }}>
                        {app.state.curUser.isSuper && (
                            <span
                                className="btn btn-primary float-right m-4"
                                onClick={() => {
                                    this.openModal();
                                }}
                            >
                                Create Alphabet
                            </span>
                        )}
                    </div>
                </div>
                <div className="row">
                    {alphabets.map((alphabet, index) => {
                        const url =
                            process.env.REACT_APP_CLASS_ROOM_URL +
                            "/alphabet?letter=" +
                            alphabet.letter;
                        return (
                            <div className="col-md-2" key={index}>
                                <CardBlock>
                                    <a
                                        style={{ fontSize: "24pt" }}
                                        target="_blank"
                                        href={url}
                                    >
                                        {alphabet.letter.toUpperCase()}{" "}
                                    </a>
                                    <i
                                        className="fa fa-pencil text-primary"
                                        style={{
                                            marginLeft: "10px",
                                            curUser: "pointer",
                                            fontSize: "16px"
                                        }}
                                        onClick={() => {
                                            this.showEditForm(alphabet);
                                        }}
                                    />
                                </CardBlock>
                            </div>
                        );
                    })}
                </div>

                <Modal
                    title={modalTitle}
                    footer={null}
                    onCancel={() => {
                        this.closeModal();
                    }}
                    visible={modalVisible}
                    width={modalWidth}
                    maskClosable={false}
                    destroyOnClose={true}
                >
                    <AlphabetForm
                        handleClose={this.closeModal}
                        data={alphabetToEdit}
                    />
                </Modal>
            </CardBlock>
        );
    }
}

const _AlphabetList = props => {
    return (
        <AppContext.Consumer>
            {app => {
                return <AlphabetList {...props} app={app} />;
            }}
        </AppContext.Consumer>
    );
};

export default _AlphabetList;
