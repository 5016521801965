import React, { Component } from "react";
import { Switch } from "react-router-dom";

import { AppContext } from "../App";
import Children from "./profileComps/children";
import ProtectedRoute from "./navComps/protectedRoute";
import KidForm from "./profileComps/kidForm";
import ProfileForm from "./profileComps/profileForm";
import MemberShips from "./profileComps/memberShips";
import CardBlock from "./themeComps/cardBlock";

import userService from "../services/userService";
import kidService from "../services/kidService";
import DeviceTbl from "./profileComps/deviceTbl";

class PageProfile extends Component {
    state = {
        kids: [],
        userID: null,
        userInfo: {}
    };

    async componentDidMount() {
        const { app, match } = this.props;
        app.setState({
            pageTitle: "Profile"
        });

        let userID = app.state.curUser && app.state.curUser._id;
        if (match.params.userID) userID = match.params.userID;

        try {
            const { data: userInfo } = await userService.getUser(userID);
            const { data: kids } = await kidService.getKids(userID);
            this.setState({ kids, userInfo, userID });
        } catch (ex) {
            console.log(ex);
        }
    }

    componentWillUnmount() {
        const { app } = this.props;
        app.setState({
            pageTitle: ""
        });
    }

    handleKidsChange = kids => {
        this.setState({ kids });
    };
    handleKidChange = kid => {
        const kids = [...this.state.kids];

        const index = kids.findIndex(k => k._id === kid._id);

        if (index === -1) {
            kids.push(kid);
        } else {
            kids[index] = kid;
        }

        this.handleKidsChange(kids);
    };

    render() {
        const { app } = this.props;
        const appState = app.state;
        if (!appState.curUser) return "";
        const { kids, userID, userInfo } = this.state;
        if (!userID) return "";
        return (
            <React.Fragment>
                <section className="row">
                    <div className="col">
                        <Switch>
                            {[
                                "/profile/kidForm",
                                "/profile/:userID/kidForm"
                            ].map((path, index) => {
                                return (
                                    <ProtectedRoute
                                        key={index}
                                        path={path}
                                        render={props => {
                                            return (
                                                <KidForm
                                                    {...props}
                                                    userID={userID}
                                                    handleKidChange={
                                                        this.handleKidChange
                                                    }
                                                />
                                            );
                                        }}
                                    />
                                );
                            })}
                        </Switch>
                    </div>
                </section>
                <section className="row">
                    <div className="col-sm-12 col-md-6">
                        <CardBlock title="User Info">
                            <ProfileForm userID={userID} />
                        </CardBlock>

                        <DeviceTbl userID={userID} />
                    </div>
                    <div className="col-sm-12 col-md-6 ">
                        <Children userID={userID} kids={kids} />
                        <CardBlock title="Memberships">
                            <MemberShips userInfo={userInfo} />
                        </CardBlock>
                    </div>
                    <div className="col-sm-12 col-md-6 " />
                </section>
            </React.Fragment>
        );
    }
}

const _PageProfile = props => (
    <AppContext.Consumer>
        {app => <PageProfile {...props} app={app} />}
    </AppContext.Consumer>
);

export default _PageProfile;
