import queryStr from "query-string";
import Joi from "joi-browser";
import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Form from "./../commons/form";
import CardBlock from "../themeComps/cardBlock";
import schoolService from "../../services/schoolService";

class SchoolYearForm extends Form {
    state = {
        data: {
            name: "",
            order: 0
        },
        errors: {},
        curSchoolYear: null
    };

    componentDidMount() {
        this.setUpState();
    }

    componentDidUpdate(prevProps, prevState) {
        const queryObj = queryStr.parse(this.props.location.search);
        if (queryObj.syID) {
            if (
                !prevState.curSchoolYear ||
                queryObj.syID !== prevState.curSchoolYear._id
            ) {
                this.setUpState();
            }
        } else if (!queryObj.syID && this.state.curSchoolYear) {
            this.setState({
                curSchoolYear: null,
                data: {
                    name: "",
                    order: 0
                }
            });
        }
    }

    schema = {
        name: Joi.string()
            .min(4)
            .max(32)
            .required(),
        order: Joi.number()
    };

    setUpState = () => {
        const { schoolYears, location } = this.props;
        const queryObj = queryStr.parse(location.search);
        if (queryObj.syID) {
            const curSchoolYear = schoolYears.find(
                sy => sy._id === queryObj.syID
            );
            if (curSchoolYear) {
                const data = curSchoolYear;
                this.setState({
                    data,
                    curSchoolYear
                });
            }
        }
    };

    doSubmit = async () => {
        const formData = { ...this.state.data };
        try {
            if (formData._id) {
                //update
                const { data } = await schoolService.updateYear(
                    formData,
                    this.props.curSchoolID
                );
                this.props.handleYearChange(data);
                toast.success("Updated Successfully!");
            } else {
                //create new
                const { data } = await schoolService.createYear(
                    formData,
                    this.props.curSchoolID
                );
                formData.name = "";
                formData.order = 0;
                this.setState({ data: formData });
                this.props.handleYearChange(data);
                toast.success("Created Successfully!");
            }
        } catch (ex) {
            console.log(ex);
        }
    };

    render() {
        if (!this.state.data) return "";

        return (
            <CardBlock
                title={
                    this.state.data._id
                        ? "Update School Year"
                        : "Create a School Year"
                }
            >
                <form onSubmit={this.handleSubmit} className="row">
                    <div className="col-md-6">
                        {this.renderInput("name", "Name")}
                    </div>
                    <div className="col-md-6">
                        {this.renderInput("order", "Order", "number")}
                    </div>
                    <div className="col">
                        {this.renderButton(
                            this.state.curSchoolYear ? "Update" : "Create"
                        )}{" "}
                        <Link className="btn btn-default" to="/school">
                            Close
                        </Link>
                    </div>
                </form>
            </CardBlock>
        );
    }
}

export default SchoolYearForm;
