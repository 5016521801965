import React, { Component } from "react";
import { Table } from "antd";
import { DragDropContext, DragSource, DropTarget } from "react-dnd";
import HTML5Backend from "react-dnd-html5-backend";
import CardBlock from "../themeComps/cardBlock";

let dragingIndex = -1;

class BodyRow extends React.Component {
    render() {
        const {
            isOver,
            connectDragSource,
            connectDropTarget,
            moveRow,
            ...restProps
        } = this.props;
        const style = { ...restProps.style, cursor: "move" };

        let className = restProps.className;
        if (isOver) {
            if (restProps.index > dragingIndex) {
                className += " drop-over-downward";
            }
            if (restProps.index < dragingIndex) {
                className += " drop-over-upward";
            }
        }

        return connectDragSource(
            connectDropTarget(
                <tr {...restProps} className={className} style={style} />
            )
        );
    }
}

const rowSource = {
    beginDrag(props) {
        dragingIndex = props.index;
        return {
            index: props.index
        };
    }
};

const rowTarget = {
    drop(props, monitor) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Time to actually perform the action
        props.moveRow(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    }
};

const DragableBodyRow = DropTarget("row", rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
}))(
    DragSource("row", rowSource, connect => ({
        connectDragSource: connect.dragSource()
    }))(BodyRow)
);

class DragSortingTable extends React.Component {
    state = {};

    columns = [
        {
            title: "Number",
            dataIndex: "",
            render: (val, item, index) => {
                return index + 1;
            }
        },
        { title: "Title", dataIndex: "question" },
        { title: "Grade", dataIndex: "grade" },
        { title: "Unit", dataIndex: "unit" },
        { title: "Lesson", dataIndex: "lesson" },
        {
            title: "",
            render: (val, item) => {
                return (
                    <button
                        className="btn btn-secondary btn-circle margin"
                        type="button"
                        onClick={() => {
                            this.props.toggleQuestion(item);
                        }}
                    >
                        <span className="fa fa-trash" />
                    </button>
                );
            }
        }
    ];

    components = {
        body: {
            row: DragableBodyRow
        }
    };

    moveRow = (dragIndex, hoverIndex) => {
        console.log(dragIndex, hoverIndex);
        const data = [...this.props.pickedQuestions];
        const item = { ...data[dragIndex] };
        const hoverItem = { ...data[hoverIndex] };

        if (dragIndex < hoverIndex) {
            data.splice(dragIndex, 1);
            const newHoverIndex = data.findIndex(d => d._id === hoverItem._id);
            data.splice(newHoverIndex + 1, 0, item);
        } else {
            data.splice(dragIndex, 1);
            const newHoverIndex = data.findIndex(d => d._id === hoverItem._id);
            data.splice(newHoverIndex, 0, item);
        }

        this.props.updatePickedQuestions(data);
    };

    render() {
        const { pickedQuestions: data } = this.props;
        return (
            <CardBlock title={data.length + " Picked Questions"}>
                <Table
                    columns={this.columns}
                    dataSource={data}
                    components={this.components}
                    onRow={(record, index) => ({
                        index,
                        moveRow: this.moveRow
                    })}
                    // locale={{ emptyText: "No Question Picked" }}
                />
            </CardBlock>
        );
    }
}

const ChallengeForm_pickedQuestionTbl = DragDropContext(HTML5Backend)(
    DragSortingTable
);

export default ChallengeForm_pickedQuestionTbl;
