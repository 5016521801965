const objIDRegex = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i;

const isObjectID = function(id) {
    return objIDRegex.test(id);
};

const isJson = function(str) {
    let flag = false;

    try {
        JSON.parse(str);
        flag = true;
    } catch (ex) {}

    return flag;
};

const JoiErrorMsg = (errors, key, msg) => {
    if (errors) {
        const err = errors[0];
        if (err.context.key === key) err.message = msg;
    }
    return errors;
};
const JoiObjectIDErrorMsg = (errors, key) => {
    if (errors) {
        const err = errors[0];
        if (err.context.key === key)
            err.message = key + " is not a valid MongoDB Object ID";
    }
    return errors;
};

const JoiColorCodeErrorMsg = (errors, key) => {
    if (errors) {
        const err = errors[0];
        if (err.context.key === key)
            err.message =
                key + " is not a valid color hex code, example #000000";
    }
    return errors;
};

export default {
    isObjectID,
    isJson,
    JoiErrorMsg,
    JoiObjectIDErrorMsg,
    JoiColorCodeErrorMsg,
    objIDRegex
};
