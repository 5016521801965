import moment from "moment";
import { Table, Input, Button, Icon } from "antd";
import React, { Component } from "react";
import { Link, Switch, Route } from "react-router-dom";
import { toast } from "react-toastify";
import Highlighter from "react-highlight-words";

import { AppContext } from "../../App";
import userService from "../../services/userService";
import userUtils from "../../utils/userUtils";
import CardBlock from "../themeComps/cardBlock";
import UserForm from "./userForm";

import SuperRoute from "./../navComps/superRoute";
import antTblUtils from "../../utils/antTblUtils";

class UserList extends Component {
    state = {
        users: [],
        userToUpdate: null,
        showForm: false,
        searchText: ""
    };

    //search

    //

    columns = [
        {
            title: "Name",
            dataIndex: "name",
            sorter: (a, b) => {
                let nameA = "",
                    nameB = "";
                try {
                    nameA = a.name.toLowerCase();
                } catch (ex) {}
                try {
                    nameB = b.name.toLowerCase();
                } catch (ex) {}
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;

                return 0;
            },
            render: (val, user) => {
                let url = "";
                if (!user.isChild) {
                    url = "/profile/" + user._id;
                } else {
                    url = "/dashboard/" + user._id;
                }
                if (url) {
                    return <Link to={url}>{val}</Link>;
                }

                return <span>{val}</span>;
            },
            ...antTblUtils.getColumnSearchProps("name", this.state.searchText)
        },
        {
            title: "Email",
            dataIndex: "email",
            render: (val, user) => {
                if (!val) return "";

                let url = "/profile/" + user._id;
                return <Link to={url}>{val}</Link>;
            },
            ...antTblUtils.getColumnSearchProps("email", this.state.searchText)
        },
        {
            title: "Roles",
            dataIndex: "roles",
            render: (val, user) => {
                if (!val) return "";
                return val.map((r, index) => {
                    let url = "";
                    if (r == "teacher" && user.isTeacher) {
                        url = "/teacher/" + user._id;
                    } else if (r == "manager" && user.isManager) {
                        url = "/school?schoolID=" + user.isManager.school;
                    }
                    if (!url)
                        return (
                            <span key={index} className="m-1">
                                {r}
                            </span>
                        );
                    return (
                        <Link to={url} key={index} className="m-1">
                            {r}
                        </Link>
                    );
                });
            }
        },
        {
            title: "Created",
            dataIndex: "created",
            render: val => {
                return moment(val).format("YYYY-MM-DD");
            },
            sorter: (a, b) => {
                let dateA = moment(),
                    dateB = moment();
                try {
                    dateA = moment(a.created);
                } catch (ex) {}
                try {
                    dateB = moment(b.created);
                } catch (ex) {}

                if (dateA.diff(dateB, "days") <= -1) return -1;
                if (dateA.diff(dateB, "days") >= 1) return 1;

                if (dateA.diff(dateB, "days") === 0) return 0;
            },
            defaultSortOrder: "descend"
        },
        {
            title: "",
            dataIndex: "",
            render: (val, user) => {
                return (
                    <div>
                        <span
                            className="m-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                this.toggleForm(false);
                                setTimeout(() => {
                                    this.setState({ userToUpdate: user._id });
                                    this.toggleForm(true);
                                }, 100);
                            }}
                        >
                            <em className="fa fa-pencil" />
                        </span>
                        <span
                            className="trash"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                this.deleteUser(user);
                            }}
                        >
                            <em className="fa fa-trash" />
                        </span>
                    </div>
                );
            }
        }
    ];

    async componentDidMount() {
        const { app } = this.props;
        app.setState({
            pageTitle: "Registed Users"
        });

        this.setUsers();
    }

    componentWillUnmount() {
        const { app } = this.props;
        app.setState({
            pageTitle: ""
        });
    }

    setUsers = async () => {
        try {
            const { data: users } = await userService.getUsers();

            users.forEach(u => {
                userUtils.createUser(u);
            });
            this.setState({
                users
            });
        } catch (ex) {
            console.log(ex);
        }
    };

    handleUsersChange = users => {
        this.setState({ users });
    };
    handleUserChange = user => {
        const users = [...this.state.users];
        const index = users.findIndex(u => u._id === user._id);
        user = userUtils.createUser(user);
        if (index === -1) {
            users.push(user);
        } else {
            users[index] = user;
        }
        this.handleUsersChange(users);
    };
    deleteUser = async user => {
        const flag = window.confirm("Are u sure want to delete this user?");
        if (!flag) return;
        let users = [...this.state.users];
        try {
            const { data } = await userService.deleteUser(user._id);
            if (data) {
                users = users.filter(u => u._id !== data._id);
                this.setState({ users });
                toast.success("Successed to delete a user");
            }
        } catch (ex) {
            console.log(ex);
        }
    };

    toggleForm = val => {
        if (val === null || val === undefined) val = false;

        if (val === false) {
            this.setState({ userToUpdate: null });
        }

        this.setState({ showForm: val });
    };

    render() {
        const { userToUpdate, showForm } = this.state;
        return (
            <div className="">
                {showForm && (
                    <UserForm
                        handleUserChange={this.handleUserChange}
                        userToUpdate={userToUpdate}
                        toggleForm={this.toggleForm}
                    />
                )}

                <div className="row">
                    <div className="col-md-12">
                        <span
                            className="btn btn-primary float-right m-4"
                            onClick={() => {
                                this.toggleForm(true);
                            }}
                        >
                            Create User
                        </span>
                    </div>

                    <div className="col-md-12">
                        <CardBlock title="User List">
                            <Table
                                dataSource={this.state.users}
                                columns={this.columns}
                                pagination={{ pageSize: 50 }}
                                bordered={true}
                                rowKey="_id"
                            />
                        </CardBlock>
                    </div>
                </div>
            </div>
        );
    }
}

const _UserList = props => (
    <AppContext.Consumer>
        {app => <UserList {...props} app={app} />}
    </AppContext.Consumer>
);

export default _UserList;
