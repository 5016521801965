import { Empty } from "antd";
import React, { Component } from "react";
import QuestionPageFilter from "./QuestionPageFilter";
import CardBlock from "../themeComps/cardBlock";
import challengeService from "../../services/challengeService";

class QuestionPicker extends Component {
    state = {
        questions: []
    };
    async componentDidMount() {
        await this.getSetQuestions();
    }
    getSetQuestions = async filterObj => {
        if (!filterObj) filterObj = challengeService.getQuestionFilter();

        filterObj["owner.owner"] = filterObj.owner;
        delete filterObj.owner;
        try {
            const { data } = await challengeService.getQuestions(filterObj);
            this.setState({
                questions: data
            });
        } catch (ex) {
            console.log(ex);
        }
    };
    renderQuestion = q => {
        const { toggleQuestion } = this.props;
        return (
            <CardBlock key={q._id}>
                <h2 className="lead">
                    {q.key && q.key + "."} {q.question}
                    {!this.ifPicked(q) && (
                        <small
                            className="pull-right btn btn-primary"
                            onClick={() => {
                                toggleQuestion(q);
                            }}
                        >
                            Pick
                        </small>
                    )}
                </h2>
                <div className="row">
                    {q.options.map((opt, index) => {
                        const isAnswer = q.answers.includes(opt);

                        let btnClasses = "alert";
                        if (isAnswer) {
                            btnClasses += " bg-primary";
                        } else {
                            btnClasses += " bg-transparent text-dark";
                        }

                        return (
                            <div className="col-md-12" key={index}>
                                <div
                                    className={btnClasses}
                                    style={{ textTransform: "inherit" }}
                                >
                                    {opt}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </CardBlock>
        );
    };
    ifPicked = q => {
        const { pickedQuestions } = this.props;

        return pickedQuestions.find(picked => picked._id === q._id);
    };
    render() {
        const { questions } = this.state;

        return (
            <CardBlock title="Question Picker">
                <div className="row">
                    <div className="col-md-8">
                        {questions.map(q => this.renderQuestion(q))}

                        {questions.length === 0 && (
                            <Empty description="No Data, please change search query" />
                        )}
                    </div>
                    <div className="col-md-4">
                        <QuestionPageFilter
                            getSetQuestions={this.getSetQuestions}
                        />
                    </div>
                </div>
            </CardBlock>
        );
    }
}

export default QuestionPicker;
