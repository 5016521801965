import moment from "moment";
import React, { Component } from "react";

const MemberShips = ({ userInfo }) => {
    return (
        <div>
            <table className="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th>Role</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {userInfo.memberships.map(m => {
                        return (
                            <tr>
                                <td style={{ textTransform: "capitalize" }}>
                                    {m.roleName}{" "}
                                </td>
                                <td>
                                    {moment(m.dateCreated).format("YYYY-MM-DD")}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default MemberShips;
