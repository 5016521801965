import React from "react";

const TableHeader = ({ columns, onSort, sortColumn }) => {
    const raiseSort = path => {
        if (!onSort) return;
        const newSort = { ...sortColumn };

        if (newSort.path === path) {
            newSort.order = newSort.order === "asc" ? "desc" : "asc";
        } else {
            newSort.path = path;
            newSort.order = "asc";
        }
        onSort(newSort);
    };
    const rednerSortIcon = column => {
        if (column.path !== sortColumn.path) return null;
        if (sortColumn.order === "asc") return <i className="fa fa-sort-asc" />;
        return <i className="fa fa-sort-desc" />;
    };
    return (
        <thead>
            <tr>
                {columns.map((c, index) => (
                    <th
                        className="clickable"
                        key={index}
                        onClick={() => {
                            raiseSort(c.path);
                        }}
                    >
                        {c.label} {rednerSortIcon(c)}
                    </th>
                ))}
            </tr>
        </thead>
    );
};

export default TableHeader;
