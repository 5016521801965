import React from "react";
import TableHeader from "./tableHeader";
import TableBody from "./tableBody";

const Table = ({
    data = [],
    columns = [],
    sortColumn = {},
    onSort = null,
    className = "table table-striped"
}) => {
    const getTableClasses = () => {
        let cls = className;
        cls += data.length === 0 ? "d-none" : "";
        return cls;
    };
    return (
        <table className={getTableClasses()}>
            <TableHeader
                columns={columns}
                onSort={onSort}
                sortColumn={sortColumn}
            />
            <TableBody data={data} columns={columns} />
        </table>
    );
};

export default Table;
