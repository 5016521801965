import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";

import { AppContext } from "../App";
import Child from "./childComps/child";

class PageHome extends Component {
    state = {};

    componentDidMount() {
        const { app } = this.props;

        app.setState({
            pageTitle: `Welcome Back!`
        });
    }

    componentWillUnmount() {
        const { app } = this.props;
        app.setState({
            pageTitle: ""
        });
    }
    render() {
        const { app, location, match } = this.props;
        const { curUser } = app && app.state;
        let childID = curUser.isParent && curUser.isParent.child;
        if (match.params.childID) childID = match.params.childID;
        if (!childID) return this.renderNoChildComp();
        return (
            <div className="row">
                <div className="col">
                    <Child childID={childID} />
                </div>
            </div>
        );
    }

    renderNoChildComp = () => {
        const { app, location, match } = this.props;
        const { curUser } = app && app.state;
        if (!curUser) return "";
        const parentID = curUser._id;

        return (
            <div className="row justify-content-md-center">
                <div className="col">
                    <div className="jumbotron">
                        <h1 className="mb-4">
                            You don't have a child account yet!
                        </h1>
                        <p className="lead">
                            Create a child account in your profile
                        </p>

                        <p className="lead">
                            <Link
                                className="btn btn-primary btn-lg mt-2"
                                to={"/profile/" + parentID + "/kidForm"}
                                role="button"
                            >
                                Go to Profile
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        );
    };
}

const _PageHome = props => (
    <AppContext.Consumer>
        {app => <PageHome {...props} app={app} />}
    </AppContext.Consumer>
);

export default _PageHome;
