import { Modal, Popconfirm } from "antd";
import React, { Component } from "react";

import { AppContext } from "./../../App";
import CardBlock from "../themeComps/cardBlock";
import appUtils from "../../utils/appUtils";
import QuestionPageFilter from "./QuestionPageFilter";
import challengeService from "../../services/challengeService";
import QuestionForm from "./questionForm";
import { toast } from "react-toastify";

class QuestionPage extends Component {
    state = {
        questions: [],
        modalTitle: "Create new question",
        modalVisible: false,
        modalWidth: 992,
        questionToEdit: null
    };

    async componentDidMount() {
        const { app } = this.props;

        await this.getSetQuestions();

        app.setState({
            pageTitle: "Questions Management"
        });
    }
    componentWillUnmount() {
        const { app } = this.props;
        app.setState({
            pageTitle: ""
        });
    }

    getSetQuestions = async filterObj => {
        if (!filterObj) filterObj = challengeService.getQuestionFilter();

        filterObj["owner.owner"] = filterObj.owner;
        delete filterObj.owner;
        try {
            const { data } = await challengeService.getQuestions(filterObj);

            this.setState({
                questions: data
            });
        } catch (ex) {
            console.log(ex);
        }
    };
    openModal = () => {
        const state = {
            modalVisible: true
        };
        if (window.innerWidth < 992) {
            state.modalWidth = 768;
        }
        this.setState(state);
    };
    closeModal = async flag => {
        this.setState({
            questionToEdit: null,
            modalVisible: false,
            modalTitle: "Create new question"
        });
        if (flag)
            await this.getSetQuestions(challengeService.getQuestionFilter());
    };

    render() {
        const { app } = this.props;
        const appState = app.state;
        const state = this.state;
        const { questions } = this.state;
        return (
            <div>
                <div className="row">
                    <div className="col-md-8">
                        {questions.length > 0 && (
                            <CardBlock title="Filtered Questions">
                                {questions.map(q => this.renderQuestion(q))}
                            </CardBlock>
                        )}
                        {questions.length == 0 && (
                            <div className="row justify-content-md-center">
                                <div className="col">
                                    <div className="jumbotron">
                                        <h1 className="mb-4">
                                            No Question Found!
                                        </h1>
                                        <p className="lead">
                                            Please change your query and filter
                                            again.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="col-md-4">
                        <QuestionPageFilter
                            getSetQuestions={this.getSetQuestions}
                        />
                        {(appState.curUser.isSuper ||
                            appState.curUser.isManager ||
                            appState.curUser.isTeacher) && (
                            <div className="row">
                                <div
                                    className="col"
                                    style={{
                                        textAlign: "right",
                                        marginBottom: "20px"
                                    }}
                                >
                                    <span
                                        className="btn btn-primary "
                                        onClick={() => {
                                            this.openModal();
                                        }}
                                    >
                                        Create new question
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <Modal
                    footer={null}
                    maskClosable={false}
                    destroyOnClose={true}
                    title={state.modalTitle}
                    visible={state.modalVisible}
                    width={state.modalWidth}
                    onCancel={() => {
                        this.closeModal();
                    }}
                >
                    <QuestionForm
                        question={state.questionToEdit}
                        handleClose={this.closeModal}
                        curUser={appState.curUser}
                    />
                </Modal>
            </div>
        );
    }

    renderQuestion = q => {
        const { app } = this.props;
        const dropDownOpts = [
            {
                label: "Edit Question",
                iconCls: "fa-pencil ",
                handler: () => {
                    this.setState({
                        questionToEdit: q,
                        modalVisible: true
                    });
                }
            },
            {
                label: "Delete Question",
                iconCls: "fa-trash",
                handler: async () => {
                    const toDel = window.confirm(
                        "Are u sure want to delete this record?"
                    );
                    if (!toDel) return;
                    try {
                        const { data } = await challengeService.deleteQuestion(
                            q._id
                        );
                        if (data) {
                            await this.getSetQuestions();
                            toast.success("Deleted successfully!");
                        }
                    } catch (ex) {
                        console.log(ex);
                    }
                }
            }
        ];
        if (q.owner.owner === "system" && !app.state.curUser.isSuper)
            dropDownOpts.splice(0, 1);
        return (
            <CardBlock key={q._id} dropDownOpts={dropDownOpts}>
                <h2 className="lead">
                    {q.key && q.key + "."} {q.question}
                </h2>
                <div className="row">
                    {q.options.map((opt, index) => {
                        const isAnswer = q.answers.includes(opt);

                        let btnClasses = "alert";
                        if (isAnswer) {
                            btnClasses += " bg-primary";
                        } else {
                            btnClasses += " bg-transparent text-dark";
                        }

                        return (
                            <div className="col-md-12" key={index}>
                                <div
                                    className={btnClasses}
                                    style={{ textTransform: "inherit" }}
                                >
                                    {opt}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </CardBlock>
        );
    };
}

const _QuestionPage = props => (
    <AppContext.Consumer>
        {app => <QuestionPage {...props} app={app} />}
    </AppContext.Consumer>
);

export default _QuestionPage;
