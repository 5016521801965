import moment from "moment";
import { Table, Input, Button, Icon } from "antd";
import React, { Component } from "react";
import Highlighter from "react-highlight-words";

const Utils = class {
    constructor() {}
    getColumnSearchProps = (dataIndex, searchText, dataType) => {
        const obj = {
            filterDropdown: ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters
            }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            obj.searchInput = node;
                        }}
                        placeholder={`Search ${dataIndex}`}
                        value={selectedKeys[0]}
                        onChange={e =>
                            setSelectedKeys(
                                e.target.value ? [e.target.value] : []
                            )
                        }
                        onPressEnter={() =>
                            obj.handleSearch(selectedKeys, confirm)
                        }
                        style={{
                            width: 188,
                            marginBottom: 8,
                            display: "block"
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => obj.handleSearch(selectedKeys, confirm)}
                        icon="search"
                        size="small"
                        style={{ width: 90, marginRight: 8 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => obj.handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </div>
            ),
            filterIcon: filtered => (
                <Icon
                    type="search"
                    style={{ color: filtered ? "#1890ff" : undefined }}
                />
            ),
            onFilter: (value, record) => {
                let column = record[dataIndex];
                if (!column) return;

                return column
                    .toString()
                    .toLowerCase()
                    .includes(value.toLowerCase());
            },
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => obj.searchInput.select());
                }
            },
            // render: text => (
            //     <Highlighter
            //         highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
            //         searchWords={[searchText]}
            //         autoEscape
            //         textToHighlight={text && text.toString()}
            //     />
            // ),

            handleSearch: (selectedKeys, confirm) => {
                confirm();
                searchText = selectedKeys[0];
            },

            handleReset: clearFilters => {
                clearFilters();
                searchText = "";
            }
        };

        return obj;
    };
};

export default new Utils();
