import React, { Component } from "react";
import queryString from "query-string";

import { AppContext } from "../../App";
import challengeResultservice from "../../services/challengeResultservice";
import assignmentService from "../../services/assignmentService";
import challengeService from "../../services/challengeService";
import userService from "../../services/userService";
import QuestionTbl from "./questionTbl";
import Overall from "./Overall";

class ResultComp extends Component {
    state = {
        challenge: null,
        assignmentInfo: null,
        student: null,
        studentID: null,
        resultID: null,
        challengeResults: [],
        studentResult: null
    };
    async componentDidMount() {
        const { location, app } = this.props;
        const query = queryString.parse(location.search);
        console.log(query);
        const assignmentID = query.assignmentID;
        const classID = query.classID;
        const studentID = query.studentID;
        const challengeID = query.challengeID;
        const resultID = query.resultID;

        if (!studentID || !challengeID) {
            console.log("student ID or challengeID is missing");
            return "";
        }

        try {
            const state = { studentID };
            const { data: student } = await userService.getUser(studentID);
            const { data: challenge } = await challengeService.getChallenge(
                challengeID
            );

            state.student = student;
            state.challenge = challenge;
            // console.log("get challenge", challenge);

            if (assignmentID) {
                const {
                    data: challengeResults
                } = await challengeResultservice.getAssignmentResults(
                    assignmentID
                );

                const {
                    data: assignmentInfo
                } = await assignmentService.getAssignment(assignmentID);

                state.assignmentInfo = assignmentInfo;
                state.challengeResults = challengeResults;
                state.studentResult = this.getStudentResult({
                    challengeResults,
                    studentID
                });
            } else {
                const {
                    data: challengeResults
                } = await challengeResultservice.getByChallengeID(challengeID);
                // console.log("get challengeResults", challengeResults);
                state.challengeResults = challengeResults;
                state.studentResult = this.getStudentResult({
                    challengeResults,
                    resultID
                });
            }

            this.setState(state);

            app.setState({
                pageTitle: () => {
                    let pageTitle = "Challenge";
                    if (assignmentID) pageTitle = "Assignment";

                    pageTitle += " Result of " + student.name;
                    return (
                        <React.Fragment>
                            {pageTitle} <br />
                            <small>
                                Compare to: {assignmentID ? "Class" : "All"},{" "}
                                Result Count: {state.challengeResults.length}
                            </small>
                        </React.Fragment>
                    );
                }
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    componentWillUnmount() {
        const { app } = this.props;
        app.setState({
            pageTitle: ""
        });
    }
    getStudentResult = ({ challengeResults, studentID, resultID }) => {
        if (resultID) return challengeResults.find(r => r._id === resultID);
        return challengeResults.find(r => r.userID === studentID);
    };
    render() {
        const {
            challenge,
            challengeResults,
            assignmentInfo,
            studentResult
        } = this.state;

        if (!challenge || challengeResults.length === 0) {
            // console.log("challenge", challenge);
            // console.log("challengeResults", challengeResults);
            return "";
        }
        return (
            <div className="row">
                <div className="col-md-8">
                    <QuestionTbl
                        challenge={challenge}
                        result={studentResult}
                        results={challengeResults}
                    />
                </div>
                <div className="col-md-4">
                    <Overall
                        result={studentResult}
                        results={challengeResults}
                        assignmentInfo={assignmentInfo}
                    />
                </div>
            </div>
        );
    }
}

const ResultComp_ = props => {
    return (
        <AppContext.Consumer>
            {app => {
                return <ResultComp app={app} {...props} />;
            }}
        </AppContext.Consumer>
    );
};
export default ResultComp_;
