import moment from "moment";
import React, { Component } from "react";
import { Table, Modal, Button } from "antd";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

import CardBlock from "./../themeComps/cardBlock";
import CodeForm from "./codeForm";
import EditCodeForm from "./editCodeForm";

import schoolService from "../../services/schoolService";
import classService from "../../services/classService";

class StudentTbl extends Component {
    state = {
        showForm: false,
        codeToEdit: null,
        codeFormVisible: false
    };
    dropdownOpts = [
        {
            label: "Create Codes",
            iconCls: "fa-plus",
            url: "",
            handler: () => {
                this.toggleForm(true);
            }
        },
        {
            label: "Print Codes",
            iconCls: "fa-print",
            url: "",
            handler: () => {
                toast.warn("To do!");
            }
        }
    ];
    columns = [
        {
            title: "Name",
            dataIndex: "student.name",
            width: 150,
            fixed: "left",
            render: (v, r, i) => {
                if (!v) return r.name;
                return <Link to={"/dashboard/" + r.student._id}>{v}</Link>;
            },
            sorter: (a, b) => {
                let nameA = "",
                    nameB = "";
                if (a.student) {
                    nameA = a.student.name.toLowerCase();
                } else {
                    try {
                        nameA = a.name.toLowerCase();
                    } catch (ex) {}
                }

                if (b.student) {
                    nameB = b.student.name.toLowerCase();
                } else {
                    try {
                        nameB = b.name.toLowerCase();
                    } catch (ex) {}
                }
                if (nameA < nameB) return -1;
                if (nameA > nameB) return 1;

                return 0;
            }
        },
        { title: "Code", dataIndex: "_id" },
        {
            title: "subscriptions",
            dataIndex: "subscriptions",
            width: 150,
            render: (val, record) => {
                try {
                    return val.map(s => {
                        return (
                            <div key={s._id}>
                                {s.app},{s.status}
                            </div>
                        );
                    });
                } catch (ex) {
                    return "";
                }
            }
        },
        {
            title: "Date Used",
            dataIndex: "dateUsed",
            width: 200,
            defaultSortOrder: "ascend",
            sorter: (a, b) => {
                let dateA = moment(),
                    dateB = moment();
                try {
                    dateA = moment(a.dateUsed);
                } catch (ex) {}
                try {
                    dateB = moment(b.dateUsed);
                } catch (ex) {}

                if (dateA.diff(dateB, "days") <= -1) return -1;
                if (dateA.diff(dateB, "days") >= 1) return 1;

                if (dateA.diff(dateB, "days") === 0) return 0;
            },
            render: text => {
                if (!text) return "";

                return <span>{moment(text).format("YYYY-MM-DD")}</span>;
            }
        },
        {
            title: "Actions",
            width: 150,
            render: (val, record) => {
                if (record.student && record.student._id) return "";
                return (
                    <div>
                        <button
                            className="btn btn-sm btn-danger btn-circle margin"
                            onClick={async () => {
                                await this.deleteCode(record);
                            }}
                        >
                            <span className="fa fa-trash" />
                        </button>{" "}
                        <button
                            className="btn btn-sm btn-primary btn-circle margin"
                            onClick={async () => {
                                await this.editCode(record);
                            }}
                        >
                            <span className="fa fa-edit" />
                        </button>
                    </div>
                );
            }
        }
    ];
    deleteCode = async record => {
        const { gClass } = this.props;
        const toDel = window.confirm("Are u sure want to delete this?");

        if (!toDel) return;
        try {
            const { data } = await classService.deleteCode(
                record._id,
                gClass._id
            );
            await this.props.setClassData();
            toast.success("Success!");
        } catch (ex) {
            console.log(ex);
        }
    };
    editCode = async record => {
        const { gClass } = this.props;
        try {
            const { data } = await schoolService.getActivationCode(
                gClass._id,
                record._id
            );
            this.setState({
                codeToEdit: data,
                codeFormVisible: true
            });
        } catch (ex) {
            console.log(ex);
        }
    };

    toggleForm = val => {
        if (val === null) val = !this.state.showForm;
        this.setState({ showForm: val });
    };
    handleModalClose = async flag => {
        this.setState({ codeToEdit: null, codeFormVisible: false });
        if (flag) {
            await this.props.setClassData();
        }
    };

    createCodeModal = code => {
        if (!code) return "";
        return (
            <Modal
                title={"Edit Code #" + code._id}
                visible={this.state.codeFormVisible}
                onCancel={this.handleModalClose}
                footer={null}
            >
                <EditCodeForm
                    codeInfo={code}
                    closeForm={this.handleModalClose}
                />
            </Modal>
        );
    };

    render() {
        const { gClass } = this.props;
        const { showForm, codeToEdit } = this.state;
        let codes = gClass.ActivationCodes;
        if (!codes) codes = [];

        return (
            <React.Fragment>
                <CardBlock title="Students" dropDownOpts={this.dropdownOpts}>
                    {showForm && (
                        <div className="row">
                            <div className="col-md-12">
                                <CodeForm
                                    toggleForm={this.toggleForm}
                                    classID={gClass._id}
                                />
                            </div>
                        </div>
                    )}
                    <Table
                        columns={this.columns}
                        dataSource={codes}
                        pagination={false}
                        bordered={true}
                        rowKey="_id"
                        scroll={{ x: 900 }}
                    />
                </CardBlock>

                {this.createCodeModal(codeToEdit)}
            </React.Fragment>
        );
    }
}

export default StudentTbl;
