import http from "./httpService";
import auth from "./authService";

const endpoint = "/devices";

http.setJwt(auth.getJwt());

const Utils = class {
    getDevices = async userID => {
        if (userID) http.setUserIDHeader(userID);
        return await http.get(endpoint);
    };

    deleteDevice = async (userID, deviceID) => {
        if (userID) http.setUserIDHeader(userID);
        return await http.delete(endpoint + "/" + deviceID);
    };
};

export default new Utils();
