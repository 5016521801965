import http from "./httpService";
import auth from "./authService";
const reportEndPoint = "/reports";
const questionEndpoint = "/questions";
const diaryEndPoint = "/diaries";

http.setJwt(auth.getJwt());

const Utils = class {
    getReports = async childID => {
        return await http.get(reportEndPoint + "/" + childID);
    };

    getReportsByChallenge = async challengeID => {
        return await http.get(reportEndPoint + "/byChallenge/" + challengeID);
    };
    getDiaires = async data => {
        return await http.get(diaryEndPoint, {
            headers: { "x-request-body": JSON.stringify(data) }
        });
    };

    getQuestions = async data => {
        return await http.get(
            questionEndpoint + "?filter=" + JSON.stringify(data)
        );
    };
};

export default new Utils();
