import React, { Component } from "react";
import { NavLink, Link } from "react-router-dom";

import $ from "jquery";

import { AppContext } from "../App";
import TeacherNav from "./navComps/teacherNav";
import SchoolNav from "./navComps/schoolNav";
import SuperNav from "./navComps/superNav";
import MaterialNav from "./navComps/materialNav";
import ChallengeNav from "./navComps/challengeNav";

class Navigation extends Component {
    componentDidMount() {
        $(".sidebar").on("click", "ul.nav li.parent > a ", function() {
            $(this)
                .find("i")
                .toggleClass("fa-minus");
        });
        $(".sidebar span.icon")
            .find("em:first")
            .addClass("fa-plus");

        $("#menu-toggle").click(function(e) {
            e.preventDefault();
            $("#wrapper").toggleClass("toggled");
        });

        $(".sidebar").on("click", "ul.nav a", () => {
            $("#wrapper").toggleClass("toggled");
        });
    }
    render() {
        const appState = this.props.appContext.state;
        const { curUser, appName } = appState;
        return (
            <nav className="sidebar col-md-12 col-lg-3 col-xl-2 bg-faded sidebar-style-1">
                <h1 className="site-title">
                    <Link to="/">
                        <em className="fa fa-rocket" /> {appName}
                    </Link>
                </h1>

                <a
                    href="#menu-toggle"
                    className="btn btn-default"
                    id="menu-toggle"
                >
                    <em className="fa fa-bars" />
                </a>

                <ul className="nav nav-pills flex-column sidebar-nav">
                    <SuperNav curUser={curUser} />
                    <SchoolNav />
                    <TeacherNav curUser={curUser} />
                    <MaterialNav curUser={curUser} />
                    <ChallengeNav curUser={curUser} />
                    <li className="nav-item">
                        <NavLink className="nav-link" to="/dashboard">
                            <em className="fa fa-home" /> Dashboard
                            <span className="sr-only">(current)</span>
                        </NavLink>
                    </li>

                    <li className="nav-item">
                        <NavLink className="nav-link" to="/about">
                            <em className="fa fa-info-circle" /> About
                        </NavLink>
                    </li>
                    {/* no user login */}
                    {!curUser && (
                        <li className="nav-item">
                            <NavLink to="/login" className="nav-link">
                                <em className="fa fa-sign-in" /> Login
                            </NavLink>
                            <NavLink to="/register" className="nav-link">
                                <em className="fa fa-user-plus" /> Register
                            </NavLink>
                        </li>
                    )}
                    {/* user loged in */}
                    {curUser && (
                        <React.Fragment>
                            <li className="nav-item">
                                <NavLink to="/profile" className="nav-link">
                                    <i className="fa fa-user-circle" /> Profile
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/logout" className="nav-link">
                                    <em className="fa fa-sign-out" /> Logout
                                </NavLink>
                            </li>
                        </React.Fragment>
                    )}
                </ul>
            </nav>
        );
    }
}

const _Navigation = props => (
    <AppContext.Consumer>
        {appContext => <Navigation {...props} appContext={appContext} />}
    </AppContext.Consumer>
);

export default _Navigation;
