import { Table, Input, Button, Icon } from "antd";
import { Link } from "react-router-dom";
import React, { Component } from "react";
import queryString from "query-string";
import moment from "moment";

import { AppContext } from "../../App";
import schoolService from "../../services/schoolService";
import challengeResultservice from "../../services/challengeResultservice";
import assignmentService from "../../services/assignmentService";

class AssignmentResult extends Component {
    state = {
        classInfo: null,
        assignmentInfo: null,
        challengeResults: []
    };
    async componentDidMount() {
        const { location, app } = this.props;
        const query = queryString.parse(location.search);
        const assignmentID = query.assignmentID;
        const classID = query.classID;

        if (!classID) return;
        if (!assignmentID) return;

        try {
            const { data: classInfo } = await schoolService.getClass(classID);

            const {
                data: challengeResults
            } = await challengeResultservice.getAssignmentResults(assignmentID);

            const {
                data: assignmentInfo
            } = await assignmentService.getAssignment(assignmentID);
            this.setState({
                classInfo,
                assignmentInfo,
                challengeResults
            });
            app.setState({
                pageTitle: "Assignment Result of " + assignmentInfo.name
            });
        } catch (ex) {
            console.log(ex);
        }
    }
    componentWillUnmount() {
        const { app } = this.props;
        app.setState({
            pageTitle: ""
        });
    }
    render() {
        return <div>{this.renderStudentTbl()}</div>;
    }
    getStudentResult = student => {
        const studentID = student.student._id || student.student;
        return this.state.challengeResults.find(r => r.userID === studentID);
    };
    renderStudentTbl = () => {
        const { classInfo, assignmentInfo, challengeResults } = this.state;

        if (!classInfo) return "";

        let students = classInfo.ActivationCodes;

        const columns = [
            {
                title: "Name",
                dataIndex: "student.name",
                render: (v, r, i) => {
                    return (
                        <span style={{ textTransform: "uppercase" }}>
                            {v || r.name}
                        </span>
                    );
                }
            },
            {
                title: "Score / 100",
                render: (v, r) => {
                    const result = this.getStudentResult(r);
                    if (result) return <span>{result.score}</span>;
                }
            },
            {
                title: "Time Spent",
                render: (v, r) => {
                    const result = this.getStudentResult(r);
                    if (result) {
                        const start = new moment();
                        const end = start
                            .clone()
                            .add(result.timeSpend, "seconds");
                        const dif = end.diff(start);
                        const timer = moment.utc(dif).format("HH:mm:ss");
                        return <span>{timer}</span>;
                    }
                }
            },
            {
                title: "Completed",
                render: (v, r) => {
                    const result = this.getStudentResult(r);
                    const studentID = r.student._id || r.student.student;
                    if (result)
                        return (
                            <Link
                                style={{ lineHeight: "2.5" }}
                                title="View Detail"
                                className="btn btn-primary btn-circle"
                                to={`/challengeResult?challengeID=${
                                    assignmentInfo.challenge
                                }&assignmentID=${
                                    assignmentInfo._id
                                }&studentID=${studentID}`}
                            >
                                <span className="fa fa-search" />
                            </Link>
                        );
                }
            }
        ];

        return (
            <Table
                columns={columns}
                dataSource={students}
                pagination={false}
                bordered={true}
                rowKey="_id"
            />
        );
    };
}

const AssignmentResult_ = props => (
    <AppContext.Consumer>
        {app => <AssignmentResult {...props} app={app} />}
    </AppContext.Consumer>
);

export default AssignmentResult_;
